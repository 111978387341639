import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {catchError, map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {environment} from '@mydrake/environments/environment';
import {throwError} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private http: HttpClient) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this.http.get(environment.apiUrl + 'My/Registration/AuthGuard').pipe(
            map(() => true),
            catchError((err) => {
                window.location.href = `${environment.appUrl}/Logon#?err=${btoa('MY_DRAKE_ACCESS_DENIED')}`;
                return throwError(err);
            })
        );
    }
}
