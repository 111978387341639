<!-- LOADING BAR -->
<ng-progress [min]="8" [max]="50" [speed]="300" [spinner]="false" [direction]="'ltr+'" [color]="'#009688'" [trickleSpeed]="200" [thick]="true" [ease]="'linear'" [meteor]="false"></ng-progress>
<!--/ LOADING BAR -->

<div fxLayout="row" style="height: 100vh; overflow:hidden">

    <!-- ROOT CONTAINER -->
    <mat-sidenav-container class="mydrake-core-container">

        <!-- MINIMAL MENU -->
        <mat-sidenav class="mydrake-minimal-menu" mode="side" #minimalMenu opened="false" fxLayout="column" opened="true" disableClose="true">
            <div class="sidenav-full" kscrollbar>
                <div class="logo">
                    <img [src]="images.minilogo" alt="MyDrake" width="30" (click)="openMenuExpanded()">
                </div>
                <ul>
                    <li *ngFor="let item of menu" fxLayout="row" fxLayoutAlign="center center">
                        <a [routerLink]="item.link">
                            <i [class]="item.icon" *ngIf="!item.isSvgIcon" matTooltip="{{ item.title | translate }}" matTooltipPosition="right"></i>
                            <mat-icon [svgIcon]="item.icon" *ngIf="item.isSvgIcon" matTooltip="{{ item.title | translate }}" matTooltipPosition="right"></mat-icon>
                        </a>
                    </li>
                </ul>
                <div fxFlex></div>
                <ul>
                    <li>
                        <a (click)="logOut()" fxLayout="row" fxLayoutAlign="start center">
                            <i class="icon-mydrake-logout" matTooltip="{{ 'MENU.LOGOUT' | translate }}" matTooltipPosition="right"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </mat-sidenav>
        <!--/ MINIMAL MENU -->

        <mat-sidenav-container class="mydrake-root-container" style="width: 100%; height: 100vh; overflow: hidden;">

            <!-- EXPANDED MENU -->
            <mat-sidenav class="mydrake-expanded-menu" mode="side" #expandedMenu fxLayout="column" disableClose="true">

                <div class="sidenav-full" fxLayout="column" kscrollbar>
                    <div class="logo">
                        <img [src]="images.logo" alt="MyDrake" width="160" (click)="openMinimalMenu()">
                    </div>
                    <div class="tenant-selection" *ngIf="tenants && tenants.length > 1">
                        <mat-form-field class="hide-underline">
                            <mat-label>{{ 'MENU.SELECTED_TENANT' | translate }}</mat-label>
                            <mat-select [(ngModel)]="selectedTenant" (selectionChange)="changeTenant($event)">
                                <mat-option *ngFor="let tenant of tenants" [value]="tenant">{{tenant.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <ul>
                        <li *ngFor="let item of menu">
                            <a [routerLink]="item.link" *ngIf="!item.isSvgIcon" fxLayout="row" fxLayoutAlign="start center">
                                <i [class]="item.icon"></i>
                                <span class="title">{{ item.title | translate }}</span>
                            </a>
                            <a [routerLink]="item.link" *ngIf="item.isSvgIcon" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon [svgIcon]="item.icon"></mat-icon>
                                <span class="title">{{ item.title | translate }}</span>
                            </a>
                        </li>
                    </ul>
                    <div fxFlex></div>
                    <ul>
                        <li>
                            <a (click)="logOut()" fxLayout="row" fxLayoutAlign="start center">
                                <i class="icon-mydrake-logout"></i>
                                <span class="title">{{ 'MENU.LOGOUT' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </div>

            </mat-sidenav>
            <!--/ EXPANDED MENU -->

        </mat-sidenav-container>

    </mat-sidenav-container>
    <!--/ ROOT CONTAINER -->

    <div fxLayout="column" fxFlex style="width: 100%">

        <div fxLayout="row" fxLayoutAlign="space-between center" style="height: 60px; background: #ffffff; border-bottom: 1px solid #eef2f5; ">

            <section fxLayout="row" fxLayoutAlign="start center" *ngIf="!hideGlobalSearch" style="flex: 1">
                <i class="material-icons" style="font-size: 30px; color: rgba(117, 117, 117, 0.5); padding: 10px 8px 10px 15px;">search</i>
                <input type="text" [(ngModel)]="textSearch" style="height: 40px; width: 100%; border: none; outline: none" placeholder="{{ 'SEARCH' | translate }}" (keyup)="textSearch$.next($event.target.value)">
            </section>

            <section fxLayout="row" fxLayoutAlign="start center" *ngIf="hideGlobalSearch"></section>

            <div fxFlex></div>

            <div *ngIf="(filterTags && filterTags.length > 0) || (orderProperties && orderProperties.length > 0) || showOrderArrow" fxLayout="row" fxLayoutAlign="start center" style="border-left: 1px solid #eef2f5; height: 60px; padding: 0 5px; border-right: 1px solid #eef2f5; font-size: .915rem;">

                <mat-form-field class="hide-underline" *ngIf="filterTags && filterTags.length > 0" style="padding-right: 15px; padding-top: 25px;">
                    <mat-label> {{ 'SHOW_ONLY' | translate }} </mat-label>
                    <mat-select class="hide-underline" [(value)]="selectedFilterTag">
                        <mat-option *ngIf="allFilterTag" [value]="allFilterTag.value"> {{ allFilterTag.text | translate }}</mat-option>
                        <mat-option *ngFor="let filterTag of filterTags" [value]="filterTag">
                            {{ filterTagTranslatePath + filterTag | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="hide-underline" *ngIf="orderProperties && orderProperties.length > 0" style="padding-top: 25px;">
                    <mat-label> {{ 'ORDER_BY' | translate }} </mat-label>
                    <mat-select [(value)]="selectedOrderProperty">
                        <mat-option *ngFor="let orderProperty of orderProperties" [value]="orderProperty">
                            {{ orderByTranslatePath + orderProperty | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button style="margin-left: 5px;" mat-icon-button *ngIf="showOrderArrow" (click)="toggleShowOrderArrow()">
                    <mat-icon *ngIf="!descending" class="material-icons"
                        style="font-size: 23px;color: #757575;">arrow_upward
                    </mat-icon>
                    <mat-icon *ngIf="descending" class="material-icons"
                        style="font-size: 23px;color: #757575;">arrow_downward
                    </mat-icon>
                </button>

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" style="border-left: 1px solid #eef2f5; height: 60px;  margin-right: 7px; border-right: 1px solid #eef2f5; cursor: pointer;" [matMenuTriggerFor]="menuProfile">
                <k-avatar [avatarId]="userLoggedIn.avatar" [name]="userLoggedIn.name" type="circle" size="40px" fontsize="24px" style="padding: 0 15px"></k-avatar>
                <div style="padding-right: 20px;">
                    <div style="font-weight:bold; font-size: 12px; text-transform:uppercase">{{ userLoggedIn.name }}
                    </div>
                    <div style="font-style: italic; color: #757575; font-size: 11px;">{{ userLoggedIn.job }}</div>
                </div>
                <mat-menu #menuProfile="matMenu">
                    <button mat-menu-item (click)="onChangePassword()">
                        <mat-icon>vpn_key</mat-icon>
                        <span> {{ 'CHANGE_PASSWORD' | translate }}</span>
                    </button>
                </mat-menu>
            </div>

            <section style="margin-right: 10px;">
                <button mat-icon-button [matMenuTriggerFor]="menuLanguage">
                    <mat-icon>language</mat-icon>
                </button>
                <mat-menu #menuLanguage="matMenu">
                    <button mat-menu-item (click)="onChangeCulture('pt-br')">{{ 'LANGUAGE.PORTUGUESE' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('en-us')">{{ 'LANGUAGE.ENGLISH' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('fr-fr')">{{ 'LANGUAGE.FRENCH' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('es-es')">{{ 'LANGUAGE.SPANISH' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('ja-ja')">{{ 'LANGUAGE.JAPANESE' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('zh-zh')">{{ 'LANGUAGE.CHINESE' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('de-de')">{{ 'LANGUAGE.GERMAN' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('it-it')">{{ 'LANGUAGE.ITALIAN' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('no-no')">{{ 'LANGUAGE.NORWEGIAN' | translate }}</button>
                    <button mat-menu-item (click)="onChangeCulture('ru-ru')">{{ 'LANGUAGE.RUSSIAN' | translate }}</button>
                </mat-menu>
            </section>
        </div>

        <div style="height: calc(100vh - 60px)">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
