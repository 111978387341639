<mat-form-field class="example-full-width">
    <input matInput
           placeholder="{{prop.title}}"
           [required]="prop.required"
           [formControl]="domainControl"
           [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelect($event)" (closed)="onClosed()">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.text }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
