import {OnInit, Component, EventEmitter, Input, Output} from "@angular/core";
import * as moment from 'moment';

@Component({
    selector: 'k-input-time',
    styleUrls: ['./k-input-time.component.scss'],
    template: `
        <mat-form-field>
            <input matInput type="time" [(ngModel)]="formattedDate" name="{{name}}" placeholder="{{placeholder}}" (change)="onChange($event)" [required]="required" [disabled]="disabled">
        </mat-form-field>`,
})
export class KInputTimeComponent implements OnInit {

    public formattedDate;

    @Input() public name: string;
    @Input() public placeholder: string;
    @Input() public required = false;
    @Input() public disabled = false;

    @Output() public dateChange: EventEmitter<string> = new EventEmitter<string>();
    public innerDate: any;

    constructor() {
        this.innerDate = '';
    }

    get date() {
        return this.innerDate;
    }

    @Input()
    set date(val) {
        this.innerDate = val;
        this.formattedDate = moment(this.innerDate).format('HH:mm');
        this.dateChange.emit(this.innerDate);
    }

    ngOnInit() {
        this.formattedDate = '';
    }

    public onChange(event: any) {
        if (event) {
            let time = moment(event.target.value, 'HH:mm');
            let dateTime = moment(this.innerDate);

            dateTime.hour(time.get('hour'));
            dateTime.minute(time.get('minute'));

            this.date = dateTime;
        }
    }
}
