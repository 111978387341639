import { Component } from '@angular/core';
import { TemplateComponent } from '../template';

@Component({
    selector: 'inbox-card-appointment',
    templateUrl: './participant-logistic-schedule.component.html',
    styleUrls: ['./participant-logistic-schedule.component.scss']
})
export class InboxCardParticipantLogisticScheduleComponent extends TemplateComponent {

    constructor() {
        super();
    }
}
