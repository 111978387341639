import { ElementRef, HostListener, Directive, OnInit, Input, Renderer2 } from '@angular/core';

import * as _ from 'lodash';

@Directive({
    selector: '[selectItem]',
    exportAs: 'selectItem',
})
export class SelectItemDirective implements OnInit {

    @Input() items: Array<any>;
    @Input() propertyId: string;
    @Input() selectItem: any;

    private readonly _nativeElement: any;

    constructor(private _el: ElementRef, private _renderer: Renderer2) {
        this._nativeElement = _el.nativeElement;
        this.items = [];
    }

    ngOnInit(): void {
        if (!this.items) {
            throw new Error('É necessário informar uma coleção onde serão guardados os items selecionados');
        }
    }

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event) {
        if (!this.hasNotSelectable(event.toElement)) {
            if (!this.selectItem.isSelected) {

                if (this.items.length === 0) {
                    this.select(this._nativeElement, this.selectItem);
                } else {
                    if (event.ctrlKey) {
                        this.select(this._nativeElement, this.selectItem);
                    } else {
                        this.clearList();
                        this.select(this._nativeElement, this.selectItem);
                    }
                }
            } else {
                if (event.ctrlKey) {
                    this.unselectItem(this._nativeElement, this.selectItem);
                } else {
                    this.clearList();
                    this.select(this._nativeElement, this.selectItem);
                }
            }
        }
    }

    public clearList() {
        if (this.items.length > 0) {
            for (let i = this.items.length - 1; i >= 0; i--) {
                this._renderer.removeClass(this.items[i].el, 'k-selected');
                this.items[i].item.isSelected = false;
                this.items.splice(i, 1);
            }
        }
    }

    private select(element: ElementRef, item: any) {
        this._renderer.addClass(element, 'k-selected');
        this.selectItem.isSelected = true;
        this.items.push({ el: element, item: item });
    }

    private unselectItem(element: ElementRef, item: any) {
        this._renderer.removeClass(element, 'k-selected');
        item.isSelected = false;
        _.remove(this.items, (obj) => obj.item[this.propertyId] === item[this.propertyId]);
    }

    private hasNotSelectable(element: any) {
        if (element.classList.contains('not-selectable')) return true;
        return element.parentElement && this.hasNotSelectable(element.parentElement);

    }

}
