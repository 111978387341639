<k-dialog *ngIf="!loadingInitialData" [title]="availableService?.name" [iconId]="availableService?.imageIconId">

    <k-dialog-content class="content" fxLayout="column" style="padding: 10px 15px 10px 10px;">
        <k-formio-list 
            [linkedFormInstances]="linkedForms" 
            [showButtons]="false" #kformiolist
        >
        </k-formio-list>
        <mat-card class="loading" *ngIf="loading">
            <div>
                <mat-spinner class="spinner"></mat-spinner>
            </div>
        </mat-card>
    </k-dialog-content>

    <k-dialog-footer>
        <button style="margin-right: 15px;" *ngIf="availableService.currentIssuePendingToRequestId" [disabled]="loading"  color="warn" mat-button (click)="onCancelDraft()">{{ 'ACTIONS.DISCART_DRAFT' | translate }}</button>
        <button class="draft" mat-button [disabled]="loading" (click)="onSaveDraft()">{{ 'ACTIONS.SAVE_DRAFT' | translate }}</button>
        <button class="send" mat-button [disabled]="loading"  (click)="onRequest()">{{ 'REQUEST_CENTER.REQUEST' | translate }} </button>
    </k-dialog-footer>
</k-dialog>
<mat-spinner *ngIf="loadingInitialData"></mat-spinner>
