<div class="k-flow-transition-log">
    <div class="header-wrapper">
        <h3>{{'TASK_STATE_CHANGES_HISTORY' | translate}}</h3>

        <button mat-icon-button mat-dialog-close>
            <i class="material-icons">clear</i>
        </button>
    </div>
    <div class="content-wrapper">
        <div *ngFor="let item of transitions; let i = index">
            <div class="transition-log-item">
                <mat-card [class.even]="(i % 2 === 0)" [class.odd]="(i % 2 === 1)">
                    <div class="time-info">
                        <mat-icon aria-hidden="false">event</mat-icon>
                        <span>{{ item.timeStamp | i18nDate:'full' : false }}</span>
                    </div>
                    <div class="description">
                        <span *ngIf="!item.previousState">
                            {{ 'CREATED_ON_STATE' | translate }}
                            <span class="emphasis">{{ item.targetState }}</span>
                        </span>

                        <span *ngIf="item.previousState && item.action">
                            {{ 'ACTION_TAKEN' | translate }}
                            <span class="emphasis">{{ item.action }}</span>
                            {{ 'FROM_STATE' | translate }}
                            <span class="emphasis">{{ item.previousState }}</span>
                            {{ 'TO_STATE' | translate }}
                            <span class="emphasis">{{ item.targetState }}</span>
                        </span>

                        <span *ngIf="item.previousState && !item.action">
                            {{ 'FORCED_STATE' | translate }}
                            <span class="emphasis">{{ item.action }}</span>
                            {{ 'FROM_STATE' | translate }}
                            <span class="emphasis">{{ item.previousState }}</span>
                            {{ 'TO_STATE' | translate }}
                            <span class="emphasis">{{ item.targetState }}</span>
                        </span>

                        <ul *ngIf="item.extractedArguments">
                            <li *ngFor="let arg of item.extractedArguments">
                                <div *ngIf="arg.value || arg.image">
                                    <label>{{ arg.title }}</label>
                                    <div *ngIf="!arg.image && arg.value" class="value">{{ arg.value }}</div>
                                    <img *ngIf="!arg.value && arg.image" [src]="arg.image"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </mat-card>
                <div class="user-wrapper">
                    <k-avatar [name]="item.user" [avatarId]="item.userAvatarId" [type]="(item.isSystem ? 'system' : 'user')"
                              size="70px"
                              matTooltip="{{ (item.isSystem ? 'SYSTEM' : item.user) | translate }}"></k-avatar>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-wrapper">
        <button mat-flat-button mat-dialog-close>{{ 'OK' | translate }}</button>
    </div>
</div>
