<mat-sidenav-container style=" background:whitesmoke; overflow:hidden; height: 100%;">

    <div *ngIf="logistics.length > 0">
        <div id="logistic-panel">

            <div autoresize fix="143" kscrollbar>
                <div *ngFor="let logistic of logistics" (click)="onDetail(logistic)" [className]="logistic.serviceStatus === 'Cancelado' || logistic.serviceStatus === 'CanceladoComPagamento' ? 'logistic logistic-canceled' : 'logistic'">

                    <!-- SEPARATOR -->
                    <div class="separator" fxLayoutAlign="center center">
                        <span *ngIf="logistic.serviceStatus === 'Cancelado' || logistic.serviceStatus === 'CanceladoComPagamento'" class="cancel-status">{{ 'APPOINTMENTS.LOGISTIC_NEED.CANCEL' | translate }}</span>
                    </div>
                    <!--/ SEPARATOR -->

                    <div class="header">
                        <div class="right-box">
                            <div class="date">
                                <div>{{ logistic.start | i18nDate:'date'}}</div>
                                <div>{{ logistic.start | i18nDate:'time'}}</div>
                            </div>
                            <div class="bullet"></div>
                        </div>
                        <icon-transport-type [type]="logistic.type"></icon-transport-type>                      
                        <div class="type">{{ 'TYPES.LOGISTIC_NEED.' + logistic.type | translate }}</div>
                    </div>

                    <!-- ORIGIN & DESTINATION -->
                    <div class="info" *ngIf="logistic.type != 'RT'">
                        <label>{{ 'APPOINTMENTS.LOGISTIC_NEED.PROVIDER' | translate }}</label>
                        <div class="value">{{ logistic.provider | whenEmpty:'-' }}</div>
                        <br>

                        <label>{{ 'APPOINTMENTS.LOGISTIC_NEED.RESERVATION_NUMBER' | translate }}</label>
                        <div class="value">{{ logistic.reservationNumber | whenEmpty:'-' }}</div>
                        <br>

                        <div *ngIf="logistic.type !== 'Hosting'">
                            <label>{{ 'APPOINTMENTS.LABELS.ORIGIN' | translate }}</label>
                            <div class="address">{{logistic.origin}}</div>
                            <label>{{ 'APPOINTMENTS.LABELS.DESTINATION' | translate }}</label>
                            <div class="address -last">{{logistic.destination}}</div>
                        </div>

                        <div *ngIf="logistic.type === 'Hosting'">
                            <label>{{ 'APPOINTMENTS.LABELS.LOCAL' | translate }}</label>
                            <div class="address -last">{{logistic.destination}}</div>
                        </div>

                        <br>
                        <label>{{ 'APPOINTMENTS.LABELS.NOTE' | translate }}</label>
                        <div class="note">{{ logistic.workerObservation | whenEmpty:'-' }}</div>

                    </div>
                    <!--/ ORIGIN & DESTINATION -->

                    <div class="footer">
                        <div class="right-box">
                            <div class="date" *ngIf="logistic.end">
                                <div>{{ logistic.end | i18nDate:'date'}}</div>
                                <div>{{ logistic.end | i18nDate:'time'}}</div>
                            </div>
                            <div class="bullet"></div>
                        </div>
                    </div>

                    <!-- SEPARATOR -->
                    <div class="separator"></div>
                    <!--/ SEPARATOR -->

                </div>
            </div>
        </div>
    </div>

    <div class="not-found" *ngIf="logistics.length == 0">
        <div class="item">
            <i class="icon-mydrake-car"></i>
        </div>
        <div class="message">{{ 'APPOINTMENTS.LOGISTIC_NEED.NOT_FOUND' | translate }}</div>
    </div>

    <mat-sidenav mode="over" #infoSideNav position="end" style="width: 420px; background:whitesmoke; overflow: hidden;">
        <mydrake-logistic-info [logistic]="selected" (back)="onBack()"></mydrake-logistic-info>
    </mat-sidenav>

</mat-sidenav-container>
