import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "environments/environment";
import { ParametersService } from "app/shared/providers";

@Injectable()
export class AdditionalEventsRouterGuardService implements CanActivate {

    constructor(private parametersService: ParametersService, private router: Router) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const canActivate = await this.parametersService.hasAnyOccurrenceThatCanBeInformedByWorker();
        if (!canActivate) {
            this.router.navigate([environment.redirectUrl]);
        }
        return canActivate;
    }
}
