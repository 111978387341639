import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { TrainingRequestService } from './training.service';
import { TrainingComponent } from './training.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        InfiniteScrollModule,
        NgScrollbarModule
    ],
    exports: [
        TrainingComponent
    ],
    declarations: [
        TrainingComponent
    ],
    providers: [
        TrainingRequestService
    ],
})
export class MyDrakeTrainingModule { }
