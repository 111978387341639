import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { FlowTransitionLog } from './models/transition-log.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mydrake/environments/environment';

@Injectable()
export class KFlowTransitionLogApiService {

    constructor(private _http: HttpClient) { }

    public getTransitionLog(domainId: string): Observable<Array<FlowTransitionLog>> {
        const params = new HttpParams().set("domainId", domainId);

        return this._http.get(`${environment.apiUrl}Integration/Inbox/GetTransitionLogToWorker`, { params: params })
            .pipe(map((result: Array<FlowTransitionLog>) => result));
    }
}
