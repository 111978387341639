import { KAvatarComponent } from './k-avatar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/shared/core/material.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
    ],
    exports: [
        KAvatarComponent
    ],
    declarations: [
        KAvatarComponent
    ],
    providers: []
})
export class KAvatarModule { }
