
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//RxJS
import { Observable } from 'rxjs';

//Services and Providers
import { environment } from 'environments/environment';

//Domain
import { VacationRequestView, VacationView } from '@mydrake/domain/read/views/vacation-planner';
import { VacationPlannerRule } from '@mydrake/domain/read/models/vacation-planner';

@Injectable()
export class VacationRequestService {

    constructor(private _http: HttpClient) { }

    public getVacationRequests() : Observable<any>  {
        return this._http.get(environment.apiUrl + "My/VacationPlanning/VacationRequest").pipe(
        map(data => data as Array<VacationRequestView>));
    }

    public getVacations() : Observable<Array<VacationView>>  {
        return this._http.get(environment.apiUrl + "My/VacationPlanning/Vacation").pipe(
        map(data => (data as any).map(vacation => this.mapToVacation(vacation))));
    }

    private mapToVacation(obj : any){
        let vacation = new VacationView();

        vacation.id = obj.id;
        vacation.start = obj.start;
        vacation.end = obj.end;
        vacation.workerId = obj.workerId;
        vacation.isConfirmed = obj.isConfirmed;
        vacation.aquisitivePeriodId = obj.aquisitivePeriodId;
        vacation.acquisitivePeriodStart = obj.acquisitivePeriodStart;
        vacation.acquisitivePeriodEnd = obj.acquisitivePeriodEnd;
        vacation.vacationRequestId = obj.vacationRequestId;

        return vacation;
    }
}
