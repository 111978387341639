export enum DocumentFilterTag {
    All = 'All',
    Rg = 'Rg',
    Cpf = 'Cpf',
    Passport = 'Passport',
    Visa = 'Visa',
    OccupationalHealth = 'OccupationalHealth',
    Additional = 'Additional',
    Certificate = 'Certificate',
}
