
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { UserModel } from '@mydrake/domain/read/models/core';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class UserLoggedIdService {

    constructor(private http: HttpClient) { }

    public getUser() {
        return this.http
            .get(environment.apiUrl + 'User/Info').pipe(
            map(res => res as UserModel))
    }

    public registerUserOnTenant(tenantId: string): Observable<any> {
        const cmd = {
            tenantId: tenantId,
            isMobile: false
        };

        return this.http.post(environment.apiUrl + '/My/Registration/RegisterUserOnTenant', cmd);
    }

    public selectTenant(tenantId: string): Observable<any> {
        return this.http.post(environment.apiUrl + '/User/SelectTenant?tenantId=' + tenantId, {});
    }
}
