import { SafeResourceUrl } from "@angular/platform-browser";

export class DocumentInfo {
    public id: string;
    public originalUrl: string;
    public title: string;
    public contentType: string;
    public size: number;
    public lastUpdate: Date;
    public isTemporary: boolean;
    public hash: string;
    public content: SafeResourceUrl;
    public isNew: boolean = false;
    public alreadyDownloaded: boolean = false;

    public isImage(): boolean {
        return this.contentType === 'image/jpeg' || this.contentType === 'image/png' || this.contentType === 'image/gif';
    }



}

export class Attachment {
    public id: string;
    public originalUrl: string;
    public fileName: string;
    public type: string;
    public size: string;
}

export class UploadUriInfo {
    public documentId: string;
    public url: string;
}
