import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'k-file-icon',
    template: `<i class="k-file-icon {{ type }}" [style.width.px]="size" [style.height.px]="size" [style.background-size.px]="size"></i>`,
    styleUrls: ['./k-file-icon.component.scss']
})

export class KFileIconComponent implements OnChanges {
    @Input()
    public contentType: string;

    @Input()
    public size = 45;

    public type = 'file';

    ngOnChanges() {
        this.type = this.getType(this.contentType);
    }

    private getType(contentType: string) {
        switch (contentType) {
            case 'folder':
                return 'folder';

            case 'folderLock':
                return 'folderLock';

            case 'folderExit':
                return 'folderExit';

            case 'image/jpeg':
            case 'jpeg':
            case 'jpg':
                return 'jpg';

            case 'image/png':
            case 'png':
                return 'png';

            case 'text/xml':
            case 'xml':
                return 'xml';

            case 'text/csv':
            case 'csv':
                return 'csv';

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'xls':
                return 'xls';

            case 'text/plain':
            case 'plain':
            case 'txt':
                return 'txt';

            case 'application/x-zip-compressed':
            case 'zip':
                return 'zip';

            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'ppt':
                return 'ppt';

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'doc':
                return 'doc';

            case 'application/pdf':
            case 'pdf':
                return 'pdf';

            case 'application/json':
            case 'json':
                return 'json';

            case 'video/mp4':
            case 'mp4':
                return 'mp4';

            case 'audio/mp3':
            case 'mp3':
                return 'mp3';

            case 'cpf':
                return 'cpf';

            case 'rg':
                return 'rg';

            case 'passport':
                return 'passport';

            case 'visa':
                return 'visa';

            case 'occupationalhealth':
                return 'occupationalhealth';

            case 'certificate':
                return 'certificate';

            default:
                return 'file';
        }
    }
}
