import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import {TranslateModule} from '@ngx-translate/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { ContactService } from "./contact-list/contact.service";
import { ContactListComponent } from "./contact-list/contact-list.component";
import { ContactInfoComponent } from "./contact-info/contact-info.component";
import {NgScrollbarModule} from 'ngx-scrollbar';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        NgScrollbarModule
    ],
    exports: [
        ContactListComponent
    ],
    declarations: [
        ContactListComponent,
        ContactInfoComponent
    ],
    providers: [
        ContactService
    ],
})
export class MyDrakeContactModule { }
