import { DocumentInfo } from '@shared/models';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'k-file-card',
    template: `<div class="k-file-card {{ type }}">
                    <k-file-icon [contentType]="document.contentType"></k-file-icon>
                    <div fxFlex>
                        <div class="title">{{ document.title }}</div>
                        <div class="size">{{ document.size | fileSize }}</div>
                    </div>
                    <div>
                        <div *ngIf="document.alreadyDownloaded" class="icon-mydrake-arrows-circle-check already-downloaded"></div>
                    </div>
                </div>`,
    styleUrls: ['./k-file-card.component.scss']
})

export class KFileCardComponent implements OnChanges {
    @Input()
    public document: DocumentInfo;
    @Input()
    public useBorder: Boolean = true;

    public type: string;

    constructor() {
        this.document = new DocumentInfo();
    }

    ngOnChanges() {
        if (this.useBorder) {
            this.type = 'border';
        }
    }
}
