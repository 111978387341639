import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'mydrake-email-not-found-dialog',
    templateUrl: 'email-not-found.component.html',
    styleUrls: ['./email-not-found.component.scss']
  })
  export class EmailNotFoundDialog {

    public email : string;

    constructor(
      public dialogRef: MatDialogRef<EmailNotFoundDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
          this.email = data.email;
       }

    onNoClick(): void {
      this.dialogRef.close();
    }

  }
