import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@mydrake/environments/environment";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class TenantResolver {
    private _currentTenantId: string;

    constructor(private http: HttpClient){}

    private resolve = (): Observable<string> => {
        return this.http.get<string>(`${environment.apiUrl}My/Profile/CurrentTenantId`)
            .pipe(tap(tenantId => this._currentTenantId = tenantId));
    }

    public currentTenantId = (): Observable<string> => 
        this._currentTenantId ? of(this._currentTenantId) : this.resolve();
}