export namespace Constants {

    export enum StorageKeys {
        Preferences = 'MyDrake.Preferences'
    }

    export enum PreferenceKeys {
        Culture = 'CULTURE'
    }

    export enum EventKeys {
        SearchTextChange = 'searchTextChange',
        HideGlobalSearch = 'hideGlobalSearch',
        OrderBy = 'orderBy',
        OrderByChange = 'orderByChange',
        FilterTag = 'filterTag',
        FilterTagChange = 'filterTagChange',
        ShowOrderArrow = 'showOrderArrow',
        OrderArrowChange = 'orderArrowChange'
    }
}
