<div class="card">
    <mat-accordion class="resource-step-documents" *ngFor="let step of steps | orderBy: 'index'">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'INBOX.ONLINE_TRAINING.STEP' | translate }}: {{step.title}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <k-document-view [useBorder]="false" [documents]="step.documents">
            </k-document-view>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        </mat-expansion-panel>
    </mat-accordion>
</div>