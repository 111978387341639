import { Component, Input, OnInit } from '@angular/core';
import { InboxMapping } from "../types/mapping";

@Component({
    selector: 'inbox-icon-type',
    template: `<i [className]="icon"></i>`,
    styleUrls: ['./inbox-icon.component.scss']
})

export class InboxIconType implements OnInit {

    @Input() type: string;
    @Input() inverse: string;

    public icon: string;
    

    ngOnInit() {

        this.icon = InboxMapping.getIcon(this.type);

        if (this.inverse === "true") {
            this.icon += ' inverse';
        }
    }
}
