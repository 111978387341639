import { Component, Input, OnChanges } from "@angular/core";

@Component({
    selector: 'k-wizard-index',
    template: `<div class="k-wizard-index">
                    <div class="k-wizard-index-item" *ngFor="let item of steps" [ngClass]="{'-active': index == item, '-completed': index > item }"></div>
               </div>`,
    styleUrls: ['./k-wizard-index.component.scss']
})
export class WizardIndexComponent implements OnChanges {

    @Input()
    public index: number;

    @Input()
    public size: number;

    public steps: Array<number> = [];

    ngOnChanges() {
        this.steps = Array(this.size).fill(0).map((x, i) => i + 1);
    }
}
