<div [class]="type" role="alert">

    <div class="default" *ngIf="mode === 'default'">
        <mat-icon *ngIf="!hideIcon">{{ icon || alertIcons[type] }}</mat-icon>
        <span class="k-alert-message" *ngIf="!!message">{{ message }}</span>
    </div>

    <div class="custom" *ngIf="mode === 'custom'">
        <ng-content></ng-content>
    </div>

</div>
