import { DocumentInfo } from '@shared/models';

export class WorkerQualificationNeeds {
    public workerId: string;
    public qualificationId: string;
    public matrix: string;
    public qualification: string;
    public needsType: string;
    public expiration: Date;
    public course: string;
    public indicatedCourse: string;
    public validDays?: number;
    public trainingState: string;
    public startCourse: Date;
    public endCourse: Date;
    public status: WorkerQualificationNeedsStatus;
    public details?: QualificationNeedsContextDetails;
    public job: string;
}

export enum WorkerQualificationNeedsStatus {
    Draft = 'Draft',
    Requested = 'Requested'
}

export class QualificationNeedsContextDetails {
    public contexts: QualificationNeedsContext[];
    public attendances: AtendimentoModel[];
}

export class QualificationNeedsContext {
    public id: string;
    public date: Date;
    public workerContextType: TipoContextoTrabalhador;
    public matrix: string;
    public needsType: string;
}

export class AtendimentoModel {
    public id: string;
    public qualificacaoNome: string;
    public qualificacoesRelacionadas: string;
    public certificadora: string;
    public tipoCertificado: string;
    public origemAtendimento: string;
    public documents: Array<DocumentInfo> = [];
}

export enum TipoContextoTrabalhador {
    Cadastro,
    Turma,
    Embarque,
    Servico
}
