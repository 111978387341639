import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";




@Injectable()
export class RegisterService {

    constructor(private http: HttpClient) { }

   
    public register(email: string) {
        return this.http.post(environment.apiUrl + 'My/Registration/RegisterUser', { "email": email, "isMobile": false });
    }

}
