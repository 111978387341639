export enum WorkerType {
    Employee = "Employee",
    ThirdParty = "ThirdParty",
    HiredEmployee = "HiredEmployee",
    Sporadic = "Sporadic",
    CustomerEmployee = "CustomerEmployee",
    PersonOnboard = "PersonOnboard",
    Expatriate = "Expatriate",
    ExpatriateThirdParty = "ExpatriateThirdParty"
}
