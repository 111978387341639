<!-- COVER -->

<header>
    <div class="menu">
        <img [src]="images.logo" alt="MyDrake">
        <span fxFlex></span>
        <a (click)="login()" class="ui button -regular">Entrar</a>
    </div>
    <div class="slogan">
        <h1 class="ui white">Um Drake para chamar de seu!</h1>
        <p class="ui white">O MyDrake é a nova maneira da força de trabalho acessar informações e receber notificações
            em tempo real</p>
        <button mat-raised-button class="-regular" routerLink="/register">Registre-se Agora</button>
    </div>
</header>

<section class="browser">
    <div class="figure">
        <!--<img src="assets/img/browser.png" alt="">-->
        <videojs *ngFor="let video of videos; let idx = index" [idx]="idx" [url]="video"></videojs>
    </div>
</section>

<!--/ COVER -->

<!-- CONTENT -->

<main>

    <section class="features ui container" fxLayout="column">
        <div class="set">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100" class="slot">
                <mat-card>
                    <h3 class="title">Inbox</h3>
                    <p>Caixa de entrada para gerenciar suas atividades online</p>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100" class="slot">
                <mat-card>
                    <h3 class="title">Compromissos</h3>
                    <p>Saiba com antecedência toda programação logística </p>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100" class="slot">
                <mat-card>
                    <h3 class="title">Contatos</h3>
                    <p>Agenda com todos os contatos da força de trabalho</p>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100" class="slot">
                <mat-card>
                    <h3 class="title">Solicitação de Férias</h3>
                    <p>Permita que o trabalhador participe do fluxo de férias da sua empresa</p>
                </mat-card>
            </div>
        </div>
    </section>

    <section class="mobile">
        <div class="ui container" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column"
            fxLayoutAlign="center center">
            <div fxFlex="50" class="info">
                <h2>Versão Mobile</h2>
                <div class="ui dash"></div>
                <p class="text">Baixe agora mesmo o MyDrake Mobile! Mantenha-se conectado a sua empresa em qualquer
                    lugar.</p>
                <div class="store" fxLayout="row" fxLayoutAlign="start center">
                    <a class="google"
                        href='https://play.google.com/store/apps/details?id=com.sapiensia.mydrake&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                            alt='Disponível no Google Play'
                            src='https://play.google.com/intl/en_us/badges/images/generic/pt-br_badge_web_generic.png' /></a>
                    <a class="apple" fxLayout="row" fxLayoutAlign="start center"
                        href="https://itunes.apple.com/us/app/mydrake/id1314516300?mt=8"><img [src]="images.apple"
                            alt="Disponível na App Store"></a>
                </div>
            </div>
            <div fxFlex="50" style="text-align: center;">
                <img class="figure" [src]="images.mobile" alt="">

            </div>
        </div>
    </section>

</main>

<!--/ CONTENT -->

<!-- FOOTER -->
<sapiensia-footer></sapiensia-footer>
<!--/ FOOTER -->
