
import {finalize, tap} from 'rxjs/operators';
import { NgProgress } from '@ngx-progressbar/core';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';


import {environment} from '@mydrake/environments/environment';
import { MessageService } from '../services/message.service';
import { MessageKeys } from 'app/shared/models/message-keys.model';
import * as _ from 'lodash';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {

    private readonly _methodsToDisableWaitResponseActions: string[] = [];
    private readonly _statusCodeErrorsToRedirect: number[] = [];

    constructor(private _messenger: MessageService, public progress: NgProgress) {

        this._methodsToDisableWaitResponseActions = [ 'POST', 'PUT', 'DELETE' ];

        this._statusCodeErrorsToRedirect = [ 401, 403];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.progress.start();

        this._messenger.broadcast(MessageKeys.LoadingMessage, true);

        if (_.includes(this._methodsToDisableWaitResponseActions, req.method)) {
            this._messenger.broadcast(MessageKeys.DisableWaitResponseActions, true);
        }

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {

            if (event instanceof HttpResponse) {
                this._messenger.broadcast(MessageKeys.LoadingMessage, false);

                if (_.includes(this._methodsToDisableWaitResponseActions, req.method)) {
                    this._messenger.broadcast(MessageKeys.DisableWaitResponseActions, false);
                }
            }

        }, (err: any) => {

            if (err instanceof HttpErrorResponse) {
                this._messenger.broadcast(MessageKeys.LoadingMessage, false);

                if (_.includes(this._methodsToDisableWaitResponseActions, req.method)) {
                    this._messenger.broadcast(MessageKeys.DisableWaitResponseActions, false);
                }

                if (_.includes(this._statusCodeErrorsToRedirect, err.status)) {
                    window.location.href = environment.appUrl + environment.authorityUrl;
                }
            }

        }),finalize(() => {
            this.progress.complete();
        }),);

    }
}

