<!-- BREADCRUMB -->
<k-breadcrumb label="{{ 'MENU.TRAINING' | translate }}"></k-breadcrumb>
<!--/ BREADCRUMB -->

<div id="training-list">
    <div class="filter">
        <div class="past" [class.-active]="!future" (click)="onLastTrainings()">{{ 'TRAINING.PAST' | translate }}</div>
        <div class="future" [class.-active]="future" (click)="onNextTrainings()">{{ 'TRAINING.FUTURE' | translate }}</div>
    </div>

    <div autoresize [fix]="185" *ngIf="trainings.length > 0">

        <ng-scrollbar [autoHeightDisabled]="false">

            <div scrollViewport infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="15" [scrollWindow]="false" (scrolled)="onScrollDown()">
                <div id="training" fxLayout="column">
                    <div class="item" fxLayout="row" fxLayoutAlign="start center" *ngFor="let training of trainings">
                        <div class="providerName" fxLayoutAlign="start center">{{training.providerName}} </div>
                        <div class="" fxLayoutAlign="start center">{{training.courseName}}</div>
                        <div class="range">
                            <div class="normal" *ngIf="!training.cursed"> {{ training.type | translate }} {{ training.status | translate }}</div>
                            <div class="approved" *ngIf="!training.reproved && training.cursed"> {{ training.type | translate }} {{ 'TRAINING.APPROVED' | translate }}</div>
                            <div class="reproved" *ngIf="training.reproved  && training.cursed"> {{ training.type | translate }} {{ 'TRAINING.DISAPPROVED' | translate }}</div>
                            <div class="to">{{ 'TRAINING.TRAINING_START' | translate }}</div>
                            <k-date-viewer style="width: 70px" [date]="training.start"></k-date-viewer>
                            <div class="to">{{ 'TRAINING.TRAINING_END' | translate }}</div>
                            <k-date-viewer style="width: 70px" [date]="training.end"></k-date-viewer>
                        </div>
                    </div>
                </div>
            </div>

        </ng-scrollbar>

    </div>

    <div style="padding: 0px 15px; height: calc(100vh - 165px);" fxLayout="column" fxLayoutAlign="center center" *ngIf="loaded && trainings.length === 0">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -80px">
        <i class="icon-mydrake-training" style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
        <div style="padding: 20px 0 5px 0;
            font-weight: 400;
            font-size: 23px;
            color: #757575;">{{ 'TRAINING.NO_TRAINING_FOUND' | translate }}</div>
        <div style="font-style: italic;
            font-size: 14px; color: #757575;">{{ 'TRAINING.USE_SEARCH_FILTERS' | translate }}</div>
        </div>
    </div>
</div>
