import { FormInstancePayloadContent } from '@mydrake/domain/write/commands/form-instance/form-instance.command';
export class CreateOrUpdateIssue {
    public issueId: string;
    public title: string;
    public description: string;
    public flowIdentifier: string;
    public draftFormInstanceContents: FormInstancePayloadContent[] = [];
}


