import { Component, OnInit, Input, Directive, ViewEncapsulation, ChangeDetectionStrategy, ElementRef } from '@angular/core';


@Directive({
    selector: 'k-dialog-content',
    host: { 'class': 'k-dialog-content' }
})
export class KDialogContentComponent { }

@Directive({
    selector: 'k-dialog-footer',
    host: { 'class': 'k-dialog-footer' }
})
export class KDialogFooterComponent { }

@Component({
    selector: 'k-dialog',
    template: `
        <div class="k-dialog">
            <div class="k-dialog-title">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                    <k-icon class="k-icon" *ngIf="iconId" [iconId]="iconId" [name]="title" type="circle" size="40px">
                    </k-icon>
                    <h3 fxFlex>{{title | translate}}</h3>
                    <button mat-icon-button mat-dialog-close>
                        <i class="material-icons">clear</i>
                    </button>
                </div>
            </div>
                <div kscrollbar class="k-dialog-content-wrapper">
                    <ng-content select="k-dialog-content"></ng-content>
                </div>
            <div class="k-dialog-footer-wrapper">
                <ng-content select="k-dialog-footer"></ng-content>
            </div>
        </div>`,
    styleUrls: ['./k-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class KDialogComponent {
    @Input() title = 'Title';
    @Input() iconId: string;
}
