
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ProfileView } from '@mydrake/domain/read/views/core';
import { UserResolver } from 'app/shared/core/providers/app.user';

@Injectable()
export class ProfileService {

    constructor(private userResolver: UserResolver) { }

    public getWorker = (): Observable<ProfileView> => this.userResolver.currentUser();
}
