
    <div style="width: 100%; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 300px">

        <div class="thumbnail">
            <i class="icon-mydrake-diploma"></i>
        </div>

        <div style="width: 75%; text-align: center; font-weight: 500; max-width: 500px; margin-bottom: 5px">
            {{ 'INBOX.ONLINE_TRAINING.START_ONLINE_TRAINING_MESSAGE' | translate:{ name: context.domainEntitySnapshot.course.name, end: context.domainEntitySnapshot.end |  i18nDate:'date' } }}
        </div>

        <br />

        <mydrake-inbox-transition [transitions]="context.availableTransitions" [flowType]="context.flowType" [domainId]="domainId" [inboxId]="inboxId"></mydrake-inbox-transition>
    </div>

