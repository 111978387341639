import { Combo } from "@shared/models";
import { Option } from "@shared/models"
import { Moment } from "moment";
import { UUID } from "angular2-uuid";

import * as moment from "moment";

const ISO8601 = 'YYYY-MM-DDTHH:mm:ss';

export class AdditionalEvent {

    constructor(event: any = {}) {

        this.id = event.id;
        this.startDate = event.start ? moment(event.start) : null;
        this.endDate = event.end ? moment(event.end) : null;
        this.value = event.value;
        this.occurrence = new Option(event.occurrenceId, event.occurrenceDescription);
        this.operationalUnit = new Option(event.rigId, event.rigDescription);
        this.costCenter = new Option(event.costCenterId, event.costCenterDescription);
        this.reason = event.justification;
    }

    public id: string;
    public startDate: Moment;
    public endDate: Moment;
    public value: number;
    public occurrence: Option;
    public operationalUnit: Option;
    public costCenter: Option;
    public reason: string;
}

export class CreateAdditionalEventCommand {

    constructor(private event: AdditionalEvent) {
        this.id = UUID.UUID();
        this.start = this.event.startDate.format(ISO8601);
        this.end = this.event.endDate.format(ISO8601);
        this.value = this.event.value;
        this.occurrenceId = this.event.occurrence.id;
        this.costCenterId = this.event.costCenter ? this.event.costCenter.id : null;
        this.rigId = this.event.operationalUnit ? this.event.operationalUnit.id : null;
        this.justification = event.reason;
    }

    public id: string;
    public start: string;
    public end: string;
    public value: number;
    public occurrenceId: string;
    public rigId: string;
    public costCenterId: string;
    public justification: string;
}

export class ChangeAdditionalEventCommand {
    constructor(private event: AdditionalEvent) {
        this.id = event.id;
        this.newStart = event.startDate.format(ISO8601);
        this.newEnd = event.endDate.format(ISO8601);
        this.newValue = event.value;
        this.newOccurrenceId = event.occurrence.id;
        this.newCostCenterId = event.costCenter ? event.costCenter.id : null;
        this.newRigId = event.operationalUnit ? event.operationalUnit.id : null;
        this.newJustification = event.reason;
    }

    public id: string;
    public newStart: string;
    public newEnd: string;
    public newValue: number;
    public newOccurrenceId: string;
    public newRigId: string;
    public newCostCenterId: string;
    public newJustification: string;
}

export class RemoveAdditionalEventsCommand {

    constructor(ids: Array<string> = []) {
        this.ids = ids;
    }

    public ids: Array<string>;

}
