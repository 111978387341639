import {Component, OnInit} from '@angular/core';
import {FieldRenderTemplate} from '../field-render-template';

@Component({
    selector: 'k-simple-text-field',
    templateUrl: './k-simple-text-field.component.html',
    styleUrls: ['./k-simple-text-field.component.scss']
})

export class KSimpleTextFieldComponent extends FieldRenderTemplate implements OnInit {

    ngOnInit () {
        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.prop.isValid = this.validation();
        });
    }

    onChange(evt: any){
        this.prop.isValid = this.validation();
    }

    private validation(): boolean {
        return (this.prop.required === true && this.prop.value !== '') || this.prop.required === false;
    }
}
