import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { DateAdapter } from "@angular/material/core";

/**
 * Constante que representa do Idioma default;
 */
export const DEFAULT_LANGUAGE: string = 'en-us';

@Injectable()
export class CultureProvider {

    private _onCultureChange: EventEmitter<CultureChangeEvent> = new EventEmitter<CultureChangeEvent>();

    constructor(
        private http: HttpClient,
        private service: TranslateService,
        private adapter: DateAdapter<any>) {
    }

    private configured = false;

    /**
     * Configura os serviços necessários para dar suporte a internacionalização;
     */
    public configure(): void {

        if (!this.configured)
        {
            this.configured = true;

            //Suporte aos idiomas INGLÊS e PORTUGUÊS;
            this.service.addLangs([
                'en-us',
                'pt-br',
                'fr-fr',
                'es-es',
                'ja',
                'zh-cn',
                'de',
                'it',
                'no',
                'ru',
            ]);

            const language = this.currentCulture;

            //Configura o idioma default da aplicação
            this.service.setDefaultLang(language);

            //Configura o locale do Moment e dos componentes do Material e DevExpress
            this.setLocale(language);
        }
    }

    /**
     * Retorna o idioma atual;
     */
    get currentCulture(): string {
        return this.service.currentLang || DEFAULT_LANGUAGE;
    }

    /**
     * Emissor do evento de idioma alterado;
     */
    get onCultureChange(): EventEmitter<CultureChangeEvent> {
        return this._onCultureChange;
    }

    /**
     * Altera o idioma da aplicação;
     *
     * @param language : idioma desejado;
     */
    public changeCulture(language: string): void {

        this.service.use(language);

        this.setLocale(language);

        this._onCultureChange.emit({ language: language });
    }

    /**
     * Configura o Moment, DevExpress e Material para o locale correspondente
     * ao idioma desejado;
     *
     * @param language : idioma desejado;
     */
    private setLocale(language: string) {

        //Moment.js
        moment.locale(language);

        //DatePicker Material
        this.adapter.setLocale(language);
    }
}

/**
 * Evento de alteração de Idioma
 */
export interface CultureChangeEvent {
    language: string;
}
