import {filter, debounceTime, distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { environment } from "environments/environment";
import { MenuItem } from "../shared/models/menu-item.model";
import { Constants } from "../shared/core/constants";
import { UserModel } from "@mydrake/domain/read/models/core/user.model";
import {
    MyDrakeEventEmitter,
    SettingsService,
    UserLoggedIdService
} from "@shared/services";
import { ProfileService } from "./profile/profile.service";

import {of, Subject, Subscription} from 'rxjs';

import { NavigationStart, Router } from "@angular/router";
import { CultureProvider } from "@shared/providers";
import { ParametersService } from "../shared/providers";
import { MsalService } from 'app/shared/services/msal.service';

@Component({
    selector: "app-core",
    templateUrl: "./core.component.html",
    styleUrls: ["./core.component.scss"]
})
export class CoreComponent implements OnInit, OnDestroy {
    @ViewChild("minimalMenu", { static: true }) minimalMenu: MatSidenav;
    @ViewChild("expandedMenu", { static: true }) expandedMenu: MatSidenav;

    private _selectedOrderProperty;
    private _selectedFilterTag;

    public hideGlobalSearch: boolean;
    public filterTags: any[];
    public orderProperties: any[];
    public descending: boolean;
    public userLoggedIn: UserModel = new UserModel();
    public menu: Array<MenuItem>;
    public textSearch = "";
    public filterTagTranslatePath: string;
    public orderByTranslatePath: string;
    public tenants: Array<any>;
    public selectedTenant: any;
    public showOrderArrow: boolean;
    public allFilterTag: { value: string, text: string };

    public textSearch$: Subject<string> = new Subject<string>();

    public images: any = {
        logo: `${environment.basePath}/assets/img/mydrake.png`,
        minilogo: `${environment.basePath}/assets/img/k.png`
    };

    private hideGlobalSeatchSubscription: Subscription;
    private filterTagSubscription: Subscription;
    private orderBySubscription: Subscription;
    private orderArrowSubscription: Subscription;

    constructor(
        router: Router,
        private emitter: MyDrakeEventEmitter,
        private profile: ProfileService,
        private culture: CultureProvider,
        private settingsService: SettingsService,
        private parameters: ParametersService,
        private userService: UserLoggedIdService,
        private msalService: MsalService
    ) {
        this.hideGlobalSeatchSubscription = this.emitter
            .get(Constants.EventKeys.HideGlobalSearch)
            .subscribe(value => (this.hideGlobalSearch = value));

        this.hideGlobalSeatchSubscription = this.emitter
            .get(Constants.EventKeys.ShowOrderArrow)
            .subscribe(res => {
                this.showOrderArrow = res.value;
                this.descending = res.defaultValue;
            });

        this.filterTagSubscription = this.emitter
            .get(Constants.EventKeys.FilterTag)
            .subscribe(res => {
                this.filterTags = res.value;

                if (res.allText && res.allValue) {
                    this.allFilterTag = { value: res.allValue, text: res.allText }
                }

                this._selectedFilterTag = res.defaultValue;
                this.filterTagTranslatePath = res.translatePath;
            });

        this.orderBySubscription = this.emitter
            .get(Constants.EventKeys.OrderBy)
            .subscribe(res => {
                this.orderProperties = res.value;
                this._selectedOrderProperty = res.defaultValue;
                this.orderByTranslatePath = res.translatePath;
            });

        this.textSearch$
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(textValue => {
                this.textSearch = textValue;
                this.emitter
                    .get(Constants.EventKeys.SearchTextChange)
                    .emit(textValue);
            });

        router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.textSearch = "";
                this.filterTags = [];
                this.orderProperties = [];
                this.showOrderArrow = false;
            });
    }

    toggleShowOrderArrow() {
        this.descending = !this.descending;
        this.emitter
            .get(Constants.EventKeys.OrderArrowChange)
            .emit(this.descending);
    }

    get selectedOrderProperty() {
        return this._selectedOrderProperty;
    }

    set selectedOrderProperty(res) {
        this._selectedOrderProperty = res;
        this.emitter.get(Constants.EventKeys.OrderByChange).emit(res);
    }

    get selectedFilterTag() {
        return this._selectedFilterTag;
    }

    set selectedFilterTag(res) {
        this._selectedFilterTag = res;
        this.emitter.get(Constants.EventKeys.FilterTagChange).emit(res);
    }

    async ngOnInit() {

        this.culture.configure();

        this.loadMenu();

        this.profile.getWorker().subscribe(worker => {
            this.userLoggedIn.name = worker.name;
            this.userLoggedIn.avatar = worker.avatar;
            this.userLoggedIn.job = worker.job;
            this.tenants = worker.tenants;

            this.selectedTenant = this.tenants.find(t => {
                return t.current;
            });

            if (worker.language) {
                this.culture.changeCulture(worker.language);
            }

        });

        const showOccurrence = await this.parameters.hasAnyOccurrenceThatCanBeInformedByWorker();

        if (showOccurrence) {
            this.menu.push(
                new MenuItem(
                    "MENU.ADDITIONAL_EVENTS",
                    "icon-mydrake-additional-events",
                    ["/additional-events"],
                    true
                )
            );
        }
    }

    ngOnDestroy(): void {
        this.hideGlobalSeatchSubscription.unsubscribe();
        this.filterTagSubscription.unsubscribe();
        this.orderBySubscription.unsubscribe();
        this.orderArrowSubscription.unsubscribe();
        this.textSearch$.unsubscribe();
    }

    public openMinimalMenu(): void {
        this.minimalMenu.open();
        this.expandedMenu.close();
    }

    public openMenuExpanded(): void {
        this.minimalMenu.close();
        this.expandedMenu.open();
    }

    public changeTenant(evt: any) {
        const tenantId = this.selectedTenant.id;
        const shouldRegister = this.selectedTenant.hasMyDrakeAccess == false;

        of(undefined).pipe(
            switchMap(() => shouldRegister ? this.userService.registerUserOnTenant(tenantId) : of(undefined)),
            switchMap(() => this.userService.selectTenant(tenantId)),
            take(1)
        ).subscribe(() => {
            window.location.reload();
        }, (err) => {
            this.logOut();
        });
    }

    public logOut(): void {
        window.location.href = `${environment.appUrl}/Logon/Logout`;
    }

    private loadMenu() {
        this.menu = [
            new MenuItem("MENU.INBOX", "icon-mydrake-basic-message-multiple", [
                "/inbox"
            ]),
            new MenuItem("MENU.APPOINTMENTS", "icon-mydrake-calendar-1", [
                "/appointments"
            ]),
            new MenuItem("MENU.PROFILE", "icon-mydrake-profile", ["/profile"]),
            new MenuItem("MENU.CONTACTS", "icon-mydrake-contacts", [
                "/contacts"
            ]),
            new MenuItem("MENU.VACATIONS", "icon-mydrake-vacation", [
                "/vacations"
            ]),
            new MenuItem("MENU.TRAINING", "icon-mydrake-training", [
                "/training"
            ]),
            new MenuItem("MENU.REPORTS", "icon-mydrake-infographics", [
                "/reports"
            ]),
            new MenuItem("MENU.QUALIFICATION_NEEDS", "icon-mydrake-todo", [
                "/qualification-needs"
            ]),
            new MenuItem("MENU.DOCUMENTS", "icon-mydrake-folder-search", [
                "/documents"
            ]),
            new MenuItem("MENU.REQUEST_CENTER", "icon-mydrake-tasks", [
                "/request-center"
            ])
        ];
    }

    public onChangeCulture(language: string) {
        this.settingsService.changeLanguage(language).subscribe(() => {
            this.culture.changeCulture(language);
        });
    }

    public onChangePassword() {
        this.msalService.startResetPasswordFlow();
    }
}
