import { Component } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";

@Component({
    selector: 'mydrake-appointment-embark-type',
    templateUrl: "./embark.component.html",
    styleUrls: ['./embark.component.scss']
})
export class EmbarkCardAppointmentComponent extends AppointmentTemplateComponent {

}
