import { Component } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";

@Component({
    selector: 'mydrake-appointment-logistic-type',
    templateUrl: "./logistic.component.html",
    styleUrls: ['./logistic.component.scss']
})
export class LogisticCardAppointmentComponent extends AppointmentTemplateComponent {

}
