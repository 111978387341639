import { Component, OnInit, Input } from "@angular/core";
import * as _ from 'lodash';

@Component({
    selector: "mydrake-online-resource-step-documents",
    templateUrl: "./resource-step-documents.html",
    styleUrls: ["./resource-step-documents.scss"]
})
export class OnlineTrainingResourceStepDocuments implements OnInit {

    @Input()
    public steps: any;

    ngOnInit() {
        this.steps = _.filter(this.steps, (s) => s.type === 1 && s.documents.length > 0);
    }
}
