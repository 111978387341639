import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'mydrake-online-training-final-result',
    templateUrl: './final-result.component.html',
    styleUrls: ['./final-result.component.scss']
})
export class OnlineTrainingFinalResultComponent implements OnInit {

    @Input()
    public domainEntity: any;

    constructor() { }

    ngOnInit() {
    }

    get done() {
        return this.domainEntity.status === 'Done';
    }

    get approved() {
        return this.domainEntity.status === 'Approved';
    }

    get reproved() {
        return this.domainEntity.status === 'Reproved';
    }

    get certificateFileStatus() {
        return this.domainEntity.certificateFileStatus;
    }

    get certificateLink() {
        return this.domainEntity.certificateLink;
    }

    get hasMoreThanOneCertificate() {
        return this.domainEntity.hasMoreThanOneCertificate;
    }

    get manual() {
        return this.domainEntity.manual;
    }
}
