<div class="mydrake-email-not-found-dialog">

    <i class="material-icons">cancel</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.EXCEPTIONS.EMAIL_NOT_FOUND.TITLE' | translate }}</h4>

        <p class="description">
            <span>{{ 'REGISTRATION.EXCEPTIONS.EMAIL_NOT_FOUND.DESCRIPTION.FIRST' | translate }}</span>
            <b>{{email}}</b>
            <span>{{ 'REGISTRATION.EXCEPTIONS.EMAIL_NOT_FOUND.DESCRIPTION.SECOND' | translate }}</span>
        </p>

        <p>{{ 'REGISTRATION.LABELS.POSSIBLE_REASONS' | translate }}:</p>

        <div class="reason">
            <div class="bullet">1</div>
            <div class="text">{{ 'REGISTRATION.EXCEPTIONS.EMAIL_NOT_FOUND.FIRST_REASON' | translate }}</div>
        </div>

        <div class="reason">
            <div class="bullet">2</div>
            <div class="text">{{ 'REGISTRATION.EXCEPTIONS.EMAIL_NOT_FOUND.SECOND_REASON' | translate }}</div>
        </div>
    </div>

</div>
