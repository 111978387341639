import { OnInit, Component, ViewChild, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Constants } from "app/shared/core/constants";
import { MatDialog } from "@angular/material/dialog";
import { MyDrakeEventEmitter } from "@shared/services";
import { TimeSheetComponent } from "./time-sheet/time-sheet.component";
import { Report, Parameter } from "@shared/models";
import { ReportService } from "./report.service";

@Component({
    selector: "report",
    templateUrl: "report.component.html",
    styleUrls: ["report.component.scss"]
})
export class ReportComponent implements OnInit, OnDestroy {
    public loaded = false;
    public store = new Array<Report>();
    public reports = new Array<Report>();
    public selectedReports: Array<any> = [];
    private subscription: Subscription;
    private reportParameters: Parameter[];
    public wellKnownReports: Array<Report> = [
        new Report(
            "Time Sheet",
            "Time Sheet Generator",
            "REPORTS.TIME_SHEET.TITLE",
            "icon-mydrake-time-sheet",
            TimeSheetComponent,
            "PERMITE_TRABALHADOR_EMITIR_TIMESHEET_NO_MYDRAKE"
        )
    ];

    constructor(
        private dialog: MatDialog,
        private emitter: MyDrakeEventEmitter,
        private reportService: ReportService
    ) {
        this.subscription = this.emitter
            .get(Constants.EventKeys.SearchTextChange)
            .subscribe(text => this.onSearchListener(text));
    }

    ngOnInit(): void {
        this.reportService.getReportParameters().subscribe(parameters => {
            this.loaded = true;
            this.reportParameters = parameters;
            this.reports = this.wellKnownReports.filter(r =>
                this.reportParameters.some(
                    x => x.valor === "True" && x.nome === r.activeParameterKey
                )
            );
            this.store = this.reports;
        });
    }

    public onOpen(component: any): void {
        const dialog = this.dialog.open(component, { width: "800px" });
        dialog.afterClosed().subscribe();
    }

    public onSearchListener(textSearch: string): void {
        this.reports = this.store.filter(
            c => c.name.toUpperCase().indexOf(textSearch.toUpperCase()) !== -1
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
