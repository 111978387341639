export class MenuItem {

    constructor(title: string, icon: string, link: Array<string>, isSvgIcon: boolean = false){
        this.title = title || '';
        this.icon = icon || '';
        this.link = link || new Array<string>();
        this.isSvgIcon = isSvgIcon;
    }

    public title: string;
    public icon: string;
    public link: Array<string>;
    public isSvgIcon: boolean;
}
