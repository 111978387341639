import { FruitionPeriodModel } from "@mydrake/domain/read/models/vacation-planner/fruition-period.model";
import { VacationPlannerRule } from "./vacation-planner-rule.model";

import { UUID } from 'angular2-uuid';
import * as _ from 'lodash';

export class FruitionSuggestionModel {
    public id : string;
    public ruleConfiguration: VacationPlannerRule;
    public suggestedPeriods : Array<FruitionPeriodModel> = [];
    public informedPeriods : Array<FruitionPeriodModel> = [];

    constructor(rule: VacationPlannerRule, suggestion: any = null) {

        this.ruleConfiguration = rule;

        if (suggestion) {
            this.id = suggestion.id,
            this.suggestedPeriods = _.filter(suggestion.periods, {'type': 'Suggested'});
            this.informedPeriods = _.filter(suggestion.periods, {'type': 'Informed'});
        } else {
            this.id = UUID.UUID();
        }
    }

    public hasInformedPeriods() {
        return this.informedPeriods.length > 0;
    }

    public hasSuggestedPeriods() {
        return this.suggestedPeriods.length > 0;
    }

}
