import { GlobalInterceptor } from './core/interceptors/global.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule, ModuleWithProviders } from '@angular/core';

//Components
import { SapiensiaFooter } from './components/sapiensia-footer/sapiensia-footer.component';
import { MyDrakeHeader } from './components/mydrake-header/mydrake-header.component';

import { AutoResizeDirective } from './directives/autoresize.directive';
import { SelectItemDirective } from './directives/selectItem.directive';

import { KScrollModule } from './components/k-scrollbar/scrollbar.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserLoggedIdService } from './core/services/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CultureProvider } from './core/providers/app.culture';
import { MyDrakeUserPreference } from './core/services/app.preferences';
import { MyDrakeEventEmitter } from './core/services/emitter.service';
import { WizardModule } from './components/k-wizard/k-wizard.module';
import { DragulaModule } from 'ng2-dragula';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from './core/material.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MyDrakePipesModule } from './pipes/pipes.module';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgProgressModule } from '@ngx-progressbar/core';
import { SharedComponentsModule } from './components/components.module';
import { DMS } from './core/services/dms.service';
import { KSnackBar } from './core/services/k-snackbar.service';
import { CommonApi } from './services/common.api';
import { MessageService } from './core/services/message.service';
import { KWaitRequestDirective } from './directives/k-wait-request.directive';
import { ParametersService } from './providers';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MsalService } from './services/msal.service';
import { SettingsService } from './core/services/settings.service';
import {UnreadItemService} from './core/services/not-read-item.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        KScrollModule,
        WizardModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MyDrakePipesModule,
        SharedComponentsModule,
        DragulaModule.forRoot(),
        NgProgressModule.forRoot(),
        MatPasswordStrengthModule.forRoot()
    ],
    exports: [
        HttpClientModule,
        FlexLayoutModule,
        SapiensiaFooter,
        MyDrakeHeader,
        TranslateModule,
        AutoResizeDirective,
        SelectItemDirective,
        KWaitRequestDirective,
        KScrollModule,
        WizardModule,
        DragulaModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MyDrakePipesModule,
        NgProgressModule,
        SharedComponentsModule,
        MatPasswordStrengthModule
    ],
    declarations: [
        SapiensiaFooter,
        MyDrakeHeader,
        AutoResizeDirective,
        SelectItemDirective,
        KWaitRequestDirective
    ],
    entryComponents: [
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true }
    ]
})
export class MyDrakeSharedModule {
    static forRoot(): ModuleWithProviders<MyDrakeSharedModule> {
        return {
            ngModule: MyDrakeSharedModule,
            providers: [
                MyDrakeUserPreference,
                CultureProvider,
                UserLoggedIdService,
                MyDrakeEventEmitter,
                CommonApi,
                DMS,
                KSnackBar,
                MessageService,
                MsalService,
                ParametersService,
                SettingsService,
                UnreadItemService,
                { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
                { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
                { provide: MAT_DATE_LOCALE, useValue: 'en-us' },
            ]
        };
    }
}
