export class CreateOrUpdateFormInstanceContentsByDomainEntity
{
    public flowIdentifier: string;
    public domainEntityId: string;
    public flowType: string;
    public newContents: FormInstancePayloadContent[] = [];
}

export class FormInstancePayloadContent
{
    constructor(formId: string, payload: string) {
        this.formId = formId;
        this.payload = payload;
    }

    public formId: string;
    public payload: string;
}