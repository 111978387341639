import { Component, ViewChild, Input, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { TemplateComponent } from "./template";
import { InboxMapping } from "./mapping";
import { CommonApi } from "app/shared/services/common.api";
import { KSnackBar } from "@shared/services";

@Component({
    selector: 'inbox-template',
    template: '<div class="inbox-template-message"><div #container></div></div>'
})
export class TemplateFactoryComponent implements OnDestroy, OnChanges {

    @ViewChild('container', { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    @Input()
    public type: string;

    @Input()
    public context: any;

    @Input()
    public inboxId: string;

    @Input()
    public domainId: string;

    private componentRef: ComponentRef<{}>;

    constructor(private factoryResolver: ComponentFactoryResolver, private commonApi: CommonApi, private notifier: KSnackBar) { }

    ngOnChanges(changes: SimpleChanges) {

        if (this.type) {
            this.container.clear();
            let componentType = InboxMapping.getTemplate(this.type);
            let factory = this.factoryResolver.resolveComponentFactory(componentType);
            this.componentRef = this.container.createComponent(factory);
            let instance = <TemplateComponent>this.componentRef.instance;
            instance.context = this.context;
            instance.inboxId = this.inboxId;
            instance.domainId = this.domainId;
            instance.commonApi = this.commonApi;
            instance.notifier = this.notifier;
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
