import { OptionModel } from "@mydrake/domain/read/models/online-training/option.model";

export class QuestionModel {
    public id: string;
    public index: number;
    public isMandatory: boolean;
    public title: string;
    public options: OptionModel[] = [];
    public answer: string;
    public essayAnswer: string;
    public type: QuestionType;
}

export enum QuestionType {
    MultipleChoice = 'MultipleChoice',
    Essay = 'Essay'
}
