import * as moment from 'moment'

export class VacationView {
    public id: string;
    public start : Date;
    public end: Date;
    public aquisitivePeriodId : string;
    public workerId : string;
    public isConfirmed : boolean;
    public acquisitivePeriodStart : Date;
    public acquisitivePeriodEnd : Date;
    public vacationRequestId : string;

    public getDays(){
        return moment(this.end).diff(this.start, 'days') + 1;
    }

    public vacationRequestIdIsEmpty() {
        return !this.vacationRequestId || this.vacationRequestId.replace(/[0-]/g, '') === "";
    }
}
