<div>
    <span>{{ 'INBOX.LABELS.TASKBAR' | translate }}</span>
    <section class="actions">
        <button mat-icon-button matTooltip="{{ 'INBOX.TOOLTIPS.USER_INTERACTION_HISTORY' | translate }}" (click)="onShowTransitionLog()">
            <mat-icon>description</mat-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="defaultOkTransition" matTooltip="{{ defaultOkTransition.name }}" aria-label="Ok" (click)="onFireTransition(defaultOkTransition)">
            <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button color="warn" *ngIf="defaultCancelTransition" matTooltip="{{ defaultCancelTransition.name }}" aria-label="Cancel" (click)="onFireTransition(defaultCancelTransition)">
            <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button *ngIf="transitions.length > 0" matTooltip="{{ 'INBOX.TOOLTIPS.POSSIBLE_TRANSITIONS' | translate }}" (click)="select.open()">
            <mat-icon>swap_horiz</mat-icon>
            <mat-select #select class="transitions">
                <mat-option *ngFor="let transition of transitions" [value]="transition" (click)="onFireTransition(transition)">{{ transition.name }}</mat-option>
            </mat-select>
        </button>
    </section>
</div>
