import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mydrake-online-training-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {

    @Input()
    public domainEntity: any;

    constructor() { }

  ngOnInit(): void {
  }

}
