<div fxLayout="column">

        <button mat-icon-button (click)="onBack()" style="position: absolute; right: 10px; top: 17px;">
                <mat-icon class="md-24">clear</mat-icon>
            </button>

    <div fxLayout="column" fxLayoutAlign="center center" style="padding: 40px 30px; background: rgb(7, 71, 166);">
        <k-avatar [avatarId]="contact.avatarId" [name]="contact.name" type="circle" size="150px" fontsize="72px"></k-avatar>
        <div style="text-align: center; text-transform: uppercase; font-weight: 600; margin: 15px 0px 3px 0px; color: white">{{contact.name}}</div>
        <div style="text-align: center; font-style: italic; font-size: 12px; color: #757575;">{{contact.job}}</div>
    </div>

    <div style="padding: 20px">
        <ul style="list-style: none; margin: 0; padding: 0px 8px 5px 8px;">
            <li *ngFor="let shareable of contact.contacts" style="    margin-top: 18px;">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <i style="font-size: 25px; margin-right: 15px; color: #0747a6;" [ngClass]="{
                                'icon-mydrake-basic-mail-open-text': shareable.type=='Email',
                                'icon-mydrake-telephone': shareable.type=='Phone',
                                'icon-mydrake-map-location': shareable.type=='Local'
                            }"></i>
                    <div style="font-size: 14px; font-weight: 500;">{{shareable.value}}</div>
                </div>
            </li>
        </ul>
    </div>

</div>
