import { CultureProvider } from '@shared/providers';
import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CultureChangeEvent } from '../core/providers/app.culture';
import {TranslateService} from '@ngx-translate/core';

/**
 * Formatos de exibição padrão de datas;
 */
@Pipe({
    name: 'i18nDate',
    pure: false // required to update the value when the promise is resolved
})
export class I18nDatePipe implements PipeTransform, OnDestroy {

    private value: string = '';
    private onCultureChange: Observable<CultureChangeEvent>;

    constructor(private culture: CultureProvider, private changeDetector: ChangeDetectorRef, private translateService: TranslateService) { }

    public transform(date: Date, type?: string, useUtc: boolean = true): any {

        this.format(date, type, useUtc);

        if (!this.onCultureChange) {
            this.onCultureChange = this.culture.onCultureChange.pipe(tap((event: CultureChangeEvent) => {
                this.format(date, type, useUtc);
            }));
        }

        return this.value;
    }

    private setValue(value: string) {

        if (value.includes("Invalid date")) {
            this.value = null;
        } else {
            this.value = value;
        }

        this.changeDetector.markForCheck();
    }

    private format(date: Date, type?: string, useUtc?: boolean): void {

        let momentDate = useUtc ? moment.utc(date) : moment(date);

        if (type === 'week') {
            this.setValue(momentDate.format('ddd'));
        } else if (type === 'date' || type === 'day') {
            this.setValue(momentDate.format(this.translateService.instant('DATE_FORMAT')));
        } else if (type === 'time') {
            this.setValue(momentDate.format(this.translateService.instant('TIME_FORMAT')));
        } else {
            this.setValue(`${momentDate.format(this.translateService.instant('DATE_FORMAT'))} ${momentDate.format(this.translateService.instant('TIME_FORMAT'))}`);
        }
    }

    ngOnDestroy(): void {

    }
}
