import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { TemplateComponent } from "../template";
import { Document } from "./models/document";

@Component({
    selector: "inbox-card-document",
    templateUrl: "./document.component.html",
    styleUrls: ["./document.component.scss"],
})
export class InboxCardDocumentComponent
    extends TemplateComponent
    implements OnInit
{
    public fields: { key: string; value: any; type: string }[] = [];

    public get document(): Document {
        return this.context.domainEntitySnapshot as Document;
    }

    constructor() {
        super();
    }

    public ngOnInit(): void {
        const fields = this.document.documentType.fields;

        fields.sort((a, b) => a.order - b.order);

        this.fields = fields
            .filter((x) => x.documentTypeField !== "Attachments")
            .map((x) => {
                if (x.documentTypeField === "EntityExpiration") {
                    return {
                        key: "HAS_ENTITY_EXPIRATION",
                        value: !!this.document.entityExpiration.length,
                        type: "boolean",
                    };
                } else {
                    return {
                        key: x.documentTypeField,
                        ...this.getData(x.documentTypeField),
                    };
                }
            });
    }

    public getData(documentTypeField: string): {
        value: any;
        type: string;
    } {
        const value = this.document[_.camelCase(documentTypeField)];

        if (!value) {
            return { value, type: undefined };
        }

        if (this.isBoolean(value)) {
            return { value, type: "boolean" };
        }

        if (this.isDate(value)) {
            return { value, type: "date" };
        }

        if (this.isOption(value)) {
            return { value, type: "option" };
        }

        if (this.isPeriod(value)) {
            return { value, type: "period" };
        }

        return { value, type: undefined };
    }

    private isBoolean(value): boolean {
        return _.isBoolean(value);
    }

    private isDate(value): boolean {
        return moment(value, moment.ISO_8601, true).isValid();
    }

    private isPeriod(value): boolean {
        return _.has(value, "start") && _.has(value, "end");
    }

    private isOption(value): boolean {
        return _.has(value, "id") && _.has(value, "name");
    }
}
