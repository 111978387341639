import { CultureProvider } from '@shared/providers';
import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { CultureChangeEvent } from '../core/providers/app.culture';
import { tap } from 'rxjs/operators';

@Pipe({
    name: 'toDateAgoGroup',
    pure: true
})
export class ToDateAgoGroupPipe implements PipeTransform {

    private currentLang: string;
    private onCultureChange: Observable<CultureChangeEvent>;

    constructor(private culture: CultureProvider, private changeDetector: ChangeDetectorRef) {
        this.currentLang = this.culture.currentCulture;
    }

    public transform(value: Array<any>, args?: any): any {

        let result = this.parse(value, args);

        if (!this.onCultureChange) {
            this.onCultureChange = this.culture.onCultureChange.pipe(tap((event: CultureChangeEvent) => {
                result = this.parse(value, args);
            }));
        }

        return result;
    }

    private parse(value: Array<any>, args?: any): any {
        const groupedObj = value.reduce((ctx, cur) => {
            let curDate = cur.isoDateFormat;
            let key: string = moment(curDate).format("MMMM");

            if (moment().diff(curDate, 'days') === 0) {
                key = "TODAY";
            }
            else if (moment().diff(curDate, 'days') === 1) {
                key = "YESTERDAY";
            }
            else if (moment().year() > moment(curDate).year()) {
                key = moment(curDate).year().toString();
            }
            else if (moment().month() == moment(curDate).month()) {
                key = "THIS_MONTH";
            }
            return this.generateGroupObject(key, cur, ctx);

        }, {});

        return this.sortByDate(Object.keys(groupedObj).map(function (x) {
            return { key: x, value: groupedObj[x] }
        }));
    }

    private sortByDate(retval: any) {
        let today: any[] = [];
        let yesterday: any[] = [];
        let thisMonth: any[] = [];
        let month: any[] = [];
        let year: any[] = [];

        retval.forEach(element => {
            if (element.key == "TODAY") {
                today.push(element);
            }
            if (element.key == "YESTERDAY") {
                yesterday.push(element);
            }
            if (element.key == "THIS_MONTH") {
                thisMonth.push(element);
            }
            if (moment(element.key, "MMMM", true).isValid()) {
                month.push(element);
            }
            if (moment(element.key, "YYYY", true).isValid()) {
                year.push(element);
            }
        });

        month = this.sortMonth(month);
        year = this.sortYear(year);

        let result = _.concat(today, yesterday, thisMonth, month, year)
        return result;
    }

    private sortMonth(month: any): any {
        return month.sort(function (a, b) {
            let aMonth: any = moment().month(a.key).month;
            let bMonth: any = moment().month(b.key).month;
            return aMonth - bMonth;
        });
    }

    private sortYear(year: any): any {
        return year.sort(function (a, b) {
            return b.key - a.key;
        });
    }

    private generateGroupObject(key: string, obj: Object, groupObj: Object): Object {
        if (!groupObj[key]) {
            groupObj[key] = [obj];
        } else {
            groupObj[key].push(obj);
        }
        return groupObj;
    }
}
