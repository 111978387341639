import { IVacationPlannerRuleMethodStrategy } from "./irulemethod-strategy.interface";
import { FruitionPeriodModel } from "../..";
import { VacationPlannerRuleMethodSuggestedStrategy } from "./suggested.strategy";
import { VacationPlannerRuleMethodInformedStrategy } from "./informed.strategy";

export class VacationPlannerRuleMethodBothStrategy implements IVacationPlannerRuleMethodStrategy {

    constructor(private suggestedStrategy: VacationPlannerRuleMethodSuggestedStrategy, private informedStrategy: VacationPlannerRuleMethodInformedStrategy){

    }

    createPeriods(slices: number): void {
        this.suggestedStrategy.createPeriods(slices);
        this.informedStrategy.createPeriods(slices);
    }

    updatePeriods(updatedModel: FruitionPeriodModel[]): void {
        this.suggestedStrategy.updatePeriods(updatedModel);
        this.informedStrategy.updatePeriods(updatedModel);
    }

    initialize(): void {
        this.suggestedStrategy.initialize();
        this.informedStrategy.initialize();
    }

    getPeriods(): FruitionPeriodModel[] {
        return [...this.suggestedStrategy.getPeriods(), ...this.informedStrategy.getPeriods()];
    }

}