import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AdditionalEventsApi } from "./additional-events.service";
import { Subscription } from "rxjs";
import { MyDrakeEventEmitter } from "@shared/services";
import { MatDialog } from "@angular/material/dialog";
import { CreateAdditionalEventsComponent } from "./create/create-additional-event.component";
import { SelectItemDirective } from "app/shared/directives/selectItem.directive";
import { Constants } from "../../shared/core/constants";

@Component({
    selector: 'mydrake-additional-events',
    templateUrl: 'additional-events.component.html',
    styleUrls: ['additional-events.component.scss']
})
export class AdditionalEventsComponent implements OnInit, OnDestroy {

    public events: Array<any> = [];
    public selectedEvents: Array<any> = [];
    public loaded: boolean = false;

    private page: number = 1;
    private limit: number = 9;
    private textSearch: string;

    @ViewChild(SelectItemDirective, { static: false }) selectItemDirective: SelectItemDirective;

    private subscription: Subscription;

    constructor(private api: AdditionalEventsApi, private dialog: MatDialog, private emitter: MyDrakeEventEmitter) {
        this.subscription = this.emitter.get(Constants.EventKeys.SearchTextChange).subscribe(text => this.onSearchListener(text));
    }

    ngOnInit(): void {
        this.refresh();
    }

    private refresh(): void {
        this.loaded = false;
        this.page = 1;
        this.api.getAdditionalEvents(this.page, this.limit, this.textSearch).subscribe((events) => {
            this.loaded = true;
            this.events = events;
        });
        this.onCancelSelection();
    }

    public onAdd(id: string): void {

        let dialog = this.dialog.open(CreateAdditionalEventsComponent, { width: '800px', data: { id: id } });

        dialog.afterClosed().subscribe((action) => {
            if (action === 'refresh') {
                this.refresh();
            }
        });

        if (id) {
            this.onCancelSelection();
        }
    }

    public onRemove() {
        this.api.remove(this.selectedEvents.map((se) => se.item.id as string)).subscribe(() => {
            this.refresh();
        });
    }

    public onCancelSelection() {
        if (this.selectItemDirective) {
            this.selectItemDirective.clearList();
        }
    }

    public onSearchListener(text: string): void {
        this.textSearch = text;
        this.refresh();
    }

    public onScrollDown(): void {

        this.loaded = false;

        if (this.events.length < (this.page * this.limit)) {
            return;
        }

        this.page++;
        this.api.getAdditionalEvents(this.page, this.limit, this.textSearch).subscribe((events) => {
            this.loaded = true;
            this.events.push(...events);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
