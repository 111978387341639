
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

//RxJS
import { Observable } from "rxjs";

//Services and Providers
import { environment } from "environments/environment";

//Domains
import { ContactView } from "@mydrake/domain/read/views/core";

@Injectable()
export class ContactService {

    constructor(private http: HttpClient) { }

    /**
     * Retorna a lista de contatos compartilhados com o trabalhador
     */
    public getContacts() : Observable<Array<ContactView>> {
        return this.http.get(environment.apiUrl + 'My/Contact').pipe(
            map(res => res as Array<ContactView>));
    }
}
