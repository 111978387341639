import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'mydrake-user-already-exists-dialog',
    templateUrl: 'user-already-exists.component.html',
    styleUrls: ['./user-already-exists.component.scss']
  })
  export class UserAlreadyExistsDialog {

    constructor(
      public dialogRef: MatDialogRef<UserAlreadyExistsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      this.dialogRef.close();
    }

  }
