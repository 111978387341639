import { Component, Input, OnInit, OnChanges  } from '@angular/core';
import { LogisticNeedType } from '@mydrake/domain/enums/logistic';

@Component({
    selector: 'icon-transport-type',
    templateUrl: 'icon-transport-type.component.html'
})

export class TransportTypeIcon implements OnInit {

    @Input() type: LogisticNeedType;
    @Input() inverse: string;

    public icon: string;

    public loadIcon(){
        switch (this.type) {
            case LogisticNeedType.LandTransport:
                this.icon = "icon-mydrake-car";
                break;

            case LogisticNeedType.MaritimeTransport:
                this.icon = "icon-mydrake-ship";
                break;

            case LogisticNeedType.AirTransport:
                this.icon = "icon-mydrake-airplane";
                break;

            case LogisticNeedType.Hosting:
                this.icon = "icon-mydrake-hotel";
                break;

            case LogisticNeedType.VehicleRental:
                this.icon = "icon-mydrake-taxi";
                break;

            case LogisticNeedType.Others:
                this.icon = "icon-mydrake-arrows-info";
                break;

            case LogisticNeedType.RT:
                this.icon = "icon-mydrake-helicopter";
                break;
        }

        if (this.inverse === "true") {
            this.icon += ' inverse';
        }
    }

    ngOnInit() {
        this.loadIcon();
    }

    ngOnChanges (){
        this.loadIcon();
    }
}
