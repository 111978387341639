import { Injectable } from "@angular/core";
import { KSnackBar } from '@shared/services';
import { VacationPlannerRule, VacationRequestModel, FruitionPeriodModel, FruitionSuggestionModel, VacationPlannerRuleMethods } from '@mydrake/domain/read/models/vacation-planner';

import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class VacationRequestValidator {

    constructor(private notifier: KSnackBar) { }

    public isValid(vacation: VacationRequestModel, rule: VacationPlannerRule): boolean {

        if (rule.method === VacationPlannerRuleMethods.Informed && !this.getSuggestion(vacation, rule).hasInformedPeriods) {
            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_DONT_HAVE_INFORMED_PERIODS');
            return false;
        }

        if ((rule.method === VacationPlannerRuleMethods.Suggested || rule.method === VacationPlannerRuleMethods.Both) && !this.getSuggestion(vacation, rule).hasSuggestedPeriods) {
            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_DONT_HAVE_SUGGESTED_PERIODS');
            return false;
        }

        if (rule.method === VacationPlannerRuleMethods.Informed || rule.method === VacationPlannerRuleMethods.Both) {

            //PASSO 1: Verifica se o número de férias é maior ou menor que o solicitado;
            if (!this.calculateNumberOfVacationDays(vacation, rule)) {
                return false;
            }

            //PASSO 2: Verifica se o cada período da divisão de férias está conforme a parametrização no DRAKE;
            if (!this.checkMaxOrMinQuantityOfDaysAppliedToAVacationPartition(vacation, rule)) {
                return false;
            }

            //PASSO 3: Verifica se atende ao intervalo mínimo de entre períodos de divisão de férias
            if (!this.checkMinQuantityOfDaysBetweenInSuggestions(vacation, rule)) {
                return false;
            }

            //PASSO 4: Verificar se as férias estão contidas no período concessivo
            if (!this.checkVacationPeriodIntoConcessionPeriod(vacation, rule)) {
                return false;
            }
        }

        return true;
    }

    private getSuggestion(vacation: VacationRequestModel, rule: VacationPlannerRule): FruitionSuggestionModel {
        let suggestion = _.find(vacation.fruitionSuggestions, (s: FruitionSuggestionModel) => s.ruleConfiguration.id === rule.id);
        return new FruitionSuggestionModel(suggestion);
    }

    private getInformedPeriods(vacation: VacationRequestModel, rule: VacationPlannerRule): Array<FruitionPeriodModel> {
        let suggestion = this.getSuggestion(vacation, rule);
        return suggestion.informedPeriods;
    }

    private calculateNumberOfVacationDays(vacation: VacationRequestModel, rule: VacationPlannerRule): boolean {

        let periods = this.getInformedPeriods(vacation, rule);
        let vacationDays = vacation.acquisitivePeriod.vacationDays - vacation.howManyDaysToSell;

        if (_.some(periods, p => (moment(p.end).diff(p.start, 'days') + 1) > vacationDays)) {
            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_REQUEST_GREATER_VACATION_DAYS', { days: vacationDays });
            return false;
        }

        if (_.some(periods, p => (moment(p.end).diff(p.start, 'days') + 1) < vacationDays)) {
            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_REQUEST_LESS_VACATION_DAYS', { days: vacationDays });
            return false;
        }

        return true;
    }

    private checkMaxOrMinQuantityOfDaysAppliedToAVacationPartition(vacation: VacationRequestModel, rule: VacationPlannerRule): boolean {

        if (vacation.howManyPiecesToPartition == 0 || vacation.howManyPiecesToPartition == 1) return true;

        let max = rule.maxDaysToPartitionVacation;
        let min = rule.minDaysToPartitionVacation;

        if (min && max) {

            let periods = this.getInformedPeriods(vacation, rule);

            if (max && _.some(periods, p => (moment(p.end).diff(p.start, 'days') + 1) > max)) {
                this.notifier.error('INBOX.VACATION_REQUEST.ERROR_PARTITION_MUST_BE_LESS', { days: max });
                return false;
            }

            if (min && _.some(periods, p => (moment(p.end).diff(p.start, 'days') + 1) < min)) {
                this.notifier.error('INBOX.VACATION_REQUEST.ERROR_PARTITION_MUST_BE_GREATER', { days: min });
                return false;
            }
        }

        return true;
    }

    private checkMinQuantityOfDaysBetweenInSuggestions(vacation: VacationRequestModel, rule: VacationPlannerRule): boolean {

        if (rule.minDaysPermittedBetweenVacationPartition) {

            let min = rule.minDaysPermittedBetweenVacationPartition;
            let periods = this.getInformedPeriods(vacation, rule);

            for (let i = 0; i < periods.length; i++) {

                let period = periods[i];

                for (let j = 0; j < periods.length; j++) {

                    if (period.start == periods[j].start && period.end == periods[j].end) {
                        continue;
                    }

                    if (periods[j].end > period.start) {

                        if (moment(periods[j].start).diff(period.end, 'days') + 1 < min) {
                            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_INTERVAL_VACATION_PARTITION', { days: min });
                            return false;
                        }
                    }

                    if (periods[j].start > period.end) {

                        if (moment(periods[j].end).diff(period.start, 'days') + 1 < min) {
                            this.notifier.error('INBOX.VACATION_REQUEST.ERROR_INTERVAL_VACATION_PARTITION', { days: min });
                            return false;
                        }
                    }
                }
            }

            return true;
        }

        return true;
    }

    private checkVacationPeriodIntoConcessionPeriod(vacation: VacationRequestModel, rule: VacationPlannerRule): boolean {

        let periods = this.getInformedPeriods(vacation, rule);

        for (let j = 0; j < periods.length; j++) {

            if (periods[j].start < moment(vacation.acquisitivePeriod.concessionStart).toDate() || periods[j].start > moment(vacation.acquisitivePeriod.concessionEnd).toDate()) {
                this.notifier.error('INBOX.VACATION_REQUEST.ERROR_VACATION_MUST_BE_WITHIN_CONCESSION_PERIOD');
                return false;
            }

            if (periods[j].end < moment(vacation.acquisitivePeriod.concessionStart).toDate() || periods[j].end > moment(vacation.acquisitivePeriod.concessionEnd).toDate()) {
                this.notifier.error('INBOX.VACATION_REQUEST.ERROR_VACATION_MUST_BE_WITHIN_CONCESSION_PERIOD');
                return false;
            }
        }

        return true;
    }

}