export class Report {
    public name: string;
    public description: string;
    public title: string;
    public icon: string;
    public component: any;
    public activeParameterKey?: string;

    constructor(name: string, description: string, title: string, icon: string, component: any, activeParameterKey?: string) {
        this.name = name;
        this.description = description;
        this.title = title;
        this.icon = icon;
        this.component = component;
        this.activeParameterKey = activeParameterKey;
    }
}
