import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { FieldRenderTemplate } from '../field-render-template';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';


@Component({
    selector: 'k-customdomain-field',
    templateUrl: './k-customdomain-field.component.html',
    styleUrls: ['./k-customdomain-field.component.scss']
})
export class KCustomDomainFieldComponent extends FieldRenderTemplate implements OnInit {

    public domainControl = new FormControl();

    public filteredOptions: Observable<Array<any>>;
    public options: Array<any> = [];

    public isMultipleSelect: boolean = false;

    constructor(private http: HttpClient) {
        super();
    }

    ngOnInit () {

        this.options = JSON.parse(this.prop.type.customDomainValues);
        this.options.forEach(o => {
            o.customDomainIdField = this.prop.type.customDomainIdField;
            o.customDomainTextField = this.prop.type.customDomainTextField;
        });

        this.isMultipleSelect = this.prop.type.isMultipleSelect;

        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.prop.isValid = this.validation();
        });

        this.filteredOptions = this.domainControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            filter((term: string) => typeof term === "string"),
            switchMap((term: string) => {

                if (term) {
                    return of(this.options).pipe(
                        map((options: any[]) => options.filter((option: any) => (<string>option[option.customDomainTextField]).toLowerCase().includes(term.toLowerCase())))
                    )
                }

                return of(this.options);
            })

        );
    }

    public displayFn(option: any): string {
        return option && option[option.customDomainTextField] ? option[option.customDomainTextField] : '';
    }

    public onAutoCompleteSelect(evt: MatAutocompleteSelectedEvent): void {
        const option = evt.option.value;
        this.prop.value = option[option.customDomainIdField].toString();
        this.prop.isValid = this.validation();
    }

    public onMultipleSelect(evt: MatSelectChange): void {
        const options = (<any[]>evt.value).map(o => o[o.customDomainIdField]).join();
        this.prop.value = options;
        this.prop.isValid = this.validation();
    }

    private validation(): boolean {
        return (this.prop.required === true && this.prop.value !== '') || this.prop.required === false;
    }
}
