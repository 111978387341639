import { AppointmentType } from '@mydrake/domain/enums/core';
import { LogisticNeedView } from "@mydrake/domain/read/views/core/logistic-need.view";

export class AppointmentView {

    public id: string;
    public code: string;
    public workerId: string;
    public start: Date;
    public end: Date;
    public description: string;
    public title: string;
    public local: string;
    public type: AppointmentType;

    public logisticNeeds : Array<LogisticNeedView>;

    constructor() {
        this.logisticNeeds = [];
        this.type = AppointmentType.Appointment;
    }
}
