import { AddressType } from "@mydrake/domain/enums/core";


export class AddressView {
    public id : string;
    public streetAddress : string;
    public number : string;
    public addressComplement : string;
    public district : string;
    public city: string;
    public state: string;
    public zipCode: string;
    public country: string;
    public latitude: string;
    public longitude: string;
    public type : AddressType;
    public iata: string;
    public name : string;

    public toString(){

        let text = ''

        if (this.isNotEmpty(this.name)){
            if (this.isNotEmpty(this.iata)){
                text += `${this.iata} - ${this.name}`;
            } else {
                text += this.name;
            }
            if (text){
                text += ' - ';
            }
        };

        if(this.isNotEmpty(this.streetAddress)){
            text += this.streetAddress;
        }

        if(this.isNotEmpty(this.number)){
            text += `, ${this.number}`;
        }

        if(this.isNotEmpty(this.addressComplement)){
            text += ` - ${this.addressComplement}`;
        }

        if(this.isNotEmpty(this.district)){
            text += ` - ${this.district}`;
        }

        if(this.isNotEmpty(this.city)){
            text += `, ${this.city}`;
        }

        if(this.isNotEmpty(this.state)){
            text += ` - ${this.state}`;
        }

        if(this.isNotEmpty(this.country)){
            text += ` - ${this.country}`;
        }

        if(this.isNotEmpty(this.zipCode)){
            text += `, ${this.zipCode}`;
        }

        return text;
    }

    private isNotEmpty(value : string){
        return value != null && value != "";
    }
}
