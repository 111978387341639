import {Component, OnInit} from '@angular/core';
import { FieldRenderTemplate } from '../field-render-template';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'k-time-field',
    templateUrl: './k-time-field.component.html',
    styleUrls: ['./k-time-field.component.scss']
})

export class KTimeFieldComponent extends FieldRenderTemplate implements OnInit {

    ngOnInit () {
        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.prop.timeValue = new Date();
            this.prop.isValid = this.validation();
        });
    }

    onChange(evt: Moment){
        if (evt) {
            this.prop.value = moment(evt).format('HH:mm');
            this.prop.isValid = this.validation();
        }
    }

    private validation(): boolean {
        return (this.prop.required === true && this.prop.value !== '') || this.prop.required === false;
    }
}
