import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import {TranslateModule} from '@ngx-translate/core';
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { AppointmentListComponent } from "./appointment-list/appointment-list.component";
import { EmbarkCardAppointmentComponent } from "./types/embark/card/embark.component";
import { AppointmentCardTemplateFactoryComponent } from "./types/shared/card-factory";
import { AppointmentTemplateFactoryComponent } from "./types/shared/template-factory";
import { AppointemtMapping } from "./types/shared/mapping";
import { UnknownCardTemplateComponent } from "./types/unknown/unknown.card";
import { UnknownContentTemplateComponent } from "./types/unknown/unknown.content";
import { DisembarkCardAppointmentComponent } from "./types/disembark/card/disembark.component";
import { TrainingCardAppointmentComponent } from "./types/training/card/training.component";
import { AppointmentService } from "./appointment-list/appointment.service";
import { DisembarkDetailAppointmentComponent } from "./types/disembark/detail/detail.component";
import { EmbarkDetailAppointmentComponent } from "./types/embark/detail/detail.component";
import { LogisticTimelineComponent } from "./logistic-timeline/logistic-timeline.component";
import { TransportTypeIcon } from "./icon-transport-type/icon-transport-type.component";
import { LogisticInfoComponent } from "./logistic-timeline/logistic-info/logistic-info.component";
import { TrainingDetailAppointmentComponent } from "./types/training/detail/detail.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppointmentCardAppointmentComponent } from './types/appointment/card/appointment.component';
import {AppointmentDetailAppointmentComponent} from './types/appointment/detail/detail.component';
import { LogisticDetailAppointmentComponent } from './types/logistic/detail/detail.component';
import { LogisticCardAppointmentComponent } from './types/logistic/card/logistic.component';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        InfiniteScrollModule,
        NgScrollbarModule
    ],
    exports: [
        AppointmentListComponent
    ],
    declarations: [
        AppointmentListComponent,
        AppointmentCardTemplateFactoryComponent,
        AppointmentTemplateFactoryComponent,
        EmbarkCardAppointmentComponent,
        DisembarkCardAppointmentComponent,
        TrainingCardAppointmentComponent,
        UnknownCardTemplateComponent,
        UnknownContentTemplateComponent,
        DisembarkDetailAppointmentComponent,
        EmbarkDetailAppointmentComponent,
        TrainingDetailAppointmentComponent,
        LogisticDetailAppointmentComponent,
        LogisticCardAppointmentComponent,
        LogisticTimelineComponent,
        LogisticInfoComponent,
        TransportTypeIcon,
        AppointmentCardAppointmentComponent,
        AppointmentDetailAppointmentComponent
    ],
    providers: [
        AppointemtMapping,
        AppointmentService
    ],
    entryComponents: [
        EmbarkCardAppointmentComponent,
        DisembarkCardAppointmentComponent,
        TrainingCardAppointmentComponent,
        UnknownCardTemplateComponent,
        UnknownContentTemplateComponent,
        DisembarkDetailAppointmentComponent,
        EmbarkDetailAppointmentComponent,
        TrainingDetailAppointmentComponent,
        LogisticDetailAppointmentComponent,
        LogisticCardAppointmentComponent,
        LogisticTimelineComponent,
        LogisticInfoComponent,
        TransportTypeIcon,
        AppointmentCardAppointmentComponent,
        AppointmentDetailAppointmentComponent
    ]
})
export class MyDrakeAppointmentModule { }
