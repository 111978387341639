import {Component, Input, OnInit} from '@angular/core';
import {FlowStateProperties} from '../../models/flow-state-properties.model';


@Component({
    selector: 'k-badge',
    styleUrls: ['./k-badge.component.scss'],
    template: `
        <div class="badge" [style.background-color]="prop && prop.backgroundColor ? prop.backgroundColor : backgroundColor"  [style.color]="prop ? getForeColor(prop) : textColor" >
            <ng-content></ng-content>
        </div>`
})
export class KBadgeComponent   {

    @Input() public prop: FlowStateProperties;
    @Input() public textColor = '#FFF';
    @Input() public backgroundColor = '#607D8B';

    public getForeColor(prop: FlowStateProperties): string {

        if (prop.textColor === null && prop.backgroundColor) {
            const bigint = parseInt(prop.backgroundColor.substring(1), 16);

            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;

            const color = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
            return (color > 125) ? '#000' : '#FFF';
        }

        if (prop.textColor) {
            return prop.textColor;
        }

        return '#FFF';
    }
}
