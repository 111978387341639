import { QuizModel } from "@mydrake/domain/read/models/online-training/quiz.model";
import { StepType } from "@mydrake/domain/read/models/online-training/step-type.model";
import { DocumentInfo } from "@shared/models";

export class StepModel {
    public index: number;
    public title: string;
    public description: string;
    public type: StepType;
    public quiz: QuizModel;
    public formId?: string;
	public generateApprovalOrDisapproval?: boolean;
    public documents: Array<DocumentInfo> = [];
    public minutesToAnswer: number;
    public questionsToDisplay: number;
    public numberOfAttempts: number;
    public minimumPercentageScore: number;
    public documentsDownloaded: Array<any> = [];
}
