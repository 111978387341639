// Angular
import {Injectable} from '@angular/core';

// Material
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

// Vendor
import {TranslateService} from '@ngx-translate/core';

// Others
import {MyDrakeException} from '../exceptions/my-drake.exception';

enum SnackBarType {
    Error = 'k-snackbar-error',
    Info = 'k-snackbar-info',
    Success = 'k-snackbar-success',
    Warn = 'k-snackbar-warn'
}

@Injectable()
export class KSnackBar {

    constructor(public snackBar: MatSnackBar, public translate: TranslateService) {
    }

    /**
     * Método de exibição de mensagens de erro;
     *
     * @param message : mensagem de erro a ser traduzida;
     * @param params : parâmetros referentes a interpolação da tradução da mensagem;
     */
    public error(message: string, params: any = null): void {
        this.openSnackBar(message, params, SnackBarType.Error);
    }

    /**
     * Método de exibição de mensagens de sucesso;
     *
     * @param message : mensagem de sucesso a ser traduzida;
     * @param params : parâmetros referentes a interpolação da tradução da mensagem;
     */
    public success(message: string, params: any = null): void {
        this.openSnackBar(message, params, SnackBarType.Success);
    }

    /**
     * Método de exibição de mensagens de informação;
     *
     * @param message : mensagem de informação a ser traduzida;
     * @param params : parâmetros referentes a interpolação da tradução da mensagem;
     */
    public information(message: string, params: any = null): void {
        this.openSnackBar(message, params, SnackBarType.Info);
    }

    /**
     * Método de exibição de mensagens de alerta;
     *
     * @param message : mensagem de alerta a ser traduzida;
     * @param params : parâmetros referentes a interpolação da tradução da mensagem;
     */
    public warning(message: string, params: any = null): void {
        this.openSnackBar(message, params, SnackBarType.Warn);
    }

    /**
     * Método de exibição de uma mensagem de exceção.
     * Em caso de não existir tradução para a mensagem em questão,
     * uma mensagem padrão será exibida;
     *
     * @param exception : exception a ser exibida;
     */
    public exception(exception: MyDrakeException): void {

        let settings = this.getSettings();
        settings.panelClass = [SnackBarType.Error];

        if(exception.code){

            this.translate.get([exception.code, 'CANNOT_BE_PERFORMED']).subscribe((response: string) => {

                if (response[exception.code] === exception.code) {
                    this.snackBar.open(response['CANNOT_BE_PERFORMED'], undefined, settings);
                } else {
                    this.snackBar.open(response[exception.code], undefined, settings);
                }
            });

        } else {
            this.snackBar.open(exception.message, undefined, settings);
        }
    }

    /**
     * Retorna a configuração default para exibição de mensagens;
     */
    private getSettings(): MatSnackBarConfig {

        let settings = new MatSnackBarConfig();

        settings.duration = 3000;

        return settings;
    }

    /**
     * Método padrão de exibição da notificação, levando em consideração
     * as configurações padrões e o tipo de mensagem;
     *
     * @param message : mensagem a ser traduzida;
     * @param params : parâmetros referentes a interpolação da tradução da mensagem;
     * @param type : tipo da mensagem;
     */
    private openSnackBar(message: string, params: SnackBarType, type: SnackBarType) {

        let settings = this.getSettings();
        settings.panelClass = [type];

        this.translate.get(message, params).subscribe((response: string) => {
            this.snackBar.open(response, undefined, settings);
        });
    }
}
