
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

//RxJS
import { Observable } from 'rxjs';

//Services and Providers
import { environment } from 'environments/environment';
import { TrainingInfoView } from '@mydrake/domain/read/views/core';

@Injectable()
export class TrainingRequestService {
    constructor(private http: HttpClient) { }

    /**
     *
     * @param page
     * @param limit
     * @param textSearch
     * @param statusType
     */
    public getTrainings(page: number, limit: number, textSearch: string, statusType: string, future: boolean): Observable<Array<TrainingInfoView>> {

        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());

        if (textSearch) params = params.append('textSearch', textSearch);
        if (statusType) params = params.append('statusType', statusType);

        params = params.append('segment', (future ? "Future" : "Past"));

        return this.http.get(environment.apiUrl + "My/Training/GetTrainings", { params: params }).pipe(
            map((res: any) => res.items.map(training => this.mapToTraining(training))));
    }

    private mapToTraining(json : any) {
        let training = new TrainingInfoView();

        training.courseName = json.courseName;
        training.start = json.start;
        training.end = json.end;
        training.status = json.status;
        training.reproved = json.reproved;
        training.providerName = json.providerName;
        training.type = json.type;
        training.cursed = false;

        json.type == "Presencial" ? training.type = "TRAINING.PRESENTIAL" : training.type = json.type;

        if(json.status == "Novo"){training.status = "TRAINING.NEW";}
        if(json.status == "Planejado"){training.status = "TRAINING.PLANNED";}
        if(json.status == "Aprovado"){training.status = "TRAINING.APPROVED";}
        if(json.status == "Confirmado"){training.status = "TRAINING.CONFIRMED";}
        if(json.status == "Iniciado"){training.status = "TRAINING.STARTED";}
        if(json.status == "Cursado"){ training.cursed = true; }
        if(json.status == "Cancelado"){training.status = "TRAINING.CANCELED";}
        if(json.status == "NaoAtendido"){training.status = "TRAINING.NOT_ATTENDED";}
        if(json.status == "Inscrito"){training.status = "TRAINING.REGISTERED";}

        return training;
    }
}
