import { Component, Input, OnInit } from "@angular/core";
import { ParametersService } from "app/shared/providers";
import * as _ from 'lodash';

@Component({
    selector: "mydrake-online-training-complete",
    templateUrl: "./complete.component.html",
    styleUrls: ["./complete.component.scss"]
})
export class OnlineTrainingCompleteComponent implements OnInit {

    @Input()
    public domainEntity: any;

    public contents: any;

    public hasAnyResourceStepWithDocuments: boolean;

    public showResourceFiles: boolean;

    constructor(private parametersService: ParametersService) { }

    async ngOnInit() {

        this.contents = this.domainEntity.definedContent
            ? [this.domainEntity.definedContent]
            : this.domainEntity.course.contents;

        const showOnlineTrainingResourceFiles = await this.parametersService.showResourceFiles();

        if (showOnlineTrainingResourceFiles) {
            this.showResourceFiles = showOnlineTrainingResourceFiles;
        }

        this.hasAnyResourceStepWithDocuments = _.filter(
            _.flattenDeep(_.map(this.contents, (c) => c.steps)), (s) => s.documents.length > 0).length > 0;
    }
}
