export class TrainingInfoView {

    public courseName : string;
    public providerName : string;
    public status: string;
    public reproved: boolean;
    public start : Date;
    public end : Date;
    public type : string;
    public cursed: boolean;
}