<div class="mydrake-user-already-exists-dialog">

    <i class="material-icons">cancel</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.EXCEPTIONS.USER_ALREADY_EXISTS.TITLE' | translate }}</h4>

        <p class="description">{{ 'REGISTRATION.EXCEPTIONS.USER_ALREADY_EXISTS.DESCRIPTION' | translate }}</p>

    </div>

</div>
