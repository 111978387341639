import { Injectable } from '@angular/core';

declare let Msal: any; //declare moment

@Injectable()
export class MsalService {


   
    constructor() {



     }


     public startResetPasswordFlow() {

        let b2c = JSON.parse(sessionStorage.getItem('azureadb2c'));

        const msalConfig = {
            auth: {
            clientId: b2c.clientId,
            authority: 'https://' + b2c.name + '.b2clogin.com/' + b2c.name + '.onmicrosoft.com/' + b2c.passwordResetUserFlow,
            validateAuthority: false
            }
        };
        
        const loginRequest = {
            scopes: ["openid", "profile"],
            redirectUri: window.location.protocol + '//' + window.location.host + '/Logon', 
            prompt : 'select_account'            
        };
                                        
        const myMSALObj = new Msal.UserAgentApplication(msalConfig);
        
        myMSALObj.loginRedirect(loginRequest);      
     }

}

