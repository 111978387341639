import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { KDocumentViewComponent } from './k-document-view/k-document-view.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KBreadcrumbComponent } from './k-breadcrumb/k-breadcrumb.component';
import { KDateViewer } from './k-date-viewer/k-date-viewer';
import { KDocumentViewerDialog } from './k-document-viewer/k-document-viewer.component';
import { KFileIconComponent } from './k-file-icon/k-file-icon.component';
import { KFileDropUploadComponent } from './k-file-upload/k-file-drop-upload.component';
import { KFileUploadListComponent } from './k-file-upload/k-file-upload-list.component';
import { KFileCardComponent } from './k-file-upload/k-file-card.component';
import { MaterialModule } from '../core/material.module';
import { FileUploadModule } from 'ng2-file-upload';
import { MyDrakePipesModule } from '../pipes/pipes.module';
import { KDocumentViewer } from './k-document-viewer/k-document-viewer.service';
import { KFieldRenderComponent } from './k-field-render/k-field-render.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldRenderMapping } from './k-field-render/field-render-mapping';
import { KBooleanFieldComponent } from './k-field-render/k-boolean-field/k-boolean-field.component';
import { KSimpleTextFieldComponent } from './k-field-render/k-simple-text-field/k-simple-text-field.component';
import { KDialogModule } from './k-dialog/k-dialog.module';
import { KInputTimeComponent } from './k-input-time/k-input-time.component';
import { KBadgeComponent } from './k-badge/k-badge.component';
import { KPasswordStrengthModule } from './k-password-strength/k-password-strength.module';
import { KFormioComponent } from './k-formio/k-formio.component';
import { KFormioListComponent } from './k-formio-list/k-formio-list.component';
import { KMultilineTextFieldComponent } from './k-field-render/k-multiline-text-field/k-multiline-text-field.component';
import { KTimeFieldComponent } from './k-field-render/k-time-field/k-time-field.component';
import { KDateFieldComponent } from './k-field-render/k-date-field/k-date-field.component';
import { KDomainFieldComponent } from './k-field-render/k-domain-field/k-domain-field.component';
import { KCustomDomainFieldComponent } from './k-field-render/k-customdomain-field/k-customdomain-field.component';
import { KSignatureFieldComponent } from './k-field-render/k-signature-field/k-signature-field.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { KFlowTransitionLogModule } from './k-flow-transition-log/k-flow-transition-log.module';
import { KAvatarModule } from './k-avatar/k-avatar.module';
import { KGridComponent } from './k-grid/k-grid.component';
import { KKeyValueComponent } from './k-key-value/k-key-value.component';
import { KAutocompletePagedModule } from './k-autocomplete-paged/k-autocomplete-paged.module';
import { KAlertComponent } from './k-alert/k-alert.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FlexLayoutModule,
        FileUploadModule,
        MyDrakePipesModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        KDialogModule,
        SignaturePadModule,
        KPasswordStrengthModule,
        KFlowTransitionLogModule,
        KAvatarModule,
        KAutocompletePagedModule
    ],
    exports: [
        KFormioComponent,
        KFormioListComponent,
        KBreadcrumbComponent,
        KDateViewer,
        KDocumentViewComponent,
        KDocumentViewerDialog,
        KFileIconComponent,
        KFileDropUploadComponent,
        KFileUploadListComponent,
        KFileCardComponent,
        KFieldRenderComponent,
        KInputTimeComponent,
        KBadgeComponent,
        KDialogModule,
        KPasswordStrengthModule,
        KFlowTransitionLogModule,
        KAvatarModule,
        KGridComponent,
        KKeyValueComponent,
        KAutocompletePagedModule,
        KAlertComponent
    ],
    declarations: [
        KFormioComponent,
        KFormioListComponent,
        KBreadcrumbComponent,
        KDateViewer,
        KDocumentViewComponent,
        KDocumentViewerDialog,
        KFileIconComponent,
        KFileDropUploadComponent,
        KFileUploadListComponent,
        KFileCardComponent,
        KFieldRenderComponent,
        KBooleanFieldComponent,
        KSimpleTextFieldComponent,
        KMultilineTextFieldComponent,
        KDateFieldComponent,
        KSignatureFieldComponent,
        KTimeFieldComponent,
        KDomainFieldComponent,
        KCustomDomainFieldComponent,
        KInputTimeComponent,
        KBadgeComponent,
        KGridComponent,
        KKeyValueComponent,
        KAlertComponent
    ],
    entryComponents: [
        KFormioComponent,
        KFormioListComponent,
        KBreadcrumbComponent,
        KDateViewer,
        KDocumentViewComponent,
        KDocumentViewerDialog,
        KFileIconComponent,
        KFileDropUploadComponent,
        KFileUploadListComponent,
        KFileCardComponent,
        KFieldRenderComponent,
        KBooleanFieldComponent,
        KSimpleTextFieldComponent,
        KMultilineTextFieldComponent,
        KDateFieldComponent,
        KSignatureFieldComponent,
        KTimeFieldComponent,
        KDomainFieldComponent,
        KCustomDomainFieldComponent,
        KInputTimeComponent,
        KBadgeComponent
    ],
    providers: [
        KDocumentViewer,
        FieldRenderMapping
    ]
})
export class SharedComponentsModule { }
