import { IVacationPlannerRuleMethodStrategy } from "./irulemethod-strategy.interface";

import * as _ from 'lodash';

import { FruitionPeriodModel } from "../../fruition-period.model";
import { FruitionSuggestionModel } from "../../fruition-suggestion.model";

export class VacationPlannerRuleMethodSuggestedStrategy implements IVacationPlannerRuleMethodStrategy {

    constructor(private suggestion: FruitionSuggestionModel) {
    }

    createPeriods(slices: number): void {
        // Não se aplica
    }
    
    updatePeriods(updatedModel: FruitionPeriodModel[]): void {
        this.suggestion.suggestedPeriods = _.chain(updatedModel).sortBy((p, i) => i).forEach((p, i) => p.priority = i + 1).value();
    }

    initialize(): void {
        this.updatePeriods(this.suggestion.suggestedPeriods);
    }
 
    getPeriods(): FruitionPeriodModel[] {
        return this.suggestion.suggestedPeriods;
    }
}