import { Component } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";

@Component({
    selector: 'mydrake-appointment-detail-appointment-type',
    templateUrl: "./detail.component.html",
    styleUrls: ['./detail.component.scss']
})
export class AppointmentDetailAppointmentComponent extends AppointmentTemplateComponent {
}
