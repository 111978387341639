import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'sapiensia-footer',
    templateUrl: 'sapiensia-footer.component.html',
    styleUrls: ['./sapiensia-footer.component.scss']
})

export class SapiensiaFooter implements OnInit {

    public sapiensia: string = `${environment.basePath}/assets/img/sapiensia.png`;

    constructor() { }

    ngOnInit() { }
}
