import {plainToClass} from 'class-transformer';
import {Component, Input, OnInit} from '@angular/core';
import {StepModel} from '@mydrake/domain/read/models/online-training';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CountdownEvent} from 'ngx-countdown';
import {QuestionType} from '@mydrake/domain/read/models/online-training/question.model';

@Component({
  selector: 'mydrake-online-training-activity-step',
  templateUrl: './activity-step.component.html',
  styleUrls: ['./activity-step.component.scss']
})
export class TrainingActivityStepComponent implements OnInit {

    @Input()
    public context: any;

    @Input()
    public domainId: string;

    @Input()
    public inboxId: string;

    public step: StepModel;

    private end : Moment;

    private serverUtcNow : Moment;

    public leftTime: number;

    public expired: boolean = false;

    public allQuestionsIsEssay: boolean;
    public hasAnyEssayQuestion: boolean;
    constructor() { }

    ngOnInit() {
        this.step = plainToClass(StepModel, this.context.domainEntitySnapshot.currentStep as StepModel);

        if (this.step.quiz && this.step.quiz.form) {
            this.hasAnyEssayQuestion = this.step.quiz.form.questions.some(question => question.type === QuestionType.Essay);
            this.allQuestionsIsEssay = this.step.quiz.form.questions.every(question => question.type === QuestionType.Essay);
        }

        if(this.step.quiz && this.step.quiz.form && !this.step.quiz.calculatedPercentageScore){
            this.serverUtcNow = moment(this.step.quiz.serverUtcNow);
            this.end = moment.utc(this.step.quiz.start).add(this.step.minutesToAnswer, 'minutes');
            this.leftTime = moment.duration(this.end.diff(this.serverUtcNow)).asSeconds();
        }
    }

    public onHandleEvent(event: CountdownEvent) {
        if(event.action === 'done'){
            this.expired = true;
        }
    }
}
