import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { InboxListComponent } from "./inbox-list/inbox-list.component";
import { InboxIconType } from "./inbox-icon/inbox-icon.component";
import { TemplateFactoryComponent } from "./types/template-factory";
import { VacationRequestComponent } from "./types/vacation-request/vacation-request.component";
import { InboxMapping } from "./types/mapping";
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { CommonModule } from "@angular/common";
import { InboxApi } from "./inbox.api";
import { VacationApi } from "./types/vacation-request/vacation.api";
import { VacationRequestValidator } from "./types/vacation-request/vacation-request.validator";
import { InboxUtils } from "./types/utils";
import { OnlineTrainingComponent } from "./types/online-training/online-training.component";
import { StartTrainingComponent } from "./types/online-training/start-training/start-training.component";
import { TrainingResourceStepComponent } from "./types/online-training/resource-step/resource-step.component";
import { TrainingActivityStepComponent } from "./types/online-training/activity-step/activity-step.component";
import { InboxTransitionComponent } from "./inbox-transition/inbox-transition.component";
import { OnlineTraininfQuizComponent } from "./types/online-training/quiz/quiz.component";
import { QuizApi } from "./types/online-training/quiz/domain/quiz.service";
import { OnlineTrainingCanceledComponent } from "./types/online-training/canceled/canceled.component";
import { InboxTransitionFormDialogComponent } from "./inbox-transition-form-dialog/inbox-transition-form-dialog.component";
import { InboxCardAppointmentComponent } from "./types/appointment/appointment.component";
import { InboxCardParticipantLogisticScheduleComponent } from "./types/participant-logistic-schedule/participant-logistic-schedule.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CountdownModule } from "ngx-countdown";
import { KIconModule } from "app/shared/components/k-icon/k-icon.module";
import { VacationRequestWizardComponent } from "./types/vacation-request/wizard/wizard.component";
import { VacationRequestRuleSelectionComponent } from "./types/vacation-request/rule-selection/rule-selection.component";
import { OnlineTrainingCompleteComponent } from "./types/online-training/complete/complete.component";
import { OnlineTrainingResourceStepDocuments } from "./types/online-training/resource-step-documents/resource-step-documents";
import { OnlineTrainingFinalResultComponent } from './types/online-training/final-result/final-result.component';
import { InboxCardDefaultComponent } from './types/default/default.component';
import { InboxTaskbarComponent } from './inbox-task-bar/inbox-task-bar.component';
import { InboxCardDocumentComponent } from "./types/document/document.component";
import { ExpiredComponent } from './types/online-training/expired/expired.component';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        MyDrakeSharedModule,
        CountdownModule,
        InfiniteScrollModule,
        KIconModule,
        NgScrollbarModule
    ],
    exports: [InboxListComponent],
    declarations: [
        InboxListComponent,
        InboxIconType,
        InboxTransitionComponent,
        InboxTransitionFormDialogComponent,
        InboxTaskbarComponent,
        TemplateFactoryComponent,
        VacationRequestComponent,
        VacationRequestWizardComponent,
        VacationRequestRuleSelectionComponent,
        OnlineTrainingComponent,
        StartTrainingComponent,
        TrainingResourceStepComponent,
        TrainingActivityStepComponent,
        OnlineTraininfQuizComponent,
        OnlineTrainingCompleteComponent,
        OnlineTrainingResourceStepDocuments,
        OnlineTrainingCanceledComponent,
        OnlineTrainingFinalResultComponent,
        InboxCardAppointmentComponent,
        InboxCardParticipantLogisticScheduleComponent,
        InboxCardDefaultComponent,
        InboxCardDocumentComponent,
        ExpiredComponent
    ],
    providers: [
        InboxMapping,
        InboxApi,
        VacationApi,
        VacationRequestValidator,
        InboxUtils,
        QuizApi
    ],
    entryComponents: [
        TemplateFactoryComponent,
        VacationRequestComponent,
        VacationRequestWizardComponent,
        VacationRequestRuleSelectionComponent,
        OnlineTrainingComponent,
        StartTrainingComponent,
        TrainingResourceStepComponent,
        TrainingActivityStepComponent,
        InboxTransitionComponent,
        InboxTransitionFormDialogComponent,
        InboxTaskbarComponent,
        OnlineTraininfQuizComponent,
        OnlineTrainingCompleteComponent,
        OnlineTrainingResourceStepDocuments,
        OnlineTrainingCanceledComponent,
        OnlineTrainingFinalResultComponent,
        InboxCardAppointmentComponent,
        InboxCardParticipantLogisticScheduleComponent,
        InboxCardDefaultComponent,
        InboxCardDocumentComponent
    ]
})
export class MyDrakeInboxModule { }
