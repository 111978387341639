import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'whenEmpty'
})
export class WhenEmptyPipe implements PipeTransform {

    transform(value: string, substitute: string): any {
        return (value) ? value : substitute;
    }

}
