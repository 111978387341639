import { Component, OnInit, OnDestroy } from '@angular/core';
import { RequestCenterService } from './request-center.service';
import { MatDialog } from '@angular/material/dialog';
import { AvailableService } from '@mydrake/domain/read/models/request-center';
import { FormInstancesAndDefinitionsModel } from '@mydrake/domain/read/models/request-center/form-instances-and-definitions.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { Constants } from 'app/shared/core/constants';
import { MyDrakeEventEmitter } from '@shared/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'request-center',
    templateUrl: './request-center.component.html',
    styleUrls: ['./request-center.component.scss']
})
export class RequestCenterComponent implements OnInit, OnDestroy {

    public availableServices = new Array<AvailableService>();
    private textSearch: string;
    private subscription: Subscription;

    constructor(
        private api: RequestCenterService,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
        private emitter: MyDrakeEventEmitter) {
        this.subscription = this.emitter.get(Constants.EventKeys.SearchTextChange)
            .subscribe((text: string) => this.onSearchListener(text));
    }

    ngOnInit(): void {
        this.refresh();
    }

    public onSearchListener(text: string): void {
        this.textSearch = text;
        this.refresh();
    }

    private refresh(): void {
        this.api.getAvailableServices(this.textSearch).subscribe(availableServices => {
            this.availableServices = availableServices;
        });
    }

    public onStart(availableService: AvailableService) {
        this.openRequest(availableService, true);
    }

    public onContinue(availableService: AvailableService) {
        this.openRequest(availableService, false);
    }

    private openRequest(availableService: AvailableService, isStart: boolean): void {

        const dialog = this.dialog.open(RequestDetailsComponent,
            {
                data:
                {
                    availableService: availableService,
                    isStart: isStart
                }
            });
        dialog.afterClosed().subscribe(() => this.refresh());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
