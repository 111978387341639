import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreComponent } from "./core/core.component";
import { CoreRouting } from "./core/core.routing";
import { HomeComponent } from "./home/home.component";
import { RegisterComponent } from "./registration/register/register.component";
import {AuthGuard} from './auth_guard';
import {HttpClient} from '@angular/common/http';

/**
 * Route constant
 */
const Routing: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, },
    { path: 'register', component: RegisterComponent },
    { path: '', component: CoreComponent, children: CoreRouting, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'home' }
];

/**
 * App routing module
 */
@NgModule({
    imports: [
        RouterModule.forRoot(Routing, { relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule],
    providers: [AuthGuard, HttpClient]
})
export class AppRoutingModule { }
