import { IVacationPlannerRuleMethodStrategy } from "./irulemethod-strategy.interface";
import { FruitionPeriodModel, FruitionSuggestionModel, VacationPlannerRule } from "../..";
import { FruitionPeriodType } from "../../fruition-period-type.enum";
import { UUID } from "angular2-uuid";
import * as _ from 'lodash';

export class VacationPlannerRuleMethodInformedStrategy implements IVacationPlannerRuleMethodStrategy {

    constructor(private suggestion: FruitionSuggestionModel, private rule: VacationPlannerRule) {

    }

    createPeriods(slices: number): void {

        let newPeriods = [];
        let newSlices = (this.rule.maxSuggestionPeriodsToChoose && this.rule.maxSuggestionPeriodsToChoose > 1) ? this.rule.maxSuggestionPeriodsToChoose : 1;

        if (slices) {
            newSlices = this.rule.maxSuggestionPeriodsToChoose * slices;
        }

        for (let j = 0; j < newSlices; j++) {
            newPeriods.push(new FruitionPeriodModel(UUID.UUID(), FruitionPeriodType.Informed));
        }

        this.suggestion.informedPeriods = newPeriods;
    }

    updatePeriods(updatedModel: FruitionPeriodModel[]): void {
        // Não se aplica
    }

    initialize(): void {
        if (this.suggestion.informedPeriods.length == 0) {        
            this.createPeriods(1);
        }
    }

    getPeriods(): FruitionPeriodModel[] {
        return _.filter(this.suggestion.informedPeriods, (p:FruitionPeriodModel) => p.start && p.end);
    }
}