<header [style.background]="logistic.serviceStatus === 'Cancelado' || logistic.serviceStatus === 'CanceladoComPagamento' ? '#9e9e9e' : '#0747a6'">
    <button mat-icon-button style="position: absolute; margin-left: 15px;" (click)="onBack()">
        <mat-icon class="md-24" aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon>
    </button>

    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <icon-transport-type [type]="logistic.type"></icon-transport-type>
        <div style="font-size: 15px; text-transform: uppercase; font-weight: 500; font-family: Poppins;margin-top: -20px; margin-bottom:5px">{{
            'TYPES.LOGISTIC_NEED.' + logistic.type | translate }}</div>
    </div>

    <div fxFlex fxLayoutAlign="center center" *ngIf="logistic.serviceStatus === 'Cancelado' || logistic.serviceStatus === 'CanceladoComPagamento'">
        <span class="cancel-status">{{ 'APPOINTMENTS.LOGISTIC_NEED.CANCEL' | translate }}</span>
    </div>
</header>

<div autoresize fix="330" kscrollbar>
    <section class="logistic-information">

        <div class="control" *ngIf="logistic.subType">
            <label>{{ 'APPOINTMENTS.LABELS.SUBTYPE' | translate }}</label>
            <div class="value">{{ logistic.subType }}</div>
        </div>

        <div class="control" *ngIf="logistic.code">
            <label>{{ 'APPOINTMENTS.LABELS.CODE' | translate }}</label>
            <div class="value">{{ logistic.code }}</div>
        </div>

        <div class="control" *ngIf="logistic.start">
            <label>{{ 'APPOINTMENTS.LABELS.START' | translate }}</label>
            <div class="value">{{ logistic.start | i18nDate }}</div>
        </div>

        <div class="control" *ngIf="logistic.end">
            <label>{{ 'APPOINTMENTS.LABELS.END' | translate }}</label>
            <div class="value">{{ logistic.end | i18nDate }}</div>
        </div>

        <div class="control" *ngIf="logistic.origin && !isHosting()">
            <label>{{ 'APPOINTMENTS.LABELS.ORIGIN' | translate }}</label>
            <div class="value">{{ logistic.origin }}</div>
        </div>

        <div class="control" *ngIf="logistic.destination && !isHosting()">
            <label>{{ 'APPOINTMENTS.LABELS.DESTINATION' | translate }}</label>
            <div class="value">{{ logistic.destination }}</div>
        </div>

        <div class="control" *ngIf="logistic.destination && isHosting()">
            <label>{{ 'APPOINTMENTS.LABELS.LOCAL' | translate }}</label>
            <div class="value">{{ logistic.destination }}</div>
        </div>

        <div class="control" *ngIf="logistic.reservationNumber">
            <label>{{ 'APPOINTMENTS.LABELS.RESERVATION_NUMBER' | translate }}</label>
            <div class="value">{{ logistic.reservationNumber }}</div>
        </div>

        <div class="control" *ngIf="logistic.provider">
            <label>{{ 'APPOINTMENTS.LABELS.PROVIDER' | translate }}</label>
            <div class="value">{{ logistic.provider }}</div>
        </div>

        <div class="control" *ngIf="logistic.note">
            <label>{{ 'APPOINTMENTS.LABELS.NOTE' | translate }}</label>
            <div class="value">{{ logistic.note }}</div>
        </div>

        <div class="control" *ngIf="logistic.trackingLink">
            <label>{{ 'APPOINTMENTS.LABELS.TRACKING_LINK' | translate }}</label>
            <div class="value">{{ logistic.trackingLink }}</div>
        </div>

    </section>
</div>
