<div class="card-step">

    <!-- HEADER -->
    <div class="card-step-header">
        <div class="type">{{ step.type.toUpperCase() | translate }}</div>
        <div class="separator"></div>
        <div class="title">{{ step.title }}</div>
    </div>
    <!--/ HEADER -->

    <!-- CONTENT -->
    <div class="card-step-content" *ngIf="!(step.quiz && step.quiz.form)">
        <div class="description">{{ step.description }}</div>
    </div>
    <!--/ CONTENT -->

    <!-- FOOTER -->
    <div class="card-step-footer">
        <div class="row">

            <div class="scout">
                <mat-icon>assignment</mat-icon>
                <label>{{ step.questionsToDisplay }} {{ 'INBOX.ONLINE_TRAINING.QUESTIONS' | translate }}</label>
            </div>
            <div class="scout">
                <mat-icon>timer</mat-icon>
                <label>{{ step.minutesToAnswer }} min</label>
            </div>
            <div class="scout">
                <mat-icon>autorenew</mat-icon>
                <label>
                    <span>{{ step.quiz ? step.quiz.currentAttempt : 0 }}&nbsp;</span>
                    <span>{{ 'OUT_OF' | translate }}&nbsp;</span>
                    <span>{{ step.numberOfAttempts }}&nbsp;</span>
                    <span>{{ 'INBOX.ONLINE_TRAINING.ATTEMPTS' | translate }}</span>
                </label>
            </div>
            <div class="scout" *ngIf="step.minimumPercentageScore && step.generateApprovalOrDisapproval && !allQuestionsIsEssay">
                <mat-icon>assignment_turned_in</mat-icon>
                <label>{{ 'INBOX.ONLINE_TRAINING.MINIMUN_PERCENTAGE_SCORE' | translate: {score: step.minimumPercentageScore } }}</label>
            </div>
            <div style="flex:1"></div>
            <div class="timer" *ngIf="step.quiz && step.quiz.form && step.quiz.score === null">
                <countdown [config]="{leftTime: leftTime}" (event)="onHandleEvent($event)">$!h!:$!m!:$!s!</countdown>
            </div>
        </div>
        <div class="row">
            <div class="scout">
                <mat-icon>info</mat-icon>
                <label>{{ 'ESSAY_QUESTIONS_WILL_NOT_BE_COUNTED_TOWARDS_THE_FINAL_EVALUATION_SCORE' | translate }}</label>
            </div>
        </div>
    </div>
    <!--/ FOOTER -->

    <div *ngIf="step.quiz && step.quiz.form && step.quiz.score == null">
        <mydrake-online-training-quiz [form]="step.quiz.form" [context]="context" [domainId]="domainId" [inboxId]="inboxId" [stepId]="step.id" [expired]="expired">
        </mydrake-online-training-quiz>
    </div>

    <div class="result-box" *ngIf="step.generateApprovalOrDisapproval && step.quiz && step.quiz.calculatedPercentageScore !== null && !allQuestionsIsEssay">
        <div class="message">
            <span>{{ 'INBOX.ONLINE_TRAINING.YOU_GOT_SCORE_IN_ACTIVITY_PART1' | translate }}</span>
            <span style="font-size: 20px; padding: 0 5px; color: #0747a6;">{{ step.quiz.calculatedPercentageScore}}%</span>
            <span>{{ 'INBOX.ONLINE_TRAINING.YOU_GOT_SCORE_IN_ACTIVITY_PART2' | translate }}</span>
        </div>
    </div>

    <div class="result-box " *ngIf="step.quiz && step.quiz.score !== null && !step.generateApprovalOrDisapproval && !allQuestionsIsEssay">
        <div class="message column ">
            <div>{{ 'INBOX.ONLINE_TRAINING.THANKS_FOR_YOUR_PARTICIPATION' | translate }} !</div>
            <div>
                <span>{{ 'INBOX.ONLINE_TRAINING.YOU_GOT_SCORE_IN_ACTIVITY_PART1' | translate }}</span>
                <span style="font-size: 20px; padding-right: 3px; color: #0747a6;">{{ step.quiz.score}}</span>
                <span>{{ 'INBOX.ONLINE_TRAINING.POINTS' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="result-box " *ngIf="step.quiz && step.quiz.score !== null && allQuestionsIsEssay">
        <div class="message column ">
            <div>{{ 'INBOX.ONLINE_TRAINING.THANKS_FOR_YOUR_PARTICIPATION' | translate }} !</div>
            <div>
                <span>{{ 'INBOX.ONLINE_TRAINING.YOU_ANSWERED_ALL_ESSAY_QUESTIONS' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="step-toolbar-transition " *ngIf="!step.quiz || (step.quiz && !step.quiz.form) || (step.quiz && step.quiz.form && step.quiz.score !== null) ">
        <mydrake-inbox-transition [transitions]="context.availableTransitions " [flowType]="context.flowType " [domainId]="domainId " [inboxId]="inboxId ">
        </mydrake-inbox-transition>
    </div>
</div>
