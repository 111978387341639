import { Component, Input, EventEmitter, AfterViewInit, AfterContentChecked, Output } from '@angular/core';
import * as _ from 'lodash';
import { Formio } from 'formiojs';
import * as translations from './i18n/translations.json';
import { CultureProvider } from '@shared/providers';
import { CultureChangeEvent } from 'app/shared/core/providers/app.culture';

@Component({
    selector: 'k-formio',
    template: `<div id="formio"></div>`,
    styleUrls: ['k-formio.component.scss']
})
export class KFormioComponent implements AfterViewInit, AfterContentChecked {

    @Input()
    public formDefinition: any;

    @Input()
    public get formInstanceContent() {
        return this.innerFormInstanceContent;
    }

    public set formInstanceContent(val) {
        this.innerFormInstanceContent = val;
        this.formInstanceContentChange.emit(val);
    }

    @Input()
    public readOnly: boolean;

    @Output()
    public formInstanceContentChange: EventEmitter<any> = new EventEmitter<any>();

    private formio: any;
    private innerFormInstanceContent: any = {};
    private currentCulture: string;
    private fallbackLanguage: string = 'en';

    constructor(private culture: CultureProvider) {
        this.currentCulture = this.culture.currentCulture.substring(0, 2);
    }

    ngAfterViewInit() {

        let self = this;

        Formio.createForm(document.getElementById('formio'),
            this.formDefinition,
            { "readOnly": this.readOnly }
        ).then(function (form) {

            form.on('change', function (value) {

                if (value.changed) {

                    let changedElement = <HTMLElement>value.changed.instance.element;

                    if (changedElement) {
                        if (value.changed.instance.isValid()) {
                            changedElement.classList.remove('has-error');
                            changedElement.classList.remove('alert');
                            changedElement.classList.remove('alert-danger');
                        } else {
                            changedElement.classList.add('has-error');
                            changedElement.classList.remove('alert');
                            changedElement.classList.remove('alert-danger');
                        }
                    }

                }
            });

            if (self.formInstanceContent && self.formInstanceContent.data && Object.keys(self.formInstanceContent.data).length > 0) {
                form.submission = self.formInstanceContent;
            }

            form.addLanguage("en", translations.en);
            form.addLanguage("pt", translations.pt);

            form.language = self.currentCulture;

            self.culture.onCultureChange.subscribe((evt: CultureChangeEvent) => {
                form.language = evt?.language?.substring(0, 2) || self.fallbackLanguage;
            })

            self.formio = form;
        });
    }

    ngAfterContentChecked() {
        if (this.formio) {
            this.formInstanceContent = this.formio.submission;
        }
    }

    public isValid(): any {
        return this.formio.checkValidity(this.formio.submission, true);
    }
}
