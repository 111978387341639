import { VacationPart } from "@mydrake/domain/write/value-objects/vacation-planner/vacation-part";

export class VacationOption {

    public id: string;
    public ruleConfigurationId: string;
    public parts: Array<VacationPart> = [];

    constructor(ruleConfigurationId: string) {
        this.ruleConfigurationId = ruleConfigurationId;
        this.parts = new Array<VacationPart>();
    }
}
