import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldRenderTemplate} from '../field-render-template';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
    selector: 'k-signature-field',
    templateUrl: './k-signature-field.component.html',
    styleUrls: ['./k-signature-field.component.scss']
})

export class KSignatureFieldComponent extends FieldRenderTemplate implements OnInit {

    @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;
    
    ngOnInit () {
        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.doValidation();
        });
    }

    public onChange(){
        this.doValidation();
        this.prop.value = this.signaturePad.toDataURL();
    }

    private doValidation(): void {
        this.prop.isValid = !(this.prop.required === true && this.signaturePad.isEmpty());
    }

    public signaturePadOptions = { 
        'canvasWidth': 500,
        'canvasHeight': 250
    };

    public clear() {
        this.signaturePad.clear();
        this.onChange();        
    }
}
