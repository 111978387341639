import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {environment} from 'environments/environment';

export type Situation = 'Ativo' | 'Inativo' | 'Afastado' | 'EmContratacao';
export type DefaultAvatarType = 'person' | 'entity' | 'system' | 'issue' | 'user';
export type ImageRendering = 'auto' | 'pixelated' | 'crisp-edges';

@Component({
    selector: 'k-avatar',
    template: `
        <img *ngIf="!letter" class="circle {{ situation?.toLowerCase()?.split(' ')?.join('') }}"
             [src]="url" [style.width]="size" [style.height]="size" [style.image-rendering]="imageRendering"
             (error)="rollbackToDefault()" [alt]="type"/>
        <div class="k-avatar circle" *ngIf="letter" [style.width]="size" [style.height]="size" [style.background]="color">
            <span class="letter" [style.font-size]="fontsize">{{ letter }}</span>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./k-avatar.component.scss']
})
export class KAvatarComponent implements OnInit, OnChanges {

    @Input() name: string;
    @Input() size: string = '100px';
    @Input() avatarId: string;
    @Input() type: DefaultAvatarType = 'person';
    @Input() situation: Situation | undefined;
    @Input() imageRendering: ImageRendering = 'auto';
    @Input() fontsize: string = '30px';

    public letter: string;

    public color: string;

    public url: string;

    private defaultProfilePath: string = `${environment.basePath}/assets/img`;

    private _colorMap = {
        A: '#ff6815',
        B: '#ff940c',
        C: '#ffb500',
        D: '#ffdb00',
        E: '#d6cf06',
        F: '#8bc43d',
        G: '#00a350',
        H: '#00a99d',
        I: '#00aff0',
        J: '#0084ce',
        L: '#005fac',
        M: '#6c7b87',
        N: '#273f50',
        O: '#263b86',
        P: '#663592',
        Q: '#973290',
        R: '#571845',
        S: '#E91E63',
        T: '#F44336',
        U: '#fdc70f',
        V: '#9c8ab4',
        W: '#74abae',
        X: '#5a8770',
        Y: '#407887',
        Z: '#544d4e'
    };

    ngOnInit() {
        this.loadAvatar();
    }

    ngOnChanges(...args: any[]) {
        this.loadAvatar();
    }

    public rollbackToDefault() {
        this.url = this.getDefaultAvatarPath();
    }

    private loadAvatar() {
        if (this.type === 'system') {
            this.url = this.getDefaultAvatarPath();
        } else {
            this.url = this.avatarId ? `${environment.apiUrl}DMS/GetStream?documentId=${this.avatarId}` : this.getDefaultAvatarPath();
        }
    }

    private setLetter() {
        this.letter = this.name.charAt(0).toUpperCase();
    }

    private setColor() {
        this.color = this._colorMap[this.name.charAt(0).toUpperCase()];
    }

    private getDefaultAvatarPath() {
        switch (this.type) {
            case 'entity':
                return `${this.defaultProfilePath}/avatarempresas2.svg`;
            case 'system':
                return `${this.defaultProfilePath}/avatarsistema2.svg`;
            case 'issue':
                return `${this.defaultProfilePath}/issue.svg`;
            default:
                if (this.name) {
                    this.setLetter();
                    this.setColor();
                }
                return `${this.defaultProfilePath}/avatarpessoas2a.svg`;
        }
    }
}
