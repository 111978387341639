
export type AlertMode = 'default' | 'custom';

export type AlertType = 'primary' 
                      | 'secondary' 
                      | 'success' 
                      | 'danger' 
                      | 'warning' 
                      | 'info' 
                      | 'light' 
                      | 'dark';

export const alertIcons = {
    primary: 'info',
    secondary: 'info',
    success: 'done',
    danger: 'close',
    warning: 'warning',
    info: 'info',
    light: 'info',
    dark: 'info',
}                     