<section class="vacation-request">

    <div *ngIf="context.domainEntitySnapshot.status == 'Draft' && !formLoaded">

        <div class="thumbnail">
            <i class="icon-mydrake-vacation"></i>
        </div>

        <div style="width: 75%; text-align: center; font-weight: 500; max-width: 500px; margin-bottom: 5px">
            {{ 'INBOX.VACATION_REQUEST.COMMON.ACQUISITIVE_PERIOD_MESSAGE' | translate: {
                '0': context.domainEntitySnapshot.acquisitivePeriod.vacationDays,
                '1': context.domainEntitySnapshot.acquisitivePeriod.start |  i18nDate:'date',
                '2': context.domainEntitySnapshot.acquisitivePeriod.end |  i18nDate:'date'} }}
        </div>

        <button class="k-action" mat-raised-button (click)="onGetForm()">{{ 'ACTIONS.SCHEDULE' | translate }}</button>

    </div>

    <div *ngIf="formLoaded">
        <mydrake-vacation-request-rule-selection [context]="context" [inboxId]="inboxId" [domainId]="domainId" [workerCategoryId]="context.domainEntitySnapshot.acquisitivePeriod.worker.categoryId"></mydrake-vacation-request-rule-selection>
    </div>

    <k-formio-list [linkedFormInstances]="context.currentStateLinkedFormInstances" (submit)="onSubmitted($event)"></k-formio-list>

    <k-document-view class="documents" *ngIf="context.workersCanViewAttachments" [documents]="context.attachments" mode="grid"></k-document-view>

</section>
