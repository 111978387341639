import { FruitionPeriodType } from "./fruition-period-type.enum";

export class FruitionPeriodModel {

    public id : string;
    public start : Date;
    public end : Date;
    public priority? : number = null;
    public type: FruitionPeriodType;

    constructor(id: string = null, type: FruitionPeriodType = null) {
        if (id) {
            this.id = id;
        }
        this.type = type || FruitionPeriodType.Suggested;
    }
}