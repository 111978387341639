import {Tab} from '../../../../../shared/models/tab.model';

export class VacationRequestWizardTabs {
    
    public sell : Tab;
    public split : Tab;
    public bonus : Tab;
    public periodPriorization : Tab;
    public periodSelection : Tab;
    public confirmation : Tab;

    constructor() {
        this.sell = new Tab(false, 1);
        this.split = new Tab(false, 2);
        this.periodPriorization = new Tab(false, 3);
        this.periodSelection = new Tab(false, 4);
        this.confirmation = new Tab(false, 5);
    }
}
