import { Component, Output, EventEmitter } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";

@Component({
    selector: 'mydrake-appointment-detail-disembark-type',
    templateUrl: "./detail.component.html",
    styleUrls: ['./detail.component.scss']
})
export class DisembarkDetailAppointmentComponent extends AppointmentTemplateComponent {

}
