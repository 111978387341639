import { VacationPlannerRule } from "../vacation-planner-rule.model";
import { VacationPlannerRuleMethods, FruitionPeriodModel } from "..";
import { VacationPlannerRuleMethodSuggestedStrategy } from "./strategies/suggested.strategy";
import { IVacationPlannerRuleMethodStrategy } from "./strategies/irulemethod-strategy.interface";
import { VacationPlannerRuleMethodInformedStrategy } from "./strategies/informed.strategy";
import { VacationPlannerRuleMethodBothStrategy } from "./strategies/both.strategy";
import { FruitionSuggestionModel } from "../fruition-suggestion.model";

export class VacationPlannerRuleMethodStrategyApplier {

    private strategy: IVacationPlannerRuleMethodStrategy;

    constructor(rule: VacationPlannerRule, suggestion: FruitionSuggestionModel) {

        let suggestedStrategy = new VacationPlannerRuleMethodSuggestedStrategy(suggestion);
        let informedStrategy =  new VacationPlannerRuleMethodInformedStrategy(suggestion, rule);

        switch (rule.method) {
            case VacationPlannerRuleMethods.Suggested:
                this.strategy = suggestedStrategy;
                break;
            case VacationPlannerRuleMethods.Informed:
                this.strategy = informedStrategy
                break;
            case VacationPlannerRuleMethods.Both:
                this.strategy = new VacationPlannerRuleMethodBothStrategy(suggestedStrategy, informedStrategy);
                break;
        }
    }

    public initialize(): void {
        this.strategy.initialize();
    }

    public createPeriods(slices: number) {
        this.strategy.createPeriods(slices);
    }

    public updatePeriods(updatedModel: FruitionPeriodModel[]){
        this.strategy.updatePeriods(updatedModel);
    }

    public getPeriods(): FruitionPeriodModel[] {
        return this.strategy.getPeriods();
    }
}