<div class="card">

    <div class="thumbnail">
        <i class="icon-mydrake-basic-elaboration-calendar-remove"></i>
    </div>

    <div class="message">
        <span>{{ 'INBOX.ONLINE_TRAINING.MESSAGE_CANCEL' | translate }}</span>  
        <span>{{ cancelationReason }}</span>
    </div>
    <div class="comment">
            <div><span style="font-weight: 500;">{{ 'INBOX.ONLINE_TRAINING.COMMENT' | translate }}</span>{{ cancelationComments }}</div>
    </div>

</div>
