import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'mydrake-inbox-transition-form-dialog',
    templateUrl: 'inbox-transition-form-dialog.component.html',
    styleUrls: ['./inbox-transition-form-dialog.component.scss']
})
export class InboxTransitionFormDialogComponent implements OnDestroy {

    public fields: Array<any> = [];
    public linkedFormInstances: any;

    constructor(private dialog: MatDialogRef<InboxTransitionFormDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.fields = data.inputProperties;
        this.linkedFormInstances = data.linkedFormInstances;
    }

    public isValid(): boolean {
        return _.every(this.fields, ['isValid', true]);
    }

    public changeFormInstance($event){
        this.linkedFormInstances[0].formInstanceContent = $event;
    }

    ngOnDestroy() {
        this.dialog.close(this.linkedFormInstances);
    }
}
