<form #f="ngForm" novalidate (submit)="onSave()">

    <k-dialog title="{{ isNew ? 'ADDITIONAL_EVENT.ADD' : 'ADDITIONAL_EVENT.EDIT' | translate }}">

        <k-dialog-content fxLayout="column" style="padding: 10px 15px 10px 10px;">

            <div fxLayout="row">

                <mat-form-field fxFlex="{{percentageFxFlex}}">
                    <mat-label>{{ 'ADDITIONAL_EVENT.OCCURRENCE' | translate }}</mat-label>
                    <input matInput [matAutocomplete]="occurrenceAutocomplete" [(ngModel)]="event.occurrence" required
                        (ngModelChange)="fetchOccurrences(event.occurrence)" name="occurrence">
                    <mat-error *ngIf="f.form.controls.occurrence?.invalid">{{ 'IS_REQUIRED' | translate }}</mat-error>
                    <mat-autocomplete #occurrenceAutocomplete="matAutocomplete" [displayWith]="displayCombo">
                        <mat-option *ngFor="let occ of occurrences | async" [value]="occ">
                            <span>{{ occ.name }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <span fxFlex *ngIf="eventTypeHasBeenDefined && !valueTypeCantBeFilled"></span>

                <mat-form-field fxFlex="48%" *ngIf="eventTypeHasBeenDefined && !valueTypeCantBeFilled">
                    <input matInput [(ngModel)]="event.value" name="value" [required]="valueIsRequired" placeholder="{{ 'ADDITIONAL_EVENT.QUANTITY' | translate }}">
                    <mat-error *ngIf="f.form.controls.value?.invalid">{{ 'IS_REQUIRED' | translate }}</mat-error>
                </mat-form-field>

            </div>

            <div fxLayout="row">

                <!-- UOP -->
                <k-autocomplete-paged
                    class="autocomplete-paged"
                    name="operationalUnit"
                    placeholder="{{ 'ADDITIONAL_EVENT.OPERATIONAL_UNIT' | translate }}"
                    [(value)]="event.operationalUnit"
                    [fetch]="fetchOperationalUnits"
                    [required]="uopIsRequired"
                ></k-autocomplete-paged>
                <!--/ UOP -->

                <span fxFlex></span>

                <!-- COST CENTER -->
                <k-autocomplete-paged
                    class="autocomplete-paged"
                    name="costCenter"
                    placeholder="{{ 'ADDITIONAL_EVENT.COST_CENTER' | translate }}"
                    [(value)]="event.costCenter"
                    [fetch]="fetchCostCenters"
                ></k-autocomplete-paged>
                <!--/ COST CENTER -->

            </div>

            <div fxLayout="row" fxLayoutAlign="start end">

                <mat-form-field fxFlex="24%">
                    <input matInput [matDatepicker]="start" placeholder="{{ 'ADDITIONAL_EVENT.START_DATE' | translate }}"
                        required [(ngModel)]="event.startDate" name="start">
                    <mat-error *ngIf="f.form.controls.start?.invalid">{{ 'INVALID_DATE' | translate }}</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                </mat-form-field>

                <span fxFlex></span>

                <k-input-time  fxFlex="23%" [(date)]="event.startDate" placeholder="{{'ADDITIONAL_EVENT.START_TIME' | translate }}" name="startTime"
                [required]="true"></k-input-time>

                <span fxFlex></span>

                <mat-form-field  fxFlex="24%">
                    <input matInput [matDatepicker]="end" placeholder="{{ 'ADDITIONAL_EVENT.END_DATE' | translate }}"
                        required [(ngModel)]="event.endDate" name="end">
                    <mat-error *ngIf="f.form.controls.end?.invalid">{{ 'INVALID_DATE' | translate }}</mat-error>
                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                </mat-form-field>

                <span fxFlex></span>

                <k-input-time  fxFlex="23%" [(date)]="event.endDate" placeholder="{{'ADDITIONAL_EVENT.END_TIME' | translate }}" name="endTime"
                [required]="true"></k-input-time>

            </div>

            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <textarea matInput [(ngModel)]="event.reason" name="reason" class="additional-event-reason" placeholder="{{ 'ADDITIONAL_EVENT.REASON' | translate }}"></textarea>
                </mat-form-field>
            </div>

        </k-dialog-content>

        <k-dialog-footer>
            <button mat-button type="submit" [disabled]="!f.valid">{{ 'ACTIONS.SAVE' | translate }}</button>
        </k-dialog-footer>

    </k-dialog>
</form>
