import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'mydrake-there-is-more-than-one-worker-by-email-dialog',
    templateUrl: 'there-is-more-than-one-worker-by-email.component.html',
    styleUrls: ['./there-is-more-than-one-worker-by-email.component.scss']
  })
  export class ThereIsMoreThanOneWorkerByEmailDialog {

    constructor(
      public dialogRef: MatDialogRef<ThereIsMoreThanOneWorkerByEmailDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      this.dialogRef.close();
    }

  }
