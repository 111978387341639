<mat-sidenav-container style="height: calc(100vh - 60px);">

    <!-- SEARCH FILTERS -->
    <mat-sidenav class="mydrake-search-sidenav" mode="side" #searchFilterSideNav position="start">

        <header>
            <div fxLayout="row" fxLayoutAlign="start center"
                style="margin: 12px; border-bottom: 1px solid #ddd;  height: 50px;   padding-bottom: 10px;">
                <div fxFlex
                    style="padding-left: 5px; text-transform: uppercase; font-weight: 600; font-size: 13px; color: #757575;">
                    Filtros Básicos</div>
                <button mat-icon-button (click)="searchFilterSideNav.close()">
                    <i class="material-icons" style="font-size: 23px;color: #757575;">clear</i>
                </button>
            </div>
        </header>

        <div style="padding: 0 10px">
            <ng-scrollbar [autoHeightDisabled]="false">
                <section style="height: calc(100vh - 195px);">
                    <div style="padding: 0 10px">
                    </div>
                </section>
            </ng-scrollbar>
        </div>
        <footer fxLayout="row">
            <div fxFlex
                style="font-weight: 600; background: rgba(158, 158, 158, 0.15); color: #757575; text-transform: uppercase;  font-size: 14px; text-transform: uppercase; padding: 18px; text-align: center;">
                Limpar</div>
        </footer>

    </mat-sidenav>
    <!--/ SEARCH FILTERS -->

    <mat-sidenav-container style="height: calc(100vh - 60px);">
        <div>
            <!-- BREADCRUMB -->
            <k-breadcrumb label="{{ 'MENU.APPOINTMENTS' | translate }}">
                <!--<button mat-icon-button (click)="onOpenFilters()"><div class="icon-mydrake-big-funnel"></div></button>-->
            </k-breadcrumb>
            <!--/ BREADCRUMB -->

            <div class="appointment-filter">
                <div class="past" [class.-active]="!future" (click)="onLastAppointments()">{{ 'PAST' | translate }}</div>
                <div class="future" [class.-active]="future" (click)="onNextAppointments()">{{ 'FUTURE' | translate }}</div>
            </div>

            <!-- APPOINTMENT LIST -->
            <div autoresize [fix]="165" *ngIf="appointments.length > 0">
               <ng-scrollbar [autoHeightDisabled]="false">
                    <div scrollViewport infinite-scroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="onScrollDown()">
                        <div id="appointment-list">
                            <div class="appointment-item" *ngFor="let appointment of appointments">
                                <k-date-viewer [date]="appointment.start"></k-date-viewer>
                                <div class="line">
                                    <div class="bullet"></div>
                                    <div class="link"></div>
                                    <mat-card fxFlex (click)="onSelectAppointment(appointment)">
                                        <mydrake-card-appointment-template [type]="appointment.type" [context]="appointment">
                                        </mydrake-card-appointment-template>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-scrollbar>
            </div>

            <div style="padding: 0px 15px; height: calc(100vh - 165px);" fxLayout="column"
                 fxLayoutAlign="center center" *ngIf="loaded && appointments.length === 0">
                <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -80px">
                    <i class="icon-mydrake-calendar-1"
                       style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
                    <div style="padding: 20px 0 5px 0;
                                    font-weight: 400;
                                    font-size: 23px;
                                    color: #757575;">{{ 'APPOINTMENTS.LABELS.NOT_ANY_APPOINTMEMTS_FOUND' | translate }}
                    </div>
                    <div style="font-style: italic;
                                    font-size: 14px; color: #757575;">{{ 'APPOINTMENTS.USE_SEARCH_FILTERS' | translate }}</div>
                </div>
            </div>

            <!--/ APPOINTMENT LIST -->

        </div>
        <mat-sidenav mode="side" #appointmentDetailSideNav position="end"
            style="width: 420px; border-left: 1px solid #eee; background: whitesmoke">
            <button mat-icon-button (click)="onCloseDetail()" style="position: absolute; right: 10px; top: 17px;">
                <mat-icon class="md-24">clear</mat-icon>
            </button>
            <mydrake-appointment-template *ngIf="selected.id" [type]="selected.type" [context]="selected"></mydrake-appointment-template>

        </mat-sidenav>
    </mat-sidenav-container>

</mat-sidenav-container>
