import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'mydrake-online-training-starting',
    templateUrl: './start-training.component.html',
    styleUrls: ['./start-training.component.scss']
})
export class StartTrainingComponent implements OnInit {

    @Input()
    public context: any;

    @Input()
    public domainId: string;

    @Input()
    public inboxId: string;

    constructor() { }

    ngOnInit() {
    }

}
