import { ElementRef, HostListener, Directive, NgZone, OnInit, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[autoresize]' })
export class AutoResizeDirective implements OnInit {

    @Input() fix: number;

    constructor(private el: ElementRef, private renderer: Renderer2, private zone: NgZone) {
        this.el = el;
        this.calculateAndSetElementHeight();
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calculateAndSetElementHeight(event.target.innerHeight);
    }

    ngOnInit(): any {
        this.calculateAndSetElementHeight();
    }

    private calculateAndSetElementHeight(windowHeight?: number) {
        let height = windowHeight | window.innerHeight;
        this.renderer.setStyle(this.el.nativeElement, 'height', (height - this.fix) + "px");
    }


}
