import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'k-breadcrumb',
    template: `
        <div class="k-breadcrumb-bar">
            <div class="k-breadcrumb-title">{{label}}</div>
            <div class="k-breadcrumb-content">
                <ng-content></ng-content>
            </div>
        </div>`,
    styleUrls: ['./k-breadcrumb.component.scss']
})

export class KBreadcrumbComponent {
    @Input() label: string;
}
