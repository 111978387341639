import { Component, OnInit } from '@angular/core';
import { DefinedEvent } from '@mydrake/domain/read/models/time-sheet';
import { TimeSheetService } from './time-sheet.service';
import { KDocumentViewer } from 'app/shared/components/k-document-viewer/k-document-viewer.service';
import { DocumentInfo } from '@shared/models';
import { GenerateTimeSheet } from '@mydrake/domain/write/commands/time-sheet/generate-time-sheet.command';
import { MyDrakeException } from '@shared/exceptions';
import { KSnackBar } from '@shared/services';
import {ParametersService} from '../../../shared/providers';

@Component({
    selector: 'mydrake-time-sheet-events',
    templateUrl: 'time-sheet.component.html',
    styleUrls: ['time-sheet.component.scss']
})
export class TimeSheetComponent implements OnInit {

    public definedEvents = new Array<DefinedEvent>();
    public startDate: Date;
    public endDate: Date;
    public note: string;
    public documents: Array<DocumentInfo>;
    public defaultMessage: string;

    constructor(
        private timeSheetService: TimeSheetService,
        public viewer: KDocumentViewer,
        private notifier: KSnackBar,
        private parametersService: ParametersService) {
    }

    ngOnInit(): void {
        this.timeSheetService.getDefinedEvents().subscribe(events => {
            this.definedEvents = events;
        });
        this.parametersService.defaultMessageForIssueTimesheetInMydrake().then(response => this.defaultMessage = response);
    }

    public onSave() {
        this.definedEvents ?
            this.generateTimeSheet() :
            this.notifier.exception(new MyDrakeException('REPORTS.TIME_SHEET.EXCEPTIONS.NO_REGISTERED_OCCURRENCES'));
    }

    private generateTimeSheet() {
        const definedEventsIds = this.definedEvents.filter(x => x.selected).map(x => x.id);
        const cmd = new GenerateTimeSheet(this.startDate, this.endDate, this.note, definedEventsIds);

        this.timeSheetService.print(cmd).subscribe(doc =>
            this.viewer.open(doc as DocumentInfo)
            ,
            (error) => {
                this.notifier.exception(new MyDrakeException(error.error.Key ?  `REPORTS.TIME_SHEET.EXCEPTIONS.${error.error.Key}` : error.error.Message));
            }
        );
    }
}
