import { Gender, WorkerCategoryType, WorkerStatus, WorkerType } from "@mydrake/domain/enums/core";
import { TelephoneView, EmailView, AddressView } from "@mydrake/domain/read/views/core";
import { TenantView } from './tenant.view';

export class ProfileView {

    constructor() {
        this.telephones = [];
        this.emails = [];
        this.addresses = [];
    }

    public id: string;
    public avatar: string;
    public name: string;
    public gender: Gender;
    public registration: string;
    public bloodType: string;
    public category: WorkerCategoryType;
    public sispat: string;
    public cpf: string;
    public rne: string;
    public status: WorkerStatus;
    public type: WorkerType;
    public isManager?: boolean;
    public admissionDate?: Date;
    public dismissalDate?: Date;
    public organizationalUnit: string;
    public job: string;
    public company: string;
    public language: string;
    public telephones: Array<TelephoneView>
    public emails: Array<EmailView>
    public addresses: Array<AddressView>
    public tenants: Array<TenantView>;
}
