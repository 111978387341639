import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import {TranslateModule} from '@ngx-translate/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { VacationRequestService } from './vacation.service';
import { VacationComponent } from './vacation.component';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        NgScrollbarModule
    ],
    exports: [
        VacationComponent
    ],
    declarations: [
        VacationComponent
    ],
    providers: [
        VacationRequestService
    ],
})
export class MyDrakeVacationModule { }
