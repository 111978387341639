import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MyDrakeSharedModule } from '../../shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DocumentsComponent } from './documents.component';
import { DocumentsService } from './documents.service';
import { HttpClientModule } from '@angular/common/http';
import { DocumentDetailLegacy } from './components/document-detail-legacy/document-detail-legacy.component';
import { DocumentDetail } from './components/document-detail/document-detail.component';
import { ValidityBadge } from './components/validity-badge/validity-badge.component';
import {NgScrollbarModule} from 'ngx-scrollbar';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        InfiniteScrollModule,
        NgScrollbarModule
    ],
    exports: [
        DocumentsComponent,
        DocumentDetailLegacy,
        DocumentDetail,
        ValidityBadge
    ],
    declarations: [
        DocumentsComponent,
        DocumentDetailLegacy,
        DocumentDetail,
        ValidityBadge
    ],
    providers: [
        DocumentsService
    ],
})
export class MyDrakeDocumentsModule {}
