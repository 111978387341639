
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

//RXJS
import { Observable } from 'rxjs';

//Services and Providers
import { environment } from "environments/environment";

//Domain
import { AppointmentType } from '@mydrake/domain/enums/core';
import { AddressView, LogisticNeedView, TrainingView, EmbarkView, DisembarkView, AppointmentView, LogisticView } from '@mydrake/domain/read/views/core';
import { LogisticNeedType } from '@mydrake/domain/enums/logistic';

@Injectable()
export class AppointmentService {

    constructor(private http: HttpClient) { }

    /**
     *
     * @param page
     * @param limit
     * @param textSearch
     * @param type
     */
    public getNextAppointments(page: number, limit: number, textSearch: string, type: string): Observable<Array<AppointmentView>> {

        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());
        if (textSearch) params = params.append('textSearch', textSearch);
        if (type) params = params.append('type', type);

        return this.http.get(environment.apiUrl + 'My/Appointment/NextAppointments', { params: params }).pipe(
            map(res => res['items'].map(data => this.map(data))));
    }

    /**
     *
     * @param page
     * @param limit
     * @param textSearch
     * @param type
     */
    public getLastAppointments(page: number, limit: number, textSearch: string, type: string): Observable<Array<AppointmentView>> {

        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());
        if (textSearch) params = params.append('textSearch', textSearch);
        if (type) params = params.append('type', type);

        return this.http.get(environment.apiUrl + 'My/Appointment/LastAppointments', { params: params }).pipe(
            map(res => res['items'].map(data => this.map(data))));
    }

    public getLogisticNeeds(appointmentId: string): Observable<LogisticNeedView[]> {

        let params = new HttpParams();
        params = params.append('appointmentId', appointmentId);

        return this.http.get(environment.apiUrl + 'My/Appointment/LogisticNeeds', { params: params })
            .pipe(map((res: any) => res.map(x => this.mapLogistic(x))));
    }

    /**
     *
     * @param appointment
     */
    private map(appointment: any): EmbarkView | DisembarkView | TrainingView | LogisticView | any {
        if (appointment.type === AppointmentType.Disembark || appointment.type === AppointmentType.Embark) {
            return this.mapSchedule(appointment);

        } else if (appointment.type === AppointmentType.Training) {
            return this.mapTraining(appointment);
        } else if (appointment.type === AppointmentType.Appointment) {
            return this.mapAppointment(appointment);
        } else if (appointment.type === AppointmentType.Logistic) {
            return this.mapLogisticType(appointment);
        } else {
            return appointment;
        }
    }

    /**
     *
     * @param source
     */
    private mapSchedule(source: any): EmbarkView | DisembarkView {

        const schedule = source.type == AppointmentType.Embark ? new EmbarkView() : new DisembarkView();

        schedule.id = source.id;
        schedule.code = source.code;
        schedule.start = source.start;
        schedule.end = source.end;
        schedule.organizationalUnit = source.uop;
        schedule.job = source.job;

        return schedule;
    }

    /**
     *
     * @param source
     */
    private mapTraining(source: any): TrainingView {

        const training = new TrainingView();

        training.id = source.id;
        training.code = source.code;
        training.start = source.start;
        training.end = source.end;
        training.course = source.course;
        training.description = source.description;
        training.local = source.local;

        return training;
    }
    private mapAppointment(source: any): AppointmentView {

        const appointment = new AppointmentView();

        appointment.id = source.id;
        appointment.code = source.code;
        appointment.start = source.start;
        appointment.end = source.end;
        appointment.description = source.description;
        appointment.title = source.title;
        appointment.local = source.local;

        return appointment;
    }

    /**
     *
     * @param source
     */
    private mapLogisticType(source: any): LogisticView {

        const logistic = new LogisticView();

        logistic.id = source.id;
        logistic.code = source.code;
        logistic.start = source.start;
        logistic.end = source.end;
        logistic.description = source.description;
        logistic.local = source.local;

        return logistic;
    }

    /**
     *
     * @param source
     */
    private mapLogistic(source: any): LogisticNeedView {

        const logistic = new LogisticNeedView();

        logistic.id = source.id;
        logistic.code = source.code;
        logistic.start = source.start;
        logistic.end = source.end;
        logistic.type = <LogisticNeedType>source.type;
        logistic.subType = source.subType;
        logistic.provider = source.provider;
        logistic.reservationNumber = source.reservationNumber;
        logistic.note = source.note;
        logistic.trackingLink = source.trackingLink;
        logistic.origin = source.origin ? this.mapAddress(source.origin) : null;
        logistic.destination = source.destination ? this.mapAddress(source.destination) : null;
        logistic.status = source.status;
        logistic.serviceStatus = source.serviceStatus;
        logistic.workerObservation = source.workerObservation;

        return logistic;
    }

    /**
     *
     * @param source
     */
    private mapAddress(source: any): AddressView {

        const address = new AddressView();

        address.id = source.id;
        address.iata = source.iata;
        address.name = source.name;
        address.streetAddress = source.streetAddress;
        address.number = source.number;
        address.addressComplement = source.addressComplement;
        address.district = source.district;
        address.city = source.city;
        address.state = source.state;
        address.zipCode = source.zipCode;
        address.country = source.country;
        address.latitude = source.latitude;
        address.longitude = source.longitude;

        return address;
    }
}
