import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'mydrake-inactive-mydrake-dialog',
  templateUrl: './inactive-mydrake.component.html',
  styleUrls: ['./inactive-mydrake.component.scss']
})
export class InactiveMyDrakeDialog {

    constructor(
        public dialogRef: MatDialogRef<InactiveMyDrakeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

      onNoClick(): void {
        this.dialogRef.close();
      }

    }
