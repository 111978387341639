import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
} from "@angular/core";
import {
    AbstractControl,
    ControlContainer,
    FormControl,
    NgForm,
} from "@angular/forms";
import { Observable, Subject } from "rxjs";
import {
    debounceTime,
    exhaustMap,
    filter,
    scan,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { takeWhileInclusive } from "rxjs-take-while-inclusive";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Option } from "@shared/models";
import { DefaultAvatarType } from "../k-avatar/k-avatar.component";
import {
    MatFormFieldAppearance,
} from "@angular/material/form-field";

@Component({
    selector: "k-autocomplete-paged",
    templateUrl: "k-autocomplete-paged.component.html",
    styleUrls: ["k-autocomplete-paged.component.scss"],
})
export class KAutocompletePagedComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() public loadIcons = false;
    @Input() public iconType: DefaultAvatarType = "person";
    @Input() public required = false;
    @Input() public readonly = false;
    @Input() public fixedLabel = false;
    @Input() public placeholder: string;
    @Input() public fetch: (
        filter: string,
        currentPage: number,
        take?: number
    ) => Observable<Option[]>;
    @Input() public disabledInvalidOption: boolean = false;
    @Input() public control?: AbstractControl | null = null;
    @Input() public fieldAppearance: MatFormFieldAppearance = "standard";
    @Input() public floatLabel: string = "auto";

    private _disabled = false;

    @Input() get disabled() {
        return this._disabled;
    }

    set disabled(val) {
        this._disabled = val;
        this._disabled ? this.control.disable() : this.control.enable(); 
    }

    @Input() get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        if(this.control) {
            this.control.setValue(this.value);
        }
        this.valueChange.emit(val);
    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() public invalidOptionSelected = new EventEmitter<boolean>();
    @Output() public typedText = new EventEmitter<any>();
    @Output() public cleared = new EventEmitter<any>();

    public filteredLookups$: Observable<Option[]>;
    public noResults = false;
    private _value: Option;

    private nextPage$ = new Subject();
    private onDestroy = new Subject();

    constructor() {}

    ngOnInit() {
        const validators = [
            (control: AbstractControl): { [key: string]: any } | null => {
                if (typeof control.value === "string" && control.value !== "") {
                    this.invalidOptionSelected.emit(true);
                    this.typedText.emit({
                        typedValue: control.value,
                        isValidOption: false,
                    });
                    return this.disabledInvalidOption
                        ? null
                        : {
                              invalidAutocompleteObject: {
                                  value: control.value,
                              },
                          };
                }
                this.invalidOptionSelected.emit(false);
                this.typedText.emit({
                    typedValue: control.value,
                    isValidOption: true,
                });
                return null; /* valid option selected */
            },
        ];

        if (this.control) {
            this.control.setValidators(validators);
            this.control.updateValueAndValidity();
        } else {
            this.control = new FormControl("", { validators });
        }
    }

    // Declarando componente como intocado para evitar que ao iniciar o componente já faça requests desnecessários na API
    // por conta de mudanças ouvidas durante a inicialização pelo formControl.valueChanges.
    private touched = false;

    ngAfterViewInit() {
        if (this.value) {
            this.control.setValue(this.value);
        } else {
            setTimeout(() => (this._value = this.control.value));
        }

        this.setupFilter();
    }

    private setupFilter() {
        const filter$ = this.control.valueChanges.pipe(
            startWith(""),
            debounceTime(200),
            // Note: If the option valye is bound to object, after selecting the option
            // Note: the value will change from string to {}. We want to perform search
            // Note: only when the type is string (no match)
            filter((q) => typeof q === "string")
        );
        // Note: There are 2 stream here: the search text changes stream and the nextPage$ (raised by directive at 80% scroll)
        // Note: On every search text change, we issue a backend request starting the first page
        // Note: While the backend is processing our request we ignore any other NextPage emitts (exhaustMap).
        // Note: If in this time the search text changes, we don't need those results anymore (switchMap)
        this.filteredLookups$ = filter$.pipe(
            switchMap((filter) => {
                // Note: Reset the page with every new seach text
                let currentPage = 0;
                this.noResults = false;
                return this.nextPage$.pipe(
                    startWith(currentPage),
                    // Note: Until the backend responds, ignore NextPage requests.
                    exhaustMap((_: any) => {
                        if (this.touched) {
                            // só faz o request se o input já tiver sido tocado
                            return this.fetch(filter, currentPage, 10);
                        } else {
                            return new Observable<Option[]>();
                        }
                    }),
                    tap(() => currentPage++),
                    // Note: This is a custom operator because we also need the last emitted value.
                    // Note: Stop if there are no more pages, or no results at all for the current search text.
                    // @ts-ignore
                    takeWhileInclusive((p: string | any[]) => p.length > 0),
                    scan((allProducts, newProducts) => {
                        let all = allProducts
                            .map((x) => x as any)
                            .concat(newProducts);
                        this.noResults = all.length === 0;
                        return all;
                    }, [])
                );
            })
        ) as Observable<Option[]>; // Note: We let asyncPipe subscribe.
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        this._value = event.option.value;
        this.valueChange.emit(event.option.value);
        this.noResults = false;
    }

    onClearOptionSelected(event: MouseEvent) {
        this.value = null;
        event.stopPropagation();
        this.cleared.emit();
    }

    displayWith(option: Option) {
        return option ? option.name : undefined;
    }

    onClear() {
        this.value = null;
        this.setupFilter();
        this.noResults = false;
        this.cleared.emit();
    }

    onScroll() {
        // Note: This is called multiple times after the scroll has reached the 80% threshold position.
        this.nextPage$.next();
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    handleFocus($event: any) {
        // Definimos touched ao focar o elemento.
        this.touched = true;

        if ($event.target.value === "") {
            // force fetch when first focus input and allowing null options
            this.control.setValue("");
        }
    }

    handleEmptyInput($event: any) {
        if ($event.target.value === "") {
            this.valueChange.emit();
        }
    }
}
