import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InboxListComponent } from './inbox/inbox-list/inbox-list.component';
import { ContactListComponent } from './contacts/contact-list/contact-list.component';
import { ProfileComponent } from './profile/profile.component';
import { AppointmentListComponent } from './appointments/appointment-list/appointment-list.component';
import { VacationComponent } from './vacation/vacation.component';
import { TrainingComponent } from './training/training.component';
import { AdditionalEventsComponent } from './additional-events/additional-events.component';
import { AdditionalEventsRouterGuardService } from './additional-events/additional-event.guard.service';
import { ReportComponent } from './reports/report.component';
import { QualificationNeedsComponent } from './qualification-needs/qualification-needs.component';
import { DocumentsComponent } from './documents/documents.component';
import { RequestCenterComponent } from './request-center/request-center.component';

export const CoreRouting: Routes = [
    { path: 'inbox', component: InboxListComponent },
    { path: 'contacts', component: ContactListComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'appointments', component: AppointmentListComponent },
    { path: 'vacations', component: VacationComponent },
    { path: 'training', component: TrainingComponent },
    { path: 'additional-events', component: AdditionalEventsComponent, canActivate: [AdditionalEventsRouterGuardService] },
    { path: 'reports', component: ReportComponent },
    { path: 'qualification-needs', component: QualificationNeedsComponent },
    { path: 'documents', component: DocumentsComponent },
    { path: 'reports', component: ReportComponent },
    { path: 'request-center', component: RequestCenterComponent }
];

/**
 * Core routing module
 */
@NgModule({
    imports: [
        RouterModule.forChild(CoreRouting)
    ],
    exports: [RouterModule]
})
export class CoreRoutingModule { }
