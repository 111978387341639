import { Pipe, PipeTransform } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'yesOrNo'
})
export class YesOrNoPipe implements PipeTransform {

    private mapping : any = {
        Yes : {
            pt: 'Sim',
            'pt-br': 'Sim',
            en: 'Yes',
            'en-us': 'Yes'
        },
        No : {
            pt: 'Não',
            'pt-br': 'Não',
            en: 'No',
            'en-us': 'No'
        }
    }

    constructor(private translate: TranslateService) {}

    transform(value: any): any {

        let currentLang = this.translate.currentLang;

        if(value === 'true' || value === true) {
            return this.mapping.Yes[currentLang];
        }
        else {
            return this.mapping.No[currentLang];
        }
    }
}
