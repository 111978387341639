import { Component } from '@angular/core';
import { TemplateComponent } from '../template';

@Component({
    selector: 'inbox-card-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class InboxCardDefaultComponent extends TemplateComponent {

    constructor() {
    super();
  }


}
