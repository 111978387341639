import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@mydrake/environments/environment';
import {SaveEssayAnswersQuestion} from './quiz.commands';
import {QuestionModel} from '@mydrake/domain/read/models/online-training';

@Injectable()
export class QuizApi {

    constructor(private http: HttpClient) { }

    public markAnswer(formId : string, questionId : string, optionId : string)  {
        return this.http.post(environment.apiUrl + "Integration/Quiz/MarkAnswer", { formId: formId, questionId: questionId, optionId: optionId });
    }

    public setEssayAnswer(formId: string, question)  {
        const cmd = new SaveEssayAnswersQuestion(formId, [question]);
        return this.http.post(environment.apiUrl + 'Integration/Quiz/SetEssayAnswers', cmd);
    }

    public setEssayAnswers(formId: string, questions: QuestionModel[])  {
        const cmd = new SaveEssayAnswersQuestion(formId, questions);
        return this.http.post(environment.apiUrl + 'Integration/Quiz/SetEssayAnswers', cmd);
    }
}
