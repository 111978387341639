<div class="mydrake-register-successfully-dialog">

    <i class="material-icons">check_circle</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.LABELS.SUCCESSFULLY' | translate }}</h4>

        <p class="description">{{ message | translate }}</p>

        <button mat-raised-button class="-regular" (click)="onComplete()">{{ 'REGISTRATION.LABELS.CLOSE' | translate }}</button>

    </div>

</div>
