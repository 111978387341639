<mat-sidenav-container style="overflow: hidden">

    <!-- BREADCRUMB -->
    <k-breadcrumb label="{{ 'MENU.CONTACTS' | translate }}">
        <button mat-icon-button (click)="onListMode()" *ngIf="gridMode == true"><i class="material-icons" style="color: #757575">view_stream</i></button>
        <button mat-icon-button (click)="onGridMode()" *ngIf="gridMode == false"><i class="material-icons" style="color: #757575">view_module</i></button>
    </k-breadcrumb>
    <!--/ BREADCRUMB -->

    <ng-scrollbar #scrollable track="all" [autoHeightDisabled]="false">

        <div autoresize [fix]="135" *ngIf="contacts.length > 0">

            <!-- CONTACT GRID -->

            <div id="contact-grid" style="padding: 0 25px" *ngIf="gridMode == true && contacts.length > 0">

                <div fxLayout="row" fxLayoutWrap>
                    <div *ngFor="let contact of contacts" [fxFlex]="opened ? 25 : 20" fxFlex.xs="100" [fxFlex.sm]="opened ? 100 : 50" [fxFlex.md]="opened ? 50 : 25" style="padding: 10px">
                        <mat-card style="height: 100%;" (click)="selectContact(contact)" class="contact-card">
                            <mat-card-content fxLayout="column" fxLayoutAlign="space-between center" style="height: 100%;">
                                <k-avatar [avatarId]="contact.avatarId" [name]="contact.name" type="circle" size="80px" fontsize="42px"></k-avatar>
                                <div style="text-align: center; text-transform: uppercase; font-weight: 600; margin: 15px 0px 3px 0px;">{{contact.name}}</div>
                                <div style="text-align: center; font-style: italic; font-size: 12px; color: #757575;">{{contact.job}}</div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

            </div>

            <!--/ CONTACT GRID -->

            <!-- CONTACT LIST -->

            <div id="contact-list" style="padding: 0 35px 5px 35px;" *ngIf="gridMode == false && contacts.length > 0">
                <mat-accordion>
                    <mat-expansion-panel  hideToggle="true" *ngFor="let contact of contacts">
                        <mat-expansion-panel-header style="height: 60px !important">
                            <mat-panel-title>
                                <div fxLayout="row" fxLayoutAlign="start center" style="    margin: 5px;">
                                    <k-avatar [avatarId]="contact.avatarId" [name]="contact.name" type="circle" size="28px" fontsize="13px"></k-avatar>
                                    <div>
                                        <div style="text-transform: uppercase; font-size: 12px; font-weight: 600; margin-left: 10px;">{{contact.name}}</div>
                                        <div style="font-style: italic; font-size: 12px; color: #757575; margin-top: 4px" *ngIf="contact.job">{{contact.job}}</div>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div style="border-top: 1px solid #e3e3e3;" fxLayout="row">
                            <ul style="list-style: none; margin: 0; padding: 0px 8px 5px 8px;">
                                <li *ngFor="let shareable of contact.contacts" style="    margin-top: 18px;">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <i style="font-size: 25px; margin-right: 15px; color: #0747a6;" [ngClass]="{
                                        'icon-mydrake-basic-mail-open-text': shareable.type=='Email',
                                        'icon-mydrake-telephone': shareable.type=='Phone',
                                        'icon-mydrake-map-location': shareable.type=='Local'
                                    }"></i>
                                        <div style="font-size: 14px; font-weight: 500;">{{shareable.value}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
            <!--/ CONTACT LIST -->
        </div>
    </ng-scrollbar>

    <div style="padding: 0px 15px; height: calc(100vh - 125px);" fxLayout="column" fxLayoutAlign="center center" *ngIf="loaded && contacts.length === 0">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -60px">
            <i class="icon-mydrake-contacts" style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
            <div style="padding: 20px 0 5px 0;
                font-weight: 400;
                font-size: 23px;
                color: #757575;">Nenhum contato encontrado!</div>
            <div style="font-style: italic;
                font-size: 14px; color: #757575;">Utilize os filtros de pesquisa para melhorar sua consulta.</div>
        </div>
    </div>

    <mat-sidenav mode="side" opened="false" #infoSideNav style="width: 380px; border-left: 1px solid #eee; background: white" position="end">
        <mydrake-contact-info [contact]="selected" (back)="onClose()"></mydrake-contact-info>
    </mat-sidenav>

</mat-sidenav-container>
