import { LogisticNeedType } from '@mydrake/domain/enums/logistic';
import { AddressView } from '@mydrake/domain/read/views/core';

export class LogisticNeedView {

    public id: string;
    public code: number;
    public start: Date;
    public end: Date;
    public type: LogisticNeedType;
    public subType: string;
    public provider: string;
    public status: string;
    public serviceStatus: string;
    public reservationNumber: string;
    public note: string;
    public trackingLink: string;
    public workerId: string;
    public appointmentId: string;
    public origin: AddressView;
    public destination: AddressView;
    public workerObservation: string;
}
