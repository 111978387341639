import { Component, Input, OnInit } from "@angular/core";
import { IWorkerDocument } from "@mydrake/domain/read/models/documents";
import { DocumentsService } from "../../documents.service";

@Component({
    selector: 'validity-badge',
    templateUrl: './validity-badge.component.html',
    styleUrls: ['./validity-badge.component.scss']
})
export class ValidityBadge {

    @Input() public validDays: number;

    constructor(){}

    ngOnInit(): void {}

    public isPositive(validDays: number): boolean {
        return validDays >= 0;
    }

    public getModule(validDays: number): number {
        return Math.sqrt(Math.pow(validDays, 2));
    }
}