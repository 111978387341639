import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";

import { MyDrakeSharedModule } from "../shared/shared.module";
import {TranslateModule} from '@ngx-translate/core';

import { RegisterComponent } from "./register/register.component";
import { RegisterService } from "./register/register.service";
import { EmailNotFoundDialog } from "./register/email-not-found/email-not-found.component";
import { UserAlreadyExistsDialog } from "./register/user-already-exists/user-already-exists.component";
import { ThereIsMoreThanOneWorkerByEmailDialog } from "./register/there-is-more-than-one-worker-by-email/there-is-more-than-one-worker-by-email.component";
import { UnknownErrorDialog } from "./register/unknown-error/unknown-error.component";
import { InactiveMyDrakeDialog } from './register/inactive-mydrake/inactive-mydrake.component';
import { RegisterSuccessfullyDialog } from './register/successfully/successfully.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        MyDrakeSharedModule
    ],

    declarations: [
        RegisterComponent,
        RegisterSuccessfullyDialog,
        EmailNotFoundDialog,
        UserAlreadyExistsDialog,
        InactiveMyDrakeDialog,
        UnknownErrorDialog,
        ThereIsMoreThanOneWorkerByEmailDialog
    ],

    providers: [
        RegisterService
    ],

    entryComponents: [
        EmailNotFoundDialog,
        UserAlreadyExistsDialog,
        ThereIsMoreThanOneWorkerByEmailDialog,
        InactiveMyDrakeDialog,
        UnknownErrorDialog,
        RegisterSuccessfullyDialog
    ],

    exports: [
        RegisterComponent
    ]
})
export class MyDrakeRegistrationModule { }
