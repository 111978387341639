import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MyDrakeSharedModule } from '../../shared/shared.module';
import { QualificationNeedsComponent } from './qualification-needs.component';
import { QualificationNeedsService } from './qualification-needs.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {NgScrollbarModule} from 'ngx-scrollbar';


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        InfiniteScrollModule,
        NgScrollbarModule
    ],
    exports: [
        QualificationNeedsComponent
    ],
    declarations: [
        QualificationNeedsComponent
    ],
    providers: [
        QualificationNeedsService
    ],
})
export class MyDrakeQualificationNeedsModule { }
