<div class="box">

    <div *ngIf="context.currentStateIsInitial && context.domainEntitySnapshot.status !== 'Expired'">
        <mydrake-online-training-starting [context]="context" [domainId]="domainId" [inboxId]="inboxId"></mydrake-online-training-starting>
    </div>

    <div *ngIf="!context.currentStateIsInitial">

        <div *ngIf="context.domainEntitySnapshot.currentStep && context.domainEntitySnapshot.currentStep.type === 'Resource'">
            <mydrake-online-training-resource-step [context]="context" [domainId]="domainId" [inboxId]="inboxId"></mydrake-online-training-resource-step>
        </div>

        <div *ngIf="context.domainEntitySnapshot.currentStep && context.domainEntitySnapshot.currentStep.type === 'Activity'">
            <mydrake-online-training-activity-step [context]="context" [domainId]="domainId" [inboxId]="inboxId"></mydrake-online-training-activity-step>
        </div>

    </div>

    <div *ngIf="context.relatedFlowIsComplete || context.relatedFlowIsFinalized">

        <div *ngIf="context.domainEntitySnapshot.status !== 'Canceled' && context.domainEntitySnapshot.status !== 'Expired'">
            <mydrake-online-training-complete [domainEntity]="context.domainEntitySnapshot"></mydrake-online-training-complete>
        </div>

        <div *ngIf="context.domainEntitySnapshot.status === 'Canceled'">
            <mydrake-online-training-canceled [domainEntity]="context.domainEntitySnapshot"></mydrake-online-training-canceled>
        </div>

        <div *ngIf="context.domainEntitySnapshot.status === 'Expired'">
            <mydrake-online-training-expired [domainEntity]="context.domainEntitySnapshot"></mydrake-online-training-expired>
        </div>

    </div>

</div>
