import { VacationOption } from "@mydrake/domain/write/value-objects/vacation-planner";

export class VacationRequestCommand {
    public requestId: string;
    public receiveBonusEarly: boolean;
    public transition : string;
    public howManyDaysToSell : number;
    public howManyPiecesToPartition: number;
    public options: Array<VacationOption> = [];
}
