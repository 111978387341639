import { Component, OnInit } from '@angular/core';
import { TemplateComponent } from '../template';

@Component({
    selector: 'app-online-training',
    templateUrl: './online-training.component.html',
    styleUrls: ['./online-training.component.scss']
})
export class OnlineTrainingComponent extends TemplateComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {
    }

}
