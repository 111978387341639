
import {map} from 'rxjs/operators';
//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//RxJS
import { Observable } from "rxjs";

//Service and Providers
import { environment } from 'environments/environment';

//Domain and Commands
import { VacationPlannerRule } from '@mydrake/domain/read/models/vacation-planner';
import { VacationRequestCommand, ChangeVacationRequestRuleCommand } from '@mydrake/domain/write/commands/vacation-planner';


@Injectable()
export class VacationApi {

    constructor(private _http: HttpClient) { }

    public getRulesByCategory(categoryId: string) : Observable<Array<VacationPlannerRule>>  {
        return this._http.get(environment.apiUrl + "My/VacationPlanning/RulesByCategory", { params: { categoryId: categoryId }})
        .pipe(map(data => data as Array<VacationPlannerRule>));
    }

    public changeVacationRequestRule(vacationRequestId: string, ruleId: string) : void  {

        let cmd = new ChangeVacationRequestRuleCommand();
        cmd.requestId = vacationRequestId;
        cmd.ruleId = ruleId;

        this._http.put(environment.apiUrl + "My/VacationPlanning/ChangeVacationRequestRule", cmd).subscribe();
    }

    public requestVacation(command : VacationRequestCommand)  {
        return this._http.post(environment.apiUrl + "Integration/Inbox/RequestVacation", command);
    }

}
