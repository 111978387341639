import { Component, ViewChild, Input, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, OnChanges, Output, EventEmitter } from "@angular/core";
import { AppointmentTemplateComponent } from "./template";
import { AppointemtMapping } from "./mapping";
import {AppointmentService} from '../../appointment-list/appointment.service';

@Component({
    selector: 'mydrake-appointment-template',
    template: '<div class="appointment-template-type" style="height: 100%"><div #templatecontainer></div></div>'
})
export class AppointmentTemplateFactoryComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('templatecontainer', { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    @Input()
    public type: string;

    @Input()
    public context: any;

    @Output()
    public close = new EventEmitter();

    public onClose(){
        this.close.emit();
    }

    private componentRef: ComponentRef<{}>;

    constructor(private factoryResolver: ComponentFactoryResolver, private appointmentService: AppointmentService) { }

    ngOnInit() {

    }

    ngOnChanges(...args: any[]) {

        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }

        if (this.type) {
            let componentType = AppointemtMapping.getContent(this.type);
            let factory = this.factoryResolver.resolveComponentFactory(componentType);
            this.componentRef = this.container.createComponent(factory);
            let instance = <AppointmentTemplateComponent>this.componentRef.instance;
            instance.context = this.context;

            this.appointmentService.getLogisticNeeds(this.context.id).subscribe((data) => {
               instance.context.logisticNeeds = data;
            });
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
