export enum TelephoneType {
    Home = "Home",
    Work = "Work",
    Mobile = "Mobile",
    Partner = "Partner",
    Parent = "Parent",
    Neighbor = "Neighbor",
    Message = "Message",
    Emergency = "Emergency"
}
