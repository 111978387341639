<section>

    <div *ngIf="request.status === 'Draft'">

        <!--/ ADIANTAMENTO DO 13 SALARIO -->
        <form #bonus="ngForm" *ngIf="tabs.bonus.visible && tabs.bonus.index == index" novalidate>
            <section class="k-wizard">

                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-salary"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{index}}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.RECEIVE_EARLY_BONUS' | translate }}</div>
                                    <div class="k-wizard-step-header-description">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.RECEIVE_EARLY_BONUS_DESCRIPTION' | translate }}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <mat-radio-group name="receiveBonusEarly" [(ngModel)]="request.receiveBonusEarly">
                                    <mat-radio-button [value]="true">{{'COMMON.YES' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="false">{{'COMMON.NO' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()"
                            *ngIf="index !== 1">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-next" (click)="onNext()">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ ADIANTAMENTO DO 13 SALARIO -->

        <!-- VENDA DE FÉRIAS-->
        <form #sell="ngForm" *ngIf="tabs.sell.visible && tabs.sell.index == index" novalidate>
            <section class="k-wizard">
                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-price-tag"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{index}}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.SELL' | translate }}</div>
                                    <div class="k-wizard-step-header-description">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.SELL_DESCRIPTION' | translate: {days: rule.maxDaysToSell } }}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <mat-radio-group name="wantToSellVacationDays"
                                    [(ngModel)]="request.wantToSellVacationDays">
                                    <mat-radio-button [value]="true" (change)="defaultWantToSellVacationDays()">
                                        {{'COMMON.YES' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="false" (change)="clearWantToSellVacationDays()">
                                        {{'COMMON.NO' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="request.wantToSellVacationDays" style="margin-top: 40px">
                                <div style="margin-bottom: 8px; color: black;     font-weight: 500;">
                                    {{ 'INBOX.VACATION_REQUEST.COMMON.NUMBER_OF_DAYS_TO_SELL' | translate }}
                                </div>
                                <mat-select style="width: 240px;" name="daysToSell"
                                    [(ngModel)]="request.howManyDaysToSell">
                                    <mat-option *ngFor="let option of daysToSell" [value]="option">
                                        {{ option }} {{ 'COMMON.DAYS' | translate }}
                                    </mat-option>
                                </mat-select>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()"
                            *ngIf="index !== 1">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-next" (click)="onNext()">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ VENDA DE FÉRIAS-->

        <!--/ DIVIDIR FÉRIAS -->
        <form #split="ngForm" *ngIf="tabs.split.visible && tabs.split.index == index" novalidate>
            <section class="k-wizard">

                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-split"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{index}}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.SPLIT' | translate }}</div>
                                    <div class="k-wizard-step-header-description">{{ 'INBOX.VACATION_REQUEST.SPLIT_DESCRIPTION' | translate: { n: rule.maxVacationDivision }
                                        }}
                                    </div>
                                </div>
                            </div>

                            <div>
                                <mat-radio-group name="wantToPartitionVacation"
                                    [(ngModel)]="request.wantToPartitionVacation">
                                    <mat-radio-button [value]="true" (change)="enableSplit()">
                                        {{'COMMON.YES' | translate }}</mat-radio-button>
                                    <mat-radio-button [value]="false" (change)="clearPeriods()">
                                        {{'COMMON.NO' | translate }}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div *ngIf="request.wantToPartitionVacation" style="margin-top: 40px">
                                <div style="margin-bottom: 8px; color: black; font-weight: 500;">
                                    {{ 'INBOX.VACATION_REQUEST.NUMBER_OF_SLICES' | translate }}
                                </div>
                                <mat-select style="width: 240px;" name="limitOfSplits"
                                    [(ngModel)]="request.howManyPiecesToPartition">
                                    <mat-option *ngFor="let option of limitOfSplits" [value]="option"
                                        (click)="createPeriods(option)">
                                        {{ option }} {{ 'COMMON.SLICES' | translate }}
                                    </mat-option>
                                </mat-select>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()" *ngIf="index !== 1">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-next" (click)="onNext()">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ DIVIDIR FÉRIAS-->

        <!-- PRIORIZAÇÂO DE DATAS -->
        <form #periodPriorization="ngForm"
            *ngIf="tabs.periodPriorization.visible && tabs.periodPriorization.index == index">
            <section class="k-wizard">

                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-calendar"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{ index }}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.CHOOSE_THE_VACATION_PERIOD' | translate }}
                                    </div>
                                    <div class="k-wizard-step-header-description">
                                        {{ 'INBOX.VACATION_REQUEST.CHOOSE_THE_VACATION_PERIOD_DESCRIPTION' | translate }}
                                    </div>
                                </div>
                            </div>

                            <ul class="ordered-list" dragula="periodsPriorization"
                                [(dragulaModel)]="suggestion.suggestedPeriods">
                                <li *ngFor="let period of suggestion.suggestedPeriods; let i = index"
                                    [attr.data-index]="i">
                                    <div class="position" [innerHtml]="i + 1"></div>
                                    <div class="value">
                                        <span>{{period.start | i18nDate:'date'}}</span>
                                        <span>{{ 'COMMON.TO' | translate }}</span>
                                        <span>{{period.end | i18nDate:'date'}}</span>
                                    </div>
                                    <i class="icon-mydrake-move"></i>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()" *ngIf="index !== 1">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-next" (click)="onNext()">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ PRIORIZAÇÂO DE DATAS -->

        <!-- SELEÇÃO DE DATAS -->
        <form #periodSelection="ngForm" *ngIf="tabs.periodSelection.visible && tabs.periodSelection.index == index"
            (ngSubmit)="onNext()" nonvalidade>
            <section class="k-wizard">

                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-calendar"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{ index }}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.INFORM_THE_VACATION_PERIOD' | translate }}
                                    </div>
                                    <div class="k-wizard-step-header-description">
                                        {{ 'INBOX.VACATION_REQUEST.INFORM_THE_VACATION_PERIOD_DESCRIPTION' | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="k-wizard-question -separated" *ngIf="tabs.sell.visible">
                                <span
                                    class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.CONCESSIVE_PERIOD' | translate }}</span>
                                <span
                                    class="answer">{{ request.acquisitivePeriod.concessionStart | i18nDate:'date' }}</span>
                                <span class="answer">{{ 'COMMON.TO' | translate }}</span>
                                <span
                                    class="answer">{{ request.acquisitivePeriod.concessionEnd | i18nDate:'date' }}</span>
                            </div>

                            <div *ngFor="let period of suggestion.informedPeriods; let i = index"
                                style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">

                                <!--START -->
                                <div
                                    style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
                                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi="true" #start></mat-datepicker>
                                    <input type="text" readonly [matDatepicker]="start" required [min]="today"
                                        [max]="period.end" name="start-part-{{i}}" [(ngModel)]="period.start"
                                        style="width: 115px;">
                                </div>
                                <!--START -->

                                <!--END -->
                                <div style="margin-left: 15px;">{{ 'COMMON.TO' | translate }}</div>
                                <div
                                    style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
                                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi="true" #end></mat-datepicker>
                                    <input type="text" readonly [matDatepicker]="end" [min]="period.start" required
                                        name="end-part-{{i}}" [(ngModel)]="period.end" style="width: 115px;">
                                </div>
                                <!--END -->

                            </div>

                        </div>

                    </div>

                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()" *ngIf="index !== 1">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-next" type="submit" [disabled]="periodSelection.invalid"
                            [ngClass]="{'-disabled':periodSelection.invalid}">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ SELEÇÃO DE DATAS -->

        <!-- CONFIRMACAO DOS DADOS -->
        <form *ngIf="tabs.confirmation.visible && tabs.confirmation.index == index">
            <section class="k-wizard">

                <div class="k-wizard-content">
                    <div class="k-wizard-aside">
                        <div class="k-wizard-icon">
                            <i class="icon-mydrake-basic-elaboration-todolist-check"></i>
                        </div>
                    </div>
                    <div class="k-wizard-content-form">

                        <div class="k-wizard-step">
                            <div class="k-wizard-step-header">
                                <div class="k-wizard-step-header-bullet">{{ index }}</div>
                                <div>
                                    <div class="k-wizard-step-header-title">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.CONFIRMATION' | translate }}</div>
                                    <div class="k-wizard-step-header-description">
                                        {{ 'INBOX.VACATION_REQUEST.COMMON.CONFIRMATION_DESCRIPTION' | translate }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="k-wizard-question -separated" *ngIf="rule.maxSuggestionPeriodsToChoose && rule.maxSuggestionPeriodsToChoose > 1">
                                    <span class="question">{{ 'INBOX.VACATION_REQUEST.PERIODS_TO_INFORM' | translate }}</span>
                                    <span class="answer" style="margin-left: 0;">{{ rule.maxSuggestionPeriodsToChoose }} {{ 'INBOX.VACATION_REQUEST.COMMON.PERIODS' | translate }}</span>
                                </div>

                                <div class="k-wizard-question -separated" *ngIf="tabs.sell.visible">
                                    <span class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.SELL' | translate }}</span>
                                    <span class="answer">{{ request.howManyDaysToSell > 0 | yesOrNo }}</span>
                                    <span class="answer" style="margin-left: 0;" *ngIf="request.howManyDaysToSell > 0">,
                                        {{ request.howManyDaysToSell }} {{ 'COMMON.DAYS' | translate }}</span>
                                </div>

                                <div class="k-wizard-question -separated" *ngIf="tabs.split.visible">
                                    <span class="question">{{ 'INBOX.VACATION_REQUEST.SPLIT' | translate }}</span>
                                    <span class="answer">{{ request.wantToPartitionVacation | yesOrNo }}</span>
                                    <span class="answer" style="margin-left: 0;"
                                        *ngIf="request.howManyPiecesToPartition > 1">,
                                        {{ request.howManyPiecesToPartition }} {{ 'COMMON.SLICES' | translate }}</span>
                                </div>

                                <div class="k-wizard-question -separated" *ngIf="tabs.bonus.visible">
                                    <span
                                        class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.RECEIVE_EARLY_BONUS' | translate }}</span>
                                    <span class="answer">{{ request.receiveBonusEarly | yesOrNo }}</span>
                                </div>

                                <div *ngIf="request.choosedRuleConfiguration.method === 'Suggested' || request.choosedRuleConfiguration.method === 'Both'">
                                    <div class="k-wizard-question">
                                        <span
                                            class="question">{{ 'INBOX.VACATION_REQUEST.PREORIZED_PERIODS' | translate }}:</span>
                                    </div>
                                    <div>
                                        <ul class="confirmation">
                                            <li *ngFor="let period of suggestion.suggestedPeriods | orderBy:'priority'">
                                                &nbsp;
                                                <div class="option">{{period.priority}}&nbsp;-&nbsp;</div>
                                                <div class="question">
                                                    <span>&nbsp;&nbsp;{{ period.start | i18nDate:'date' }}</span>
                                                    <span>&nbsp;{{ 'COMMON.TO' | translate }}&nbsp;</span>
                                                    <span>{{ period.end | i18nDate:'date' }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div *ngIf="request.choosedRuleConfiguration.method === 'Informed' || request.choosedRuleConfiguration.method === 'Both'">
                                    <div class="k-wizard-question">
                                        <span
                                            class="question">{{ 'INBOX.VACATION_REQUEST.INFORMED_PERIODS' | translate }}:</span>
                                    </div>
                                    <div>
                                        <ul class="confirmation">
                                            <li *ngFor="let period of suggestion.informedPeriods">
                                                <div class="question">
                                                    <span>&nbsp;{{ period.start | i18nDate:'date' }}</span>
                                                    <span>&nbsp;{{ 'COMMON.TO' | translate }}&nbsp;</span>
                                                    <span>{{ period.end | i18nDate:'date'}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="k-wizard-footer">
                    <div class="k-wizard-aside">
                        <k-wizard-index [size]="steps" [index]="index"></k-wizard-index>
                    </div>
                    <div class="k-wizard-navigation">
                        <button class="-previous" (click)="onPrevious()">
                            <i class="icon-mydrake-up-arrow"></i>
                        </button>
                        <div fxFlex></div>
                        <button class="-send" (click)="onSend()" *ngIf="transition">{{ transition.name }}</button>
                    </div>
                </div>

            </section>
        </form>
        <!--/ CONFIRMACAO DOS DADOS -->
    </div>

    <!-- APRESENTAÇÂO DOS DADOS EM CASO DE SOLICITAÇÂO ENVIADA -->
    <div *ngIf="request.status === 'Requested'">
        <section class="k-wizard">

            <div class="k-wizard-content">
                <div class="k-wizard-aside">
                    <div class="k-wizard-icon">
                        <i class="icon-mydrake-basic-elaboration-calendar-check"></i>
                    </div>
                </div>
                <div class="k-wizard-content-form">

                    <div class="k-wizard-step">

                        <div class="k-wizard-step-header">
                            <div>
                                <div class="k-wizard-step-header-title">
                                    {{ 'INBOX.VACATION_REQUEST.COMMON.VACATION_REQUEST_INFORMATION' | translate }}</div>
                            </div>
                        </div>

                        <div>
                            <div class="k-wizard-question -separated">
                                <span
                                    class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.ACQUISITIVE_PERIOD' | translate }}</span>
                                <span class="answer">{{ request.acquisitivePeriod.start | i18nDate:'date' }}</span>
                                <span class="answer">{{ 'COMMON.TO' | translate }}</span>
                                <span class="answer">{{ request.acquisitivePeriod.end | i18nDate:'date' }}</span>
                            </div>

                            <div class="k-wizard-question -separated" *ngIf="rule.maxSuggestionPeriodsToChoose && rule.maxSuggestionPeriodsToChoose > 1">
                                    <span class="question">{{ 'INBOX.VACATION_REQUEST.PERIODS_TO_INFORM' | translate }}</span>
                                    <span class="answer" style="margin-left: 0;">{{ rule.maxSuggestionPeriodsToChoose }} {{ 'INBOX.VACATION_REQUEST.COMMON.PERIODS' | translate }}</span>
                                </div>

                            <div class="k-wizard-question -separated" *ngIf="tabs.sell.visible">
                                <span class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.SELL' | translate }}</span>
                                <span class="answer">{{ request.howManyDaysToSell > 0 | yesOrNo }}</span>
                                <span class="answer" style="margin-left: 0;" *ngIf="request.howManyDaysToSell > 0">,
                                    {{ request.howManyDaysToSell }} {{ 'COMMON.DAYS' | translate }}</span>
                            </div>

                            <div class="k-wizard-question -separated" *ngIf="tabs.split.visible">
                                <span class="question">{{ 'INBOX.VACATION_REQUEST.SPLIT' | translate }}</span>
                                <span class="answer">{{ request.howManyPiecesToPartition > 0 | yesOrNo }}</span>
                                <span class="answer" style="margin-left: 0;"
                                    *ngIf="request.howManyPiecesToPartition > 1">,
                                    {{ request.howManyPiecesToPartition }} {{ 'COMMON.SLICES' | translate }}</span>
                            </div>

                            <div class="k-wizard-question -separated" *ngIf="tabs.bonus.visible">
                                <span
                                    class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.RECEIVE_EARLY_BONUS' | translate }}</span>
                                <span class="answer">{{ request.receiveBonusEarly | yesOrNo }}</span>
                            </div>

                            <div fxLayout="row" fxLayout="column" fxLayoutAlign="start start">
                                <div class="k-wizard-question" style="margin-bottom: 5px">
                                    <span class="question">{{ 'VACATION.SCHEDULED_VACATION' | translate }}:</span>
                                </div>
                                <ul class="confirmation">
                                    <li *ngFor="let vacation of request.acquisitivePeriod.vacations;">
                                        <div class="question">
                                            <span>&nbsp;{{ vacation.start | i18nDate: 'date' }}</span>
                                            <span>&nbsp;{{ 'COMMON.TO' | translate }}&nbsp;</span>
                                            <span>{{ vacation.end | i18nDate:'date' }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="suggestion.suggestedPeriods.length > 0">
                                <div class="k-wizard-question" style="margin-bottom: 5px !important">
                                    <span
                                        class="question">{{ 'INBOX.VACATION_REQUEST.PRIORIZED_PERIODS' | translate }}:</span>
                                </div>
                                <ul class="confirmation">
                                    <li *ngFor="let period of suggestion.suggestedPeriods">
                                        &nbsp;
                                        <div class="option">{{period.priority}}&nbsp;-&nbsp;</div>
                                        <div class="question">
                                            <span>&nbsp;{{ period.start | i18nDate:'date' }}</span>
                                            <span>&nbsp;{{ 'COMMON.TO' | translate }}&nbsp;</span>
                                            <span>{{ period.end | i18nDate:'date' }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="suggestion.informedPeriods.length > 0">
                                <div class="k-wizard-question" style="margin-bottom: 5px">
                                    <span class="question">{{ 'INBOX.VACATION_REQUEST.INFORMED_PERIODS' | translate }}:</span>
                                </div>
                                <div>
                                    <ul class="confirmation">
                                        <li *ngFor="let period of suggestion.informedPeriods">
                                            <div class="question">
                                                <span>&nbsp;{{period.start | i18nDate:'date'}}</span>
                                                <span>&nbsp;{{ 'COMMON.TO' | translate }}&nbsp;</span>
                                                <span>{{period.end | i18nDate:'date'}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="k-wizard-footer">
                <div class="k-wizard-aside"></div>
                <div class="k-wizard-navigation">
                    <div fxFlex></div>
                    <button class="-send" (click)="onFireTransition()" *ngIf="transition">{{ transition.name}}</button>
                </div>
            </div>

        </section>

    </div>

    <!--/ APRESENTAÇÂO DOS DADOS EM CASO DE SOLICITAÇÂO ENVIADA -->

</section>