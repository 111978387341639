import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { IdText } from '@mydrake/domain/write/value-objects/id-text';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, startWith, tap } from 'rxjs/operators';
import { FieldRenderTemplate } from '../field-render-template';

import { environment } from 'environments/environment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'k-domain-field',
    templateUrl: './k-domain-field.component.html',
    styleUrls: ['./k-domain-field.component.scss']
})

export class KDomainFieldComponent extends FieldRenderTemplate implements OnInit {

    private validValues : IdText[] = [];

    public domainControl = new FormControl(null, [
        this.optionsValidator()
      ]);

    public filteredOptions: Observable<Array<IdText>>;

    constructor(private http: HttpClient) {
        super();
    }

    ngOnInit () {

        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.doValidation();
        });

        this.filteredOptions = this.domainControl.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            distinctUntilChanged(),
            filter((term: string) => term.length === 0 || term.length >= 2),
            tap(() => this.doValidation()),
            switchMap((term: string) => this.getDomainValues(term))
          );
    }

    public displayFn(option: IdText): string {
        return option && option.text ? option.text : '';
    }

    private setValidValues(values: IdText[]) : void {
        this.validValues = values;
    }

    public onSelect(evt: MatAutocompleteSelectedEvent): void {
        this.prop.value = evt.option.value.id;
        this.doValidation();
    }

    public onClosed(): void {

        if (!this.domainControl.value) {
            this.prop.value = '';
        }

        this.doValidation();
    }

    private doValidation(): void {
        this.prop.isValid = this.validation();
    }

    private getDomainValues(searchText: string): Observable<Array<IdText>> {
        return this.http.get<Array<IdText>>(environment.apiUrl + `Flow/GetDomainValues`, { params: { type: this.prop.type.key, searchText: searchText } }).pipe(tap(values => this.setValidValues(values)));
    }

    private validation(): boolean {
        return this.domainControl.valid;
    }

    private optionsValidator(): ValidatorFn {

        let self = this;

        return (control: AbstractControl): { [key: string]: any } | null => {


            let value = control.value;

            if (control.value && control.value.text) {
                value = control.value.text;
            }

            if (value) {

                const index = self.validValues.findIndex(option => {
                    return new RegExp("^" + option.text + "$").test(value);
                });

                return index < 0 ? { forbiddenNames: { value: value } } : null;
            }

            return null;
        };
      }
}
