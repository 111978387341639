<div class="mydrake-there-is-more-than-one-worker-by-email-dialog">

    <i class="material-icons">cancel</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.EXCEPTIONS.THERE_IS_MORE_THAN_ONE_WORKER_BY_EMAIL.TITLE' | translate }}</h4>

        <p class="description">{{ 'REGISTRATION.EXCEPTIONS.THERE_IS_MORE_THAN_ONE_WORKER_BY_EMAIL.DESCRIPTION' | translate }}</p>

    </div>

</div>
