import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MomentInput } from "moment";

@Component({
    selector: 'k-date-viewer',
    templateUrl: 'k-date-viewer.html',
    styleUrls: ['k-date-viewer.scss']
})
export class KDateViewer implements OnInit {

    @Input() date: MomentInput;
    @Input() showTime: boolean = false;

    public day: string;
    public month: string;
    public year: string;
    public time: string;

    ngOnInit() {

        const date = moment(this.date);
        let value = date.toDate().getDate().toString();

        if (value.length <= 1) {
            value = '0' + value;
        }

        this.day = value;
        this.month = date.format("MMM");
        this.year = date.toDate().getFullYear().toString();
        this.time = date.format("HH:mm");
    }
}
