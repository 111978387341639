<div class="box">
    <i class="icon-mydrake-date-schedule"></i>
    <section>
        <div class="uop">{{ context.title }}</div>
        <div class="job">{{ context.local }}</div>
    </section>
    <section>
        <div class="title">{{ 'TYPES.APPOINTMENT.END' | translate}}</div>
        <div class="description">{{ context.end | i18nDate}}</div>
    </section>
    <section *ngIf="context.description">
            <div class="title">{{ 'TYPES.APPOINTMENT.DESCRIPTION' | translate}}</div>
            <div class="description">{{ context.description }}</div>
    </section>
</div>
