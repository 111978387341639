import { Injectable } from '@angular/core';
import { UnknownCardTemplateComponent } from "../unknown/unknown.card";
import { EmbarkCardAppointmentComponent } from "../embark/card/embark.component";
import { UnknownContentTemplateComponent } from "../unknown/unknown.content";
import { DisembarkCardAppointmentComponent } from "../disembark/card/disembark.component";
import { TrainingCardAppointmentComponent } from "../training/card/training.component";
import { DisembarkDetailAppointmentComponent } from "../disembark/detail/detail.component";
import { EmbarkDetailAppointmentComponent } from "../embark/detail/detail.component";
import { TrainingDetailAppointmentComponent } from "../training/detail/detail.component";
import { AppointmentCardAppointmentComponent} from '../appointment/card/appointment.component';
import {AppointmentDetailAppointmentComponent} from '../appointment/detail/detail.component';
import { LogisticDetailAppointmentComponent } from '../logistic/detail/detail.component';
import { LogisticCardAppointmentComponent } from '../logistic/card/logistic.component';

@Injectable()
export class AppointemtMapping {

    /**
     * Mapeamento dos tipos de CARD de compromisso para seus respectivos templates;
     */
    private static cardMappings = {
        'Embark': EmbarkCardAppointmentComponent,
        'Disembark': DisembarkCardAppointmentComponent,
        'Training': TrainingCardAppointmentComponent,
        'Logistic': LogisticCardAppointmentComponent,
        'Appointment': AppointmentCardAppointmentComponent
    };

    /**
     * Mapeamento dos tipos DETAIL de compromisso para seus respectivos templates;
     */
    private static DetailMappings = {
        'Embark': EmbarkDetailAppointmentComponent,
        'Disembark': DisembarkDetailAppointmentComponent,
        'Training': TrainingDetailAppointmentComponent,
        'Logistic': LogisticDetailAppointmentComponent,
        'Appointment': AppointmentDetailAppointmentComponent
    };

    /**
     * Retorna o template do card a partir do tipo do COMPROMISSO;
     *
     * @param typeName : tipo do compromisso;
     */
    public static getCard(typeName: string) {
        let type = this.cardMappings[typeName];
        return type || UnknownCardTemplateComponent;
    }

    /**
     * Retorna o template de detalhamento a partir do tipo do COMPROMISSO;
     *
     * @param typeName : tipo do compromisso;
     */
    public static getContent(typeName: string) {
        let type = this.DetailMappings[typeName];
        return type || UnknownContentTemplateComponent;
    }
}
