<div class="loading" *ngIf="loading">{{ 'LOADING_DATA' | translate }}</div>

<ng-container *ngIf="!loading">
    <div class="wrapper">

        <div class="detail-title">{{ 'ATTACHMENTS' | translate }}</div>

        <k-document-view [useBorder]="false" [documents]="workerDocument.documents" *ngIf="workerDocument.documents.length > 0">
        </k-document-view>

        <div class="no-older-documents" *ngIf="workerDocument.documents.length === 0">{{ 'THERE_IS_NO_ATTACHMENT' | translate }}</div>

        <div class="detail-title">{{ 'OLDER_DOCUMENTS' | translate }}</div>

        <div class="no-older-documents" *ngIf="olderDocuments.length == 0">{{ 'NO_OLDER_DOCUMENTS' | translate }}</div>

        <div class="info-grid">

            <div class="info-item" *ngFor="let document of olderDocuments;">

                <div class="info-item-row">
                    <div *ngIf="workerDocument.documentTypeWellKnownId === 'OccupationalHealth'" class="info issued-date">
                        <div class="label">{{ 'QUALIFICATION' | translate }}</div>
                        <div class="value">{{ document.groupName }}</div>
                    </div>
                    <div class="info issued-date">
                        <div class="label">{{ 'ISSUE_DATE' | translate }}</div>
                        <div class="value">{{ document.issuedDate | i18nDate: 'date' | whenEmpty: '-' }}</div>
                    </div>

                    <div class="info issuer">
                        <div class="label">{{ 'ISSUER' | translate }}</div>
                        <div class="value">{{ document.issuer | whenEmpty:'-' }}</div>
                    </div>

                    <div class="info number">
                        <div class="label">{{ 'NUMBER' | translate }}</div>
                        <div class="value">{{ document.number | whenEmpty:'-' }}</div>
                    </div>

                    <div class="info validity">
                        <div class="label">{{ 'DOCUMENTS.EXPIRATION' | translate }}</div>
                        <div class="value">{{ document.validity | i18nDate: 'date' | whenEmpty:'-' }}</div>
                    </div>

                    <div class="info attachments-count">

                        <mat-icon
                            [class.no-files]="!document.documents || document.documents.length === 0"
                            matBadge="{{ document.documents.length }}"
                            matBadgeSize="small"
                            [matBadgeHidden]="!document.documents || document.documents.length === 0"
                        >
                            attach_file
                        </mat-icon>

                    </div>

                </div>

                <div class="info-item-row">

                    <div class="info attachments-list">
                        <div class="label">{{ 'ATTACHMENTS' | translate }}</div>

                        <k-document-view [useBorder]="false" [documents]="document.documents" *ngIf="document.documents.length > 0">
                        </k-document-view>

                        <div class="no-attachments" *ngIf="document.documents.length === 0">{{ 'THERE_IS_NO_ATTACHMENT' | translate }}</div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</ng-container>
