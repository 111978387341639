<div class="k-document-view">

    <table class="k-document-view-list" *ngIf="!isGrid">
        <tr *ngFor="let document of documents; let i = index;" (click)="onOpen(document)">
            <td style="width: 100%;" [style.border-bottom]="(i + 1 == documents.length) ? '' : '1px solid #ddd'">
                <k-file-card [document]="document" [useBorder]="useBorder"></k-file-card>
            </td>
        </tr>
    </table>

    <div class="k-document-view-grid" *ngIf="isGrid">
        <div class="item" *ngFor="let document of documents" (click)="onOpen(document)">
                <k-file-card [document]="document" [useBorder]="useBorder"></k-file-card>
        </div>
    </div>

</div>
