import { Component, OnInit, OnDestroy } from '@angular/core';
import { MyDrakeEventEmitter } from '@shared/services';
import { Subscription } from "rxjs";
import { ProfileView } from '@mydrake/domain/read/views/core';
import { ProfileService } from './profile.service';
import { Gender, WorkerCategoryType } from '@mydrake/domain/enums/core';
import { Constants } from '../../shared/core/constants';

@Component({
    selector: 'mydrake-profile',
    templateUrl: 'profile.component.html',
    styleUrls: ['profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    public worker : ProfileView = new ProfileView();
    public genderIcon: string;
    public categoryIcon: string;
    public avatarDataSquare: any = {
        size: 185,
        background: '#F44336', // by default it will produce dynamic colors
        fontColor: '#FFFFFF',
        text: 'R'
    };

    private _workersSubscription : Subscription;

    constructor(private _api : ProfileService, private _emitter: MyDrakeEventEmitter) {
        this._emitter.get(Constants.EventKeys.HideGlobalSearch).emit(true);
    }

    ngOnInit(): void {
        this._workersSubscription = this._api.getWorker().subscribe(worker => {
            this.worker = worker;
            this.loadIcons();
        });
    }

    ngOnDestroy(): void {
        this._emitter.get(Constants.EventKeys.HideGlobalSearch).emit(false);
        this._workersSubscription.unsubscribe();
    }

    private loadIcons(){
        this.genderIcon = (this.worker.gender === Gender.Male) ? 'icon-mydrake-male' : 'icon-mydrake-female';
        this.categoryIcon = (this.worker.category === WorkerCategoryType.OffShore) ? 'icon-mydrake-offshore' : 'icon-mydrake-onshore';
    }
}
