import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'toDate' })
export class ToDatePipe implements PipeTransform {

    transform(value: Array<any>, dateProperty: string): Array<any> {
        return value.map((item) => {
            item.date = moment(item[dateProperty]).startOf('day');
            item.isoDateFormat = moment(item[dateProperty]).format('YYYYMMDD');
            return item;
        });
    }

}
