import { Component, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { TrainingRequestService } from "./training.service";
import { Constants } from '../../shared/core/constants';
import { MyDrakeEventEmitter } from '@shared/services';
import { TrainingInfoView } from '@mydrake/domain/read/views/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mydrake-training',
    templateUrl: 'training.component.html',
    styleUrls: ['training.component.scss']
})
export class TrainingComponent implements OnDestroy {

    public loaded: boolean = false;
    public trainings: Array<TrainingInfoView> = [];
    public future: boolean;

    private page : number = 1;
    private limit : number = 15;
    private subscription : Subscription;
    private textSearch : string;
    private statusType : any;

    @ViewChild('appointmentDetailSideNav', { static: false }) detail: MatSidenav;
    @ViewChild('searchFilterSideNav', { static: false }) filter: MatSidenav;

    constructor(private _api : TrainingRequestService, private _emitter: MyDrakeEventEmitter) {
        this.trainings = [];
        this.getTrainigs(false);
        this.subscription = this._emitter.get(Constants.EventKeys.SearchTextChange).subscribe(text => this.onSearchListener(text));
    }

    public onSearchListener(textSearch : string){
        this.textSearch = textSearch;
        this.getTrainigs(this.future);
    }

    public onLastTrainings() : void {
        this.future = false;
        this.getTrainigs(this.future)
    }

    public onNextTrainings() : void {
        this.future = true;
        this.getTrainigs(this.future)
    }

    private getTrainigs(future: boolean) {
        this.page = 1;
        this._api.getTrainings(this.page, this.limit, this.textSearch, this.statusType, (future ? true : false)).subscribe(result => {
            this.loaded = true;
            this.trainings = result;
        });
    }

    public onOpenFilters() : void{
        this.detail.close();
        this.filter.open();
    }

    public onCloseDetail() {
        this.detail.close();
    }

    public onScrollDown () {

        this.loaded = false;

        if(this.trainings.length < this.page * this.limit){
            return;
        }

        this.page++;
        this._api.getTrainings(this.page, this.limit, this.textSearch, this.statusType, this.future).subscribe(result => {
            for (var index = 0; index < result.length; index++) {
                this.trainings.push(result[index]);
            }
            this.loaded = true;
        });
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
