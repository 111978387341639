import {environment} from '@mydrake/environments/environment';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class LocalTranslateHttpLoader extends TranslateHttpLoader {

    constructor(http: HttpClient) {
        super(http);
        this.prefix = `${environment.apiUrl}Translation/`;
        this.suffix = `/UI`;
    }

    getTranslation(lang: string): Observable<any> {
        return super.getTranslation(lang).pipe(map(response => {
            const translations = (response as any).JS.ANGULAR2;
            return translations;
        }));
    }
}
