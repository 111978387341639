import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { KFlowTransitionLogComponent } from './k-flow-transition-log.component';
import { KFlowTransitionLogApiService } from './k-flow-transition-log.api.service';
import { MaterialModule } from 'app/shared/core/material.module';
import { MyDrakePipesModule } from 'app/shared/pipes/pipes.module';
import { KAvatarModule } from '../k-avatar/k-avatar.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        MyDrakePipesModule,
        TranslateModule,
        KAvatarModule
    ],
    exports: [
        KFlowTransitionLogComponent
    ],
    declarations: [
        KFlowTransitionLogComponent
    ],
    entryComponents: [
        KFlowTransitionLogComponent
    ],
    providers: [
        KFlowTransitionLogApiService
    ]
})
export class KFlowTransitionLogModule { }
