import { Component, OnInit, OnDestroy } from '@angular/core';
import { InboxApi } from '../inbox.api';
import {KSnackBar, MyDrakeEventEmitter} from '@shared/services';
import { Subscription } from 'rxjs';
import { InboxModel, InboxFilterTag, InboxOrderProperty } from '@mydrake/domain/read/models/core';
import { Constants } from '../../../shared/core/constants';
import { ToDateAgoGroupPipe } from 'app/shared/pipes/to-date-ago-group.pipe';
import { ToDatePipe } from 'app/shared/pipes/to-date.pipe';

import * as _ from 'lodash';
import {UnreadItemService} from '../../../shared/core/services/not-read-item.service';

export interface InboxWrapper {
    id: string;
    date: Date;
    content: InboxModel;
}

export interface GroupWrapper {
    key: any;
    value: any;
}

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox-list.component.html',
    styleUrls: ['./inbox-list.component.scss'],
    providers: [ToDateAgoGroupPipe, ToDatePipe]
})
export class InboxListComponent implements OnInit, OnDestroy {

    public loaded = false;
    public anyInboxOpened: boolean;
    private _inboxFilterTag = InboxFilterTag.All;
    public inboxFilterTagList = [InboxFilterTag.All, InboxFilterTag.Completed, InboxFilterTag.NotCompleted];
    private _inboxOrderProperty = InboxOrderProperty.Date;
    public inboxOrderPropertyList = [InboxOrderProperty.Date, InboxOrderProperty.Status, InboxOrderProperty.Type];
    public items: Array<InboxWrapper> = [];
    public store: Array<InboxWrapper> = [];
    public groups: Array<GroupWrapper> = [];
    private searchTextChangeSubscription: Subscription;
    private filterTagChangeSubscription: Subscription;
    private orderByChangeSubscription: Subscription;
    private orderArrowChangeSubscription: Subscription;

    private page = 1;
    private limit = 10;
    private count = 0;
    private textSearch: string;
    private descending = true;


    get inboxFilterTag(): InboxFilterTag {
        return this._inboxFilterTag;
    }

    set inboxFilterTag(res) {
        this._inboxFilterTag = res;

        this.page = 1;
        this.items = [];
        this.store = [];
        this.groups = [];

        this.getInboxes();
    }

    get inboxOrderProperty(): InboxOrderProperty {
        return this._inboxOrderProperty;
    }

    set inboxOrderProperty(res) {
        this._inboxOrderProperty = res;

        this.page = 1;
        this.items = [];
        this.store = [];
        this.groups = [];

        this.getInboxes();
    }

    refresh() {
        this.page = 1;
        this.items = [];
        this.store = [];
        this.groups = [];
        this.getInboxes();
    }

    ngOnInit(): void {
        this.getInboxes();
    }

    ngOnDestroy(): void {
        this.searchTextChangeSubscription.unsubscribe();
        this.filterTagChangeSubscription.unsubscribe();
        this.orderByChangeSubscription.unsubscribe();
        this.orderArrowChangeSubscription.unsubscribe();
        this.loaded = false;
    }

    constructor(
        private _inboxApi: InboxApi,
        private _emitter: MyDrakeEventEmitter,
        private toDateAgoGroup: ToDateAgoGroupPipe,
        private toDate: ToDatePipe,
        private unreadItemService: UnreadItemService,
        private notifier: KSnackBar) {

        this.searchTextChangeSubscription = this._emitter.get(Constants.EventKeys.SearchTextChange
        ).subscribe(text => this.onSearchListener(text));
        this._emitter.get('INBOX_LIST_REFRESH').subscribe((inbox) => {
            this.refreshById(inbox);
        });

        this._emitter.get(Constants.EventKeys.OrderBy).emit(
            {
                value: this.inboxOrderPropertyList,
                defaultValue: InboxOrderProperty.Date,
                translatePath: 'INBOX.ORDER_BY.'
            });
        this._emitter.get(Constants.EventKeys.FilterTag).emit(
            {
                value: this.inboxFilterTagList,
                defaultValue: InboxFilterTag.All,
                translatePath: 'INBOX.FILTER_TAG.'
            });
        this._emitter.get(Constants.EventKeys.ShowOrderArrow).emit({ value: true, defaultValue: true });

        this.filterTagChangeSubscription = this._emitter.get(Constants.EventKeys.FilterTagChange)
            .subscribe((text) => this.inboxFilterTag = text);
        this.orderByChangeSubscription = this._emitter.get(Constants.EventKeys.OrderByChange)
            .subscribe((text) => this.inboxOrderProperty = text);
        this.orderArrowChangeSubscription = this._emitter.get(Constants.EventKeys.OrderArrowChange)
            .subscribe((value) => { this.descending = value; this.refresh(); });
    }

    private getInboxes(): void {

        this._inboxApi.list(this.page, this.limit, this.textSearch, this.descending,
            this.inboxFilterTag, this.inboxOrderProperty).subscribe(inboxes => {

                this.applyChanges(inboxes.items);

                this.count = inboxes.count;
                const groups = this.items;

                this.groups = this.toDate.transform(groups, 'date');
                if (this.inboxOrderProperty === InboxOrderProperty.Date) {
                    this.groups = this.toDateAgoGroup.transform(this.groups);
                } else {
                    this.groups = [{ key: null, value: this.groups }];
                }
                this.loaded = true;
            });
    }

    public onSearchListener(textSearch: string) {
        this.textSearch = textSearch;
        this.page = 1;
        this.groups = [];
        this.items = [];
        this.getInboxes();
    }

    public onScrollDown() {
        this.loaded = false;

        if (this.items.length === this.count) {
            return;
        }

        this.page++;
        this.getInboxes();
    }

    public openMessage(inbox) {
        inbox.opened = inbox.opened == null ? true : !inbox.opened;

        if (inbox.opened) {
            if (inbox.content.unreadItem) {
                this.unreadItemService.markAsRead(inbox.content.id, inbox.content.ownerId, 'Inbox').subscribe(() => {
                    inbox.content.unreadItem = false;
                });
            }

            this._inboxApi.getDetails(inbox.content.domainEntityId).subscribe(result => {
                inbox.content.context = result;
                inbox.content.completed = true;
            });
        } else {
            inbox.content.completed = false;
        }

        this.anyInboxOpened = inbox.opened;
    }

    public onMarkAsUnread(event, inbox) {
        // para não fechar o card quando clicar aqui
        event.stopPropagation();

        if (!inbox.content.unreadItem) {
            this.unreadItemService.markAsUnread(inbox.content.id, inbox.content.ownerId, 'Inbox').subscribe(() => {
                inbox.content.unreadItem = true;

                this.notifier.success('INBOX_MARKED_AS_NOT_READ');
            });
        }
    }

    private refreshById(inboxId: string) {

        let inbox = null;

        for (let i = 0; i < this.groups.length; i++) {

            for (let j = 0; j < this.groups[i].value.length; j++) {

                if (this.groups[i].value[j].id === inboxId) {
                    inbox = this.groups[i].value[j];
                }
            }
        }

        if (inbox) {
            this._inboxApi.getDetails(inbox.content.domainEntityId).subscribe(result => {
                inbox.content.currentState = result.currentState;
                inbox.content.currentStateName = result.currentStateName;
                inbox.content.currentStateProperties = result.currentStateProperties;
                inbox.content.context = result;
                inbox.content.completed = true;
            });
        }

    }

    private applyChanges(inboxes: Array<InboxModel>): void {

        inboxes.forEach(inbox => {
            const current = _.find(this.items, item => item.id === inbox.id);

            if (current) {
                current.content = inbox;
            } else {
                this.items.push({ id: inbox.id, date: inbox.date, content: inbox });
            }
        });
    }
}
