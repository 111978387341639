<mat-form-field
    [ngClass]="{ 'readonly-field': readonly }"
    [appearance]="fieldAppearance"
    [floatLabel]="floatLabel"
>
    <label *ngIf="fixedLabel" [class.label-field]="fixedLabel">{{
        placeholder
    }}</label>
    <mat-label *ngIf="!fixedLabel && floatLabel !== 'never'">{{
        placeholder
    }}</mat-label>

    <input
        (change)="handleEmptyInput($event)"
        [formControl]="control"
        [matAutocomplete]="auto"
        (focus)="handleFocus($event)"
        [placeholder]="fixedLabel || floatLabel === 'always' ? '' : placeholder"
        [readonly]="readonly"
        [required]="required"
        matInput
        type="text"
    />

    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
        (optionsScroll)="onScroll()"
        [displayWith]="displayWith"
        [panelWidth]="auto"
        showPanel="true"
        style="width: 100%"
    >
        <mat-option
            *ngFor="let option of filteredLookups$ | async"
            [value]="option"
        >
            <span style="display: flex">
                <k-avatar
                    *ngIf="loadIcons"
                    [avatarId]="option?.imageIconId"
                    [type]="iconType"
                    size="40px"
                    style="
                        align-items: center;
                        display: flex;
                        padding-right: 15px;
                    "
                ></k-avatar>
                <span> {{ option?.name }}</span>
            </span>
        </mat-option>
    </mat-autocomplete>
    <button
        type="button"
        class="clear-button"
        mat-button
        *ngIf="value && value.id && !readonly && !disabled"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="onClearOptionSelected($event)"
    >
        <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="control.hasError('invalidAutocompleteObject')">
        {{ "INVALID_AUTOCOMPLETE_OPTION" | translate }}
    </mat-error>

    <mat-hint *ngIf="noResults">
        {{ "COMMON.NO_RECORDS_FOUND" | translate }}
    </mat-hint>
</mat-form-field>
