import { NgModule } from '@angular/core';
import { GroupByPipe } from './group-by.pipe';
import { OrderByPipe } from './order-by.pipe';
import { ToDateAgoGroupPipe } from './to-date-ago-group.pipe';
import { I18nDatePipe } from './i18n-date.pipe';
import { LodashPipe } from './lodash.pipe';
import { YesOrNoPipe } from './yes-or-no.pipe';
import { ToDatePipe } from './to-date.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { FileSizePipe } from './size.pipe';
import { TruncateCharactersPipe } from './truncate.pipe';
import { WhenEmptyPipe } from './when-empty.pipe';

@NgModule({
    imports: [],
    exports: [
        GroupByPipe,
        OrderByPipe,
        ToDateAgoGroupPipe,
        I18nDatePipe,
        LodashPipe,
        ToDatePipe,
        YesOrNoPipe,
        SanitizePipe,
        FileSizePipe,
        TruncateCharactersPipe,
        WhenEmptyPipe
    ],
    declarations: [
        GroupByPipe,
        OrderByPipe,
        ToDateAgoGroupPipe,
        I18nDatePipe,
        LodashPipe,
        ToDatePipe,
        YesOrNoPipe,
        SanitizePipe,
        FileSizePipe,
        TruncateCharactersPipe,
        WhenEmptyPipe
    ],
    providers: [],
})
export class MyDrakePipesModule { }
