import { plainToClass } from 'class-transformer';
import { Component, OnInit, Input } from '@angular/core';
import { StepModel } from '@mydrake/domain/read/models/online-training';

@Component({
    selector: 'mydrake-online-training-resource-step',
    templateUrl: './resource-step.component.html',
    styleUrls: ['./resource-step.component.scss']
})
export class TrainingResourceStepComponent implements OnInit {

    @Input()
    public context: any;

    @Input()
    public domainId: string;

    @Input()
    public inboxId: string;

    public step: StepModel;

    constructor() { }

    ngOnInit() {
        this.step = plainToClass(StepModel, this.context.domainEntitySnapshot.currentStep as StepModel);
    }

}
