import {Component, OnInit} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import {FieldRenderTemplate} from '../field-render-template';

@Component({
    selector: 'k-date-field',
    templateUrl: './k-date-field.component.html',
    styleUrls: ['./k-date-field.component.scss']
})

export class KDateFieldComponent extends FieldRenderTemplate implements OnInit {

    ngOnInit () {
        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = '';
            this.prop.isValid = this.validation();
        });
    }

    onChange(evt: MatDatepickerInputEvent<Moment>){
        this.prop.value = evt.value ? evt.value.utc().startOf('day').format('DD/MM/YYYY') : '';
        this.prop.isValid = this.validation();
    }

    private validation(): boolean {
        return (this.prop.required === true && this.prop.value !== '') || this.prop.required === false;
    }
}
