export class Option {
    public id: string;
    public name: string;
    public imageIconId?: string;


    constructor(id: string = "", name: string = "", imageIconId: string = ""){
        this.id = id;
        this.name = name;
        this.imageIconId = imageIconId;
    }
}
