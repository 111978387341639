import {KFlowTransitionLogApiService} from './k-flow-transition-log.api.service';
import {FlowTransitionLog} from './models/transition-log.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';

@Component({
    selector: 'k-flow-transition-log',
    templateUrl: './k-flow-transition-log.component.html',
    styleUrls: ['./k-flow-transition-log.component.scss']
})
export class KFlowTransitionLogComponent implements OnInit {

    public domainId: string;
    public transitions: Array<FlowTransitionLog> = [];
    public args: any;

    constructor(
        public api: KFlowTransitionLogApiService,
        public dialogRef: MatDialogRef<KFlowTransitionLogComponent>,
        @Inject(MAT_DIALOG_DATA) public params: any) {
        this.domainId = params.domainId;
    }

    ngOnInit(): void {
        this.api.getTransitionLog(this.domainId).subscribe(logs => {
            this.transitions = logs;
            this.transitions.forEach(transition => {
                if (!this.areArgumentsEmpty(transition.arguments)) {
                    transition.extractedArguments = this.extractArguments(transition.arguments);
                }
            });
        });

    }

    public extractArguments(dictionary: any): any[] {
        console.log('extraindo argumentos');

        let args = [];

        Object.keys(dictionary).forEach(k => {

            let value = dictionary[k];

            if (typeof dictionary[k] !== 'string') {
                value = dictionary[k].ValueText;
            }

            if (value === '' && dictionary[k].Value !== '') {
                value = dictionary[k].Value;
            }

            let arg = {
                key: k,
                title: dictionary[k].Title ? dictionary[k].Title : k,
                value: value,
                image: null
            };

            if (dictionary[k] !== 'string' && dictionary[k].DocumentId) {
                arg.image = '/api/v1/DMS/GetStream?documentId=' + dictionary[k].DocumentId;
            }

            args.push(arg);

        });

        return args;
    }

    public areArgumentsEmpty(dictionary: any): boolean {
        return Object.keys(dictionary).length === 0 && dictionary.constructor === Object;
    }
}
