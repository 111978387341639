import { Component } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";

@Component({
    selector: 'mydrake-appointment-training-type',
    templateUrl: "./training.component.html",
    styleUrls: ['./training.component.scss']
})
export class TrainingCardAppointmentComponent extends AppointmentTemplateComponent {

}
