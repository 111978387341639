import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "k-grid",
    template: "<ng-content></ng-content>",
    styleUrls: ["./k-grid.component.scss"],
})
export class KGridComponent {
    @Input()
    public columns: number = 3;

    @Input()
    public padding: string;

    @HostBinding("style.grid-template-columns")
    public get hbColumns() {
        return `repeat(${this.columns}, 1fr)`;
    }

    @HostBinding("style.padding")
    public get hbPadding() {
        return this.padding;
    }
}
