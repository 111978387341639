import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDrakeSharedModule } from '../../shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TimeSheetService } from './time-sheet/time-sheet.service';
import { TimeSheetComponent } from './time-sheet/time-sheet.component';
import { ReportService } from './report.service';
import { ReportComponent } from './report.component';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    declarations: [
        ReportComponent,
        TimeSheetComponent
    ],
    imports: [
        CommonModule,
        MyDrakeSharedModule,
        FlexLayoutModule,
        InfiniteScrollModule,
        NgScrollbarModule
    ],
    providers: [
        ReportService,
        TimeSheetService
    ],
    entryComponents: [
        TimeSheetComponent
    ]
})
export class MyDrakeReportModule { }
