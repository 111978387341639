import { NgModule } from '@angular/core';
import { ScrollBarDirective } from "./scrollbar.directive";
import { KScrollBarComponent } from "./k-scrollbar.component";

@NgModule({
  declarations: [
    ScrollBarDirective,
    KScrollBarComponent
  ],
  exports: [
    ScrollBarDirective,
    KScrollBarComponent
  ]
})
export class KScrollModule { }
