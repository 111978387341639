export class MyDrakeException extends Error {

    public code: string;

    constructor(code: string, message?: string) {
        super(message);

        this.code = code;

        if (!this.message) {
            this.message = this.code;
        }

        console.error(this.message);
    }
}
