import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'mydrake-header',
    templateUrl: 'mydrake-header.component.html',
    styleUrls: ['./mydrake-header.component.scss']
})

export class MyDrakeHeader implements OnInit {

    public logo: string = `${environment.basePath}/assets/img/mydrake.png`;

    constructor() { }

    ngOnInit() { }
}
