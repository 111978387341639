<div class="quiz">

    <div *ngFor="let question of form.questions; let i = index;" class="question">

        <div class="question-header">
            <div class="bullet">{{i + 1}}</div>
            <div class="title">
                <span [innerHtml]="question.title"></span>
                <span *ngIf="question.isMandatory">*</span>
            </div>
        </div>

        <div class="question-content">
            <mat-radio-group
                class="options"
                *ngIf="question.type === 'MultipleChoice'; else essayContainer"
            >
                <mat-radio-button
                    class="option"
                    *ngFor="let option of question.options"
                    [value]="option.id"
                    (change)="markAnswer($event, question)"
                    [disabled]="expired"
                    [checked]="option.answer"
                >
                    <div>{{option.description}}</div>
                </mat-radio-button>
            </mat-radio-group>

            <ng-template #essayContainer>
                <mat-form-field class="essay-question">
                    <textarea
                        matInput
                        [disabled]="expired"
                        [value]="question.essayAnswer"
                        (input)="setEssayAnswer($event, question)"
                        name="essay-answer"
                    ></textarea>
                </mat-form-field>
            </ng-template>
        </div>

        <hr />

    </div>

    <div style="display: flex; flex-direction: row; ">
        <div style="flex:1"></div>
        <mydrake-inbox-transition
            #inboxTransition
            [transitions]="context.availableTransitions"
            [flowType]="context.flowType"
            [domainId]="domainId"
            [inboxId]="inboxId"
            [onValid]="validate.bind(this)"
        >
        </mydrake-inbox-transition>
    </div>

</div>
