
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { DocumentInfo } from '@shared/models';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import {UploadUriInfo} from '../../models/dms.model';
import {FileUploaderOptions} from 'ng2-file-upload';

export interface IDMS {

    getFileUploaderOptions() : any;
    getFileUploaderOptionsToAzureStorage(url: string): FileUploaderOptions;
    getDocumentById(id : string) : Observable<DocumentInfo>;
}

@Injectable()
export class DMS implements IDMS {

    constructor(private http : HttpClient) {}

    /**
     * Retorna as configurações para realizar um upload no DMS;
     */
    public getFileUploaderOptions() : any {
        return {
            url: environment.apiUrl + "DMS/Upload",
            method: "POST",
            autoUpload: true,
            headers: [
                { name:'Content-Disposition', value: 'form-data' }
            ]
        };
    }

    public getFileUploaderOptionsToAzureStorage(url: string): FileUploaderOptions {
        return {
            url: url,
            method: 'PUT',
            disableMultipart: true,
            autoUpload: true,
            headers: [
                { name: 'x-ms-blob-type', value: 'BlockBlob' },
                { name: 'x-ms-blob-content-type', value: 'application/octet-stream' },
                { name: 'x-ms-blob-content-disposition', value: 'form-data' }
            ]
        };
    }
    /**
     * Retorna o documento do DMS a partir do seu identificador;
     *
     * @param id: identificador do documento;
     */
    public getDocumentById(id : string) : Observable<DocumentInfo>{
        return this.http.get(environment.apiUrl + `DMS/${id}`).pipe(map(response => plainToClass(DocumentInfo, response as DocumentInfo)));
    }

    /**
     * Retorna os documentos do DMS a partir dos seus identificadores;
     *
     * @param ids: identificadores dos documentos;
     */
    /*public getAllDocumentsByIds(id : string) : Observable<Array<DocumentInfo>>{

    }*/


    public getUploadUri(): Observable<UploadUriInfo> {
        return this.http.get(environment.apiUrl + `DMS/UploadUri`).pipe(map(response => plainToClass(UploadUriInfo, response as UploadUriInfo)));
    }

    /**
     * Retorna o stream do documento;
     *
     * @param document: informações do documento;
     */
    public getStream(document : DocumentInfo) : string {

        if(document.isTemporary) return `${environment.apiUrl}DMS/GetTemporaryStream?documentId=${document.id}&contentType=${encodeURIComponent(document.contentType)}`;

        return `${environment.apiUrl}DMS/GetStream?documentId=${document.id}`;
    }

    /**
     * Realiza o download do documento;
     *
     * @param  document: informações do documento;
     */
    public download(document : DocumentInfo) : string {

        if(document.isTemporary) return `${environment.apiUrl}DMS/DownloadTemporaryFile?documentId=${document.id}&title=${encodeURIComponent(document.title)}`;
        return `${environment.apiUrl}DMS/Download?documentId=${document.id}`;
    }

    public confirmDownload(id: string) {
        if (id && id!=undefined)
        {
            this.http.post(environment.apiUrl + `DMS/ConfirmDownload?documentId=${id}`,{}).subscribe();
        }

    }
}
