import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { RequestCenterService } from '../request-center.service';
import { RequestService } from '@mydrake/domain/write/commands/request-center/request-service.command';

@Component({
    selector: 'confirm-request',
    templateUrl: 'confirm-request.component.html',
    styleUrls: ['confirm-request.component.scss']
})
export class ConfirmRequestComponent implements OnInit {
    constructor(private bottomSheetRef: MatBottomSheetRef<ConfirmRequestComponent>,
        private requestCenterService: RequestCenterService,
        @Inject(MAT_BOTTOM_SHEET_DATA) private data: RequestService) { }

    ngOnInit(): void {
    }

    public onCancel(): void {
        this.bottomSheetRef.dismiss();
    }

    public onYes(): void {

        this.requestCenterService.request(this.data).subscribe((code) => {
            this.bottomSheetRef.dismiss(code);
        });
    }
}
