import { Component, ElementRef, Input, OnInit, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { environment } from 'environments/environment';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'k-icon',
    template: `
                <img class="k-icon" [src]="url" [style.width]="size"
                [style.height]="size" [class.circle]="type == 'circle'"/>
              `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./k-icon.component.scss']

})
export class KIconComponent implements OnInit, OnChanges {
    @Input() name: string;
    @Input() type = 'circle';
    @Input() size = '100px';
    @Input() iconId: string;
    @Input() fontsize = '30px';

    public url: string;

    private _el: HTMLElement;

    constructor(el: ElementRef, private sanitizer: DomSanitizer) {
        this._el = el.nativeElement;
    }

    ngOnInit() {
        this.loadIcon();
    }

    ngOnChanges(...args: any[]) {
        this.loadIcon();
    }

    private loadIcon() {
        if (this.iconId) {
            this.url = `${environment.appUrl}/api/v1/DMS/GetStream?documentId=${this.iconId}`;
        } else {
            this.url = `${environment.basePath}/assets/img/issue.svg`;
        }
    }
}

