import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MyDrakeSharedModule } from "../../shared/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AdditionalEventsComponent } from "./additional-events.component";
import { AdditionalEventsApi } from "./additional-events.service";
import { CreateAdditionalEventsComponent } from "./create/create-additional-event.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AdditionalEventsRouterGuardService } from "./additional-event.guard.service";

@NgModule({
    declarations: [
        AdditionalEventsComponent,
        CreateAdditionalEventsComponent
    ],
    imports: [
        CommonModule,
        MyDrakeSharedModule,
        FlexLayoutModule,
        InfiniteScrollModule
    ],
    providers: [
        AdditionalEventsApi,
        AdditionalEventsRouterGuardService
    ],
    entryComponents: [
        CreateAdditionalEventsComponent
    ]
})
export class MyDrakeAdditionalEventsModule { }
