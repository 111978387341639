import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class UnreadItemService {

    constructor(private http: HttpClient) { }

    public markAsUnread(refId: string, workerId: string, topic: string): Observable<any> {

        const cmd = {
            refId: refId,
            workerId: workerId,
            topic: topic
        };

        return this.http.post<any>(environment.apiUrl + `My/UnreadItem/MarkAsUnread`, cmd);
    }

    public markAsRead(refId: string, workerId: string, topic: string): Observable<any> {
        const cmd = {
            refId: refId,
            workerId: workerId,
            topic: topic
        };

        return this.http.post(environment.apiUrl + `My/UnreadItem/MarkAsRead`, cmd);
    }
}

