import { NgModule } from '@angular/core';

import { WizardComponent } from "./k-wizard.component";
import { WizardIndexComponent } from "./k-wizard-index.component";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { WizardStepComponent } from "./k-wizard-step.component";
import { WizardStepHeaderComponent } from "./k-wizard-step-header.component";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule
    ],
    exports: [
        WizardComponent,
        WizardIndexComponent,
        WizardStepComponent,
        WizardStepHeaderComponent
    ],
    declarations: [
        WizardComponent,
        WizardIndexComponent,
        WizardStepComponent,
        WizardStepHeaderComponent
    ],
    entryComponents: [
        WizardComponent,
        WizardIndexComponent,
        WizardStepComponent,
        WizardStepHeaderComponent
    ],
    providers: [

    ],
})
export class WizardModule { }
