<mydrake-inbox-taskbar [transitions]="context.availableTransitions" [flowType]="context.flowType" [domainId]="domainId" [inboxId]="inboxId"></mydrake-inbox-taskbar>

<div fxLayout="row">
    <div fxLayout="column" fxFlex>
            <div fxLayout="row" fxFlex>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.WORKER_OPERATIONAL_UNIT_DESCRIPTION' | translate }}</label>
                    <div>{{ context.domainEntitySnapshot.workerOperationalUnitDescription }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.DATE' | translate}}</label>
                    <div *ngIf="context.domainEntitySnapshot.date">{{ context.domainEntitySnapshot.date  | i18nDate:'date' }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.CLOSED_DATA' | translate}}</label>
                    <div *ngIf="context.domainEntitySnapshot.closedDate">{{ context.domainEntitySnapshot.closedDate | i18nDate:'date'  }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.EMBARK_OPERATIONAL_UNIT_DESCRIPTION' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.embarkOperationalUnitDescription }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.DISEMBARK_OPERATIONAL_UNIT_DESCRIPTION' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.disembarkOperationalUnitDescription }}</div>
                </div>
            </div>


            <div fxLayout="row" fxFlex>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.SCHEDULE_CODE' | translate }}</label>
                    <div>{{ context.domainEntitySnapshot.scheduleCode }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.PASSENGER_TYPE_DESCRIPTION' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.passengerTypeDescription }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.CONTRACT_DESCRIPTION' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.contractDescription }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.CONTRACT_NUMBER' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.contractNumber }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.COST_CENTER_DESCRIPTION' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.costCenterDescription }}</div>
                </div>
            </div>

            <div fxLayout="row" fxFlex>
                <div fxFlex class="content" *ngIf="context.domainEntitySnapshot.logisticScheduleType" >
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.LOGISTIC_SCHEDULE_TYPE' | translate }}</label>
                    <div>{{ context.domainEntitySnapshot.logisticScheduleType }}</div>
                </div>
                <div fxFlex class="content"  *ngIf="context.domainEntitySnapshot.logisticScheduleType">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.FLIGHT_DESCRIPTION_LOGISTIC_SCHEDULE' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.flightDescriptionLogisticSchedule }}</div>
                </div>
                <div fxFlex class="content"  *ngIf="context.domainEntitySnapshot.timeToGoFlightLogisticSchedule">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.TIME_TO_GO_FLIGHT_LOGISTIC_SCHEDULE' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.timeToGoFlightLogisticSchedule  | i18nDate:'hour' }}</div>
                </div>
                <div fxFlex class="content"  *ngIf="context.domainEntitySnapshot.flightDescriptionTransportRequest">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.FLIGHT_DESCRIPTION_TRANSPORT_REQUEST' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.flightDescriptionTransportRequest }}</div>
                </div>
                <div fxFlex class="content"  *ngIf="context.domainEntitySnapshot.numberTransportRequest">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.NUMBER_TRANSPORT_REQUEST' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.numberTransportRequest }}</div>
                </div>
                <div fxFlex class="content"  *ngIf="context.domainEntitySnapshot.hourTransportRequest">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.HOUR_TRANSPORT_REQUEST' | translate}}</label>
                    <div>{{ context.domainEntitySnapshot.hourTransportRequest  | i18nDate:'hour' }}</div>
                </div>
            </div>

            <div fxLayout="row" fxFlex>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.PARTICIPANT_LOGISTIC_SCHEDULE.COMMENT' | translate }}</label>
                    <div>{{ context.domainEntitySnapshot.comment }}</div>
                </div>
            </div>

            <br>

            <k-formio-list [linkedFormInstances]="context.currentStateLinkedFormInstances" (submit)="onSubmitted($event)"></k-formio-list>

            <k-document-view class="documents" *ngIf="context.workersCanViewAttachments" [documents]="context.attachments" mode="grid"></k-document-view>
        </div>

</div>
