<mydrake-inbox-taskbar [transitions]="context.availableTransitions" [flowType]="context.flowType" [domainId]="domainId" [inboxId]="inboxId"></mydrake-inbox-taskbar>

<k-grid columns="3">
    <ng-container *ngFor="let field of fields" [ngSwitch]="field.type">
        <k-key-value
            *ngSwitchCase="'boolean'"
            key="{{ 'DOCUMENT_TYPE.' + field.key }}"
            [value]="(field.value ? 'YES' : 'NOT') | translate"
        >
        </k-key-value>
        <k-key-value
            *ngSwitchCase="'date'"
            key="{{ 'DOCUMENT_TYPE.' + field.key }}"
            [value]="field.value | i18nDate: 'date'"
        >
        </k-key-value>
        <k-key-value
            *ngSwitchCase="'option'"
            key="{{ 'DOCUMENT_TYPE.' + field.key }}"
            [value]="field.value.name"
        >
        </k-key-value>
        <k-key-value
            *ngSwitchCase="'period'"
            key="{{ 'DOCUMENT_TYPE.' + field.key }}"
            [value]="
                (field.value.start | i18nDate: 'date') +
                ' - ' +
                (field.value.end | i18nDate: 'date')
            "
        >
        </k-key-value>
        <k-key-value
            *ngSwitchDefault
            [class.occupy-all-columns]="field.key === 'Comments'"
            key="{{ 'DOCUMENT_TYPE.' + field.key }}"
            [value]="field.value"
        >
        </k-key-value>
    </ng-container>
</k-grid>

<k-document-view
    class="documents"
    *ngIf="context.workersCanViewAttachments"
    [documents]="context.attachments"
    mode="grid"
></k-document-view>
