import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {MyDrakeEventEmitter, DMS, KSnackBar} from '@shared/services';
import { Constants } from 'app/shared/core/constants';
import { DocumentsService } from './documents.service';
import { environment } from '@mydrake/environments/environment';
import {
    IWorkerDocument,
    DocumentOrderProperty,
    GroupType,
    DocumentsAndFoldersResult,
    DocumentFilterTag
} from '@mydrake/domain/read/models/documents';
import { TranslateService } from '@ngx-translate/core';
import { DocumentInfo } from '@shared/models';
import { ParametersService } from 'app/shared/providers';

@Component({
    selector: 'documents',
    templateUrl: 'documents.component.html',
    styleUrls: ['documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription = new Subscription();
    private textSearch: string;
    private page = 1;
    private limit = 15;
    private regex = /(?:\.([^.]+))?$/;
    private _documentFilterTag = DocumentFilterTag.All;

    public workerDocuments = new Array<IWorkerDocument>();

    public folders = new Array<string>();
    public inFolder = false;
    public folder?: string;
    public descending = false;
    public _documentOrderProperty = DocumentOrderProperty.Type;
    public filterTagTranslatePath: string = 'DOCUMENTS.GROUP_TYPE.';
    public loading: boolean = true;

    constructor(
        private emitter: MyDrakeEventEmitter,
        private _api: DocumentsService,
        private parameters: ParametersService,
        private dms: DMS,
        private translateService: TranslateService,
        private notifier: KSnackBar) {

            this.subscriptions.add(this.emitter.get(Constants.EventKeys.SearchTextChange)
                .subscribe((text: string) => this.onSearchListener(text)));

            this.emitter.get(Constants.EventKeys.OrderBy).emit({
                value: [
                    DocumentOrderProperty.Name,
                    DocumentOrderProperty.Type,
                    DocumentOrderProperty.Validity
                ],
                defaultValue: DocumentOrderProperty.Type,
                translatePath: 'DOCUMENTS.ORDER_BY.'
            });

            this.emitter.get(Constants.EventKeys.ShowOrderArrow).emit({
                value: true,
                defaultValue: false
            });

            this.subscriptions.add(this.emitter.get(Constants.EventKeys.FilterTagChange)
                .subscribe((text) => this.documentFilterTag = text));
            this.subscriptions.add(this.emitter.get(Constants.EventKeys.OrderByChange)
                .subscribe((text) => this.documentOrderProperty = text));
            this.subscriptions.add(this.emitter.get(Constants.EventKeys.OrderArrowChange)
                .subscribe((value) => { this.descending = value; this.refresh(); }));
    }

    get documentFilterTag(): DocumentFilterTag {
        return this._documentFilterTag;
    }

    set documentFilterTag(res) {
        this._documentFilterTag = res;

        this.page = 1;
        this.refresh();
    }

    get documentOrderProperty(): DocumentOrderProperty {
        return this._documentOrderProperty;
    }

    set documentOrderProperty(res) {
        this._documentOrderProperty = res;

        this.page = 1;
        this.refresh();
    }

    get breadcrumbLabelSuffix(): string {
        return this.folder ? ` / ${this.folder.toUpperCase()}` : '';
    }

    async ngOnInit(): Promise<void> {
        this.load(this.reloadWorkerDocuments);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public toggleOrder() {
        this.descending = !this.descending;
        this.refresh();
    }

    public downloadFilesFromFolder(folder: string, $event?: any) {
        $event?.stopPropagation();

        this._api.prepareDownloadFile(folder).subscribe(x => {
            this.notifier.success('DOCUMENTS.MESSAGE_DOWNLOAD_ALL_DOCUMENTS');
        });
    }

    public getIconType(file: IWorkerDocument): string {
        const groupType = file.groupType;
        if (groupType !== GroupType.Additional) {
            return groupType.toLowerCase();
        } else if (file.originalName) {
            const result = this.regex.exec(file.originalName)[1];
            if (result) {
                return result.toLowerCase();
            }
        }

        return 'file';
    }

    public onDownloadAllFiles() {
        let folder: string;
        this.folder ? folder = this.folder : folder = '*';

        this._api.prepareDownloadFile(folder).subscribe(x => {
            this.notifier.success('DOCUMENTS.MESSAGE_DOWNLOAD_ALL_DOCUMENTS');
        });
    }

    public onSearchListener(textSearch: string) {
        this.textSearch = textSearch;
        this.page = 1;

        this.load(this.reloadWorkerDocuments);
    }

    public onScrollDown() {
        if (this.workerDocuments.length < this.page * this.limit) {
            return;
        }
        this.page++;
        this.load(result => {
            result.workerDocuments.forEach(documents => this.workerDocuments.push(documents));
        });
    }

    public translateByGroupType(groupType: GroupType): string {
        if (groupType) {
            return this.translateService.instant(this.filterTagTranslatePath + groupType);
        }
    }

    public countDocuments(workerDocument: IWorkerDocument): number {
        return workerDocument.documents?.length || 0;
    }

    public onOpenFolder(folderName: string) {
        this.folder = folderName;
        this.inFolder = true;
        this.workerDocuments = [];
        this.folders = [];
        this.page = 1;

        this.load(this.reloadWorkerDocuments);
    }

    public onBack() {
        this.inFolder = false;
        this.folder = null;
        this.workerDocuments = [];
        this.folders = [];
        this.page = 1;

        this.load(data => {
            this.workerDocuments = data.workerDocuments;
            this.folders = data.folders;
        });
    }

    // --------------------------------------
    // PRIVATES -----------------------------
    // --------------------------------------

    private refresh() {
        this.page = 1;
        this.load(this.reloadWorkerDocuments);
    }

    private reloadWorkerDocuments = (data: DocumentsAndFoldersResult) => {
        this.workerDocuments = data.workerDocuments;
        this.folders = data.folders;

        this.filterTagTranslatePath = '';
        const allOptionTranslation = `DOCUMENTS.GROUP_TYPE.${DocumentFilterTag.All}`

        this.emitter.get(Constants.EventKeys.FilterTag).emit({
            value: [...data.groupTypes],
            defaultValue: this.documentFilterTag ?? DocumentFilterTag.All,
            translatePath: this.filterTagTranslatePath,
            allValue: DocumentFilterTag.All,
            allText: allOptionTranslation
        });
    }

    private load(onSuccess: (data: DocumentsAndFoldersResult) => void = null, onError: (error: any) => void = null): void {
        this._api.getWorkerDocuments(this.page, this.limit,
            this.descending, this._documentOrderProperty, this._documentFilterTag, this.textSearch, this.folder)
            .subscribe(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }, error => {
                if (onError) {
                    onError(error);
                }
            }, () => this.loading = false);
    }
}
