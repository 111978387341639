import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: 'mydrake-register-successfully-dialog',
    templateUrl: 'successfully.component.html',
    styleUrls: ['./successfully.component.scss']
})
export class RegisterSuccessfullyDialog {

    public message: string;

    constructor(
        private _dialog: MatDialogRef<RegisterSuccessfullyDialog>,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.message = data.message;
        }

    public onComplete(): void {
        this._dialog.close();
        this._router.navigate(['home']);
    }

}
