<div>
    <div class="valid-days positive"
        *ngIf="validDays && validDays > 90 && isPositive(validDays)">
        {{ getModule(validDays) }}
        {{ 'DOCUMENTS.DAYS_TO_EXPIRE' | translate }}
    </div>

    <div class="valid-days warning"
        *ngIf="validDays && isPositive(validDays) && 90 >= validDays">
        {{ getModule(validDays) }}
        {{ 'DOCUMENTS.DAYS_TO_EXPIRE' | translate }}
    </div>

    <div class="valid-days negative"
        *ngIf="validDays && !isPositive(validDays)">
        {{ 'DOCUMENTS.EXPIRED_TO' | translate }}
        {{ getModule(validDays) }}
        {{ 'DOCUMENTS.DAYS' | translate }}
    </div>
</div>