//Angular
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";

//Providers
import { KSnackBar } from '@shared/services';
import { environment } from 'environments/environment';
import { HomeApi } from './api/home.api';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public videos = [`https://sia-cdn-prod-spo.azureedge.net/videos/MyDrakeIntro.mp4`];

    public images: any = {
        logo: `${environment.basePath}/assets/img/mydrake.png`,
        mobile: `${environment.basePath}/assets/img/mydrake-mobile.png`,
        apple: `${environment.basePath}/assets/img/apple.svg`
    }

    private _isUserLogged: boolean = false;

    constructor(private _router: Router, private _notifier : KSnackBar, private _api: HomeApi) {

        _router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {

                if (event.url.endsWith("unavailable")) {
                    _notifier.error('MESSAGES.SERVICE_UNAVAILABLE');
                } else if (event.url.endsWith("forbbiden")){
                    _notifier.error('MESSAGES.FORBBIDEN_OPERATION');
                } else if (event.url.endsWith("unauthorized")){
                    _notifier.error('MESSAGES.NOT_AUTHORIZED_OPERATION');
                }
            }

        })
    }

    ngOnInit() {
        this._api.getPublicInfo().subscribe(info => this._isUserLogged = info && info.isUserLogged)
    }

    login() {
        const destination = this._isUserLogged ? `/my${environment.redirectUrl}` : environment.authorityUrl
        window.location.href = `${environment.appUrl}${destination}`;
    }

}
