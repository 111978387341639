import { EventEmitter } from '@angular/core';

export class MyDrakeEventEmitter {

    private emitters: { [eventName: string]: EventEmitter<any> } = {}

    public get(eventName: string): EventEmitter<any> {
        if (!this.emitters[eventName]){
            this.emitters[eventName] = new EventEmitter<any>();
        }
        return this.emitters[eventName];
    }

    public unsubscribe(eventName : string){
        this.emitters[eventName] = new EventEmitter<any>();
    }

}
