import { Input, Directive } from '@angular/core';
import { KFormioSubmission } from 'app/shared/components/k-formio-list/k-formio-list.component';
import { CreateOrUpdateFormInstanceContentsByDomainEntity, FormInstancePayloadContent } from '@mydrake/domain/write/commands/form-instance/form-instance.command';
import { CommonApi } from 'app/shared/services/common.api';
import { KSnackBar } from '@shared/services';
import { FormInstanceSaveOrUpdateSubmission } from "app/core/inbox/inbox-transition/inbox-transition.component";

@Directive()
export abstract class TemplateComponent {
  public inboxId: string;
  public context: any;
  public domainId: string;
  public commonApi: CommonApi;
  public notifier: KSnackBar;

  @Input('onSubmitted')
  public onSubmitted(submission: KFormioSubmission[]) {

    let cmd = new CreateOrUpdateFormInstanceContentsByDomainEntity();
    cmd.flowIdentifier = this.context.flowIdentifier;
    cmd.flowType = this.context.flowType;
    cmd.domainEntityId = this.domainId;

    cmd.newContents = submission.map(x => new FormInstancePayloadContent(x.formId, JSON.stringify(x.content)));

    this.commonApi.createOrUpdateFormInstanceContentsByDomainEntity(cmd).subscribe(x => {
      this.notifier.success('INBOX.COMMON.SAVED_SUCCESSFULLY');
    }, (err) => {
        console.error(err);
        this.notifier.success('INBOX.COMMON.SAVE_ERROR');
    });
  }

  @Input('onSaveOrUpdateForm')
  public onSaveOrUpdateForm(submission: FormInstanceSaveOrUpdateSubmission[]) {

    const cmd = new CreateOrUpdateFormInstanceContentsByDomainEntity();
    cmd.flowIdentifier = this.context.flowIdentifier;
    cmd.flowType = this.context.flowType;
    cmd.domainEntityId = this.domainId;

    cmd.newContents = submission.map(x => new FormInstancePayloadContent(x.formId, JSON.stringify(x.content)));

    this.commonApi.createOrUpdateFormInstanceContentsByDomainEntity(cmd).subscribe(x => {
      this.notifier.success('INBOX.COMMON.SAVED_SUCCESSFULLY');
    }, (err) => {
      console.error(err);
      this.notifier.success('INBOX.COMMON.SAVE_ERROR');
    });
  }
}
