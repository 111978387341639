<div class="k-document-viewer">
    <div class="k-document-viewer-title">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <k-file-icon [contentType]="current.contentType"></k-file-icon>
            <h3 fxFlex>{{current.title}}</h3>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>
    <div class="k-document-viewer-content">

        <div id="viewer">

            <div class="loading" *ngIf="progress">
                <mat-spinner></mat-spinner>
            </div>

            <!-- NAVIGATION BUTTON -->
            <div class="navigation next">
                <button class="circular -black" (click)="onNext()" *ngIf="index < documents.length - 1">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
            <div class="navigation previous" (click)="onPrevious()" *ngIf="index > 0">
                <button class="circular -black">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            <!--/ NAVIGATION BUTTON -->

            <!-- ZOOM BUTTON -->
            <div class="zoom in" *ngIf="current.isImage()">
                <button class="circular -white" (click)="onZoomIn()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="zoom out" *ngIf="current.isImage()">
                <button class="circular -white" (click)="onZoomOut()">
                    <mat-icon>remove</mat-icon>
                </button>
            </div>
            <!--/ ZOOM BUTTON -->

            <!-- CONTENT VIEWER-->
            <div *ngIf="!progress">
                <div class="viewer image"  *ngIf="current.id !== null && current.isImage()">
                    <img (load)="confirmDownload(current.id)" [src]="current.content" #image/>
                </div>

                <div class="viewer pdf" *ngIf="current.id !== null && !current.isImage()">
                    <iframe (load)="confirmDownload(current.id)" [src]="current.content"></iframe>
                </div>
            </div>
            <!--/ CONTENT VIEWER -->

        </div>

    </div>
</div>
