import { Component, OnInit } from '@angular/core';
import { environment } from './../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CultureProvider } from '@shared/providers';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

    constructor(private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private culture: CultureProvider) {
    }

    ngOnInit() {

        this.culture.configure();

        this.iconRegistry.addSvgIcon('icon-mydrake-additional-events',
            this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.basePath}/assets/img/icons/additional-event.svg`));
    }

}
