import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RequestCenterService } from '../request-center.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AvailableService } from '@mydrake/domain/read/models/request-center';
import { FormInstancePayloadContent } from '@mydrake/domain/write/commands/form-instance/form-instance.command';
import { KSnackBar } from '@shared/services';
import { FlowLinkedFormInstance, FlowFormInstance } from '@mydrake/domain/read/models/core/inbox-transition.model';

import { UUID } from 'angular2-uuid';

import { CreateOrUpdateIssue } from '@mydrake/domain/write/commands/request-center/create-or-update-issue.command';
import { ConfirmRequestComponent } from '../confirm-request/confirm-request.component';
import { RequestSubmittedComponent } from '../request-submitted/request-submitted.component';
import { DeleteIssueAndDraftFormInstances } from '@mydrake/domain/write/commands/request-center/delete-issue-and-draft-form-instances.command';
import {KFormioListComponent, KFormioSubmission} from '../../../shared/components/k-formio-list/k-formio-list.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { FormInstancesAndDefinitionsModel } from '@mydrake/domain/read/models/request-center/form-instances-and-definitions.model';

@Component({
    selector: 'request-details',
    templateUrl: 'request-details.component.html',
    styleUrls: ['request-details.component.scss']
})
export class RequestDetailsComponent implements OnInit {
    linkedForms = new Array<FlowLinkedFormInstance>();
    forms = new Array<any>();
    availableService: AvailableService;
    loading: boolean = false;
    loadingInitialData: boolean = false;

    @ViewChild('kformiolist', { static: false }) kformiolist: KFormioListComponent;

    constructor(
        private dialog: MatDialogRef<RequestDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) private params: any,
        private api: RequestCenterService,
        private bottomSheet: MatBottomSheet,
        public notifier: KSnackBar,
        private dialogRequestSubmitted: MatDialog) {
    }

    ngOnInit(): void {
        this.dialog.disableClose = true;
        this.loadingInitialData = true;

        if (this.params.isStart) {
            // verifica se tem formulario inicial
            this.api.getInitialFormDefinitions(this.params.availableService.flowIdentifier).subscribe(formDefinitions => {

                if (formDefinitions.length > 0) {
                    // tem formulario inicial.

                    this.availableService = this.params.availableService;
                    this.linkedForms = formDefinitions as FlowLinkedFormInstance[];

                    this.linkedForms.forEach((linkedForm: FlowLinkedFormInstance) => {
                        linkedForm.formInstanceContent = { data: {} };
                    });

                } else {
                    // TODO Não tem formulario inicial, vamos iniciar o fluxo
                    alert('Não implementado ainda! Não tem formulario inicial, vamos iniciar o fluxo!');
                }

                this.loadingInitialData = false;
            });
        } else {
            // solicita os dados de definicao do form e conteudo.
            this.api.getDraftFormInstancesAndDefinitions(this.params.availableService.currentIssuePendingToRequestId)
                .subscribe((result: FormInstancesAndDefinitionsModel) => {

                    this.availableService = this.params.availableService;
                    this.linkedForms = result.formDefinitions as FlowLinkedFormInstance[];

                    const formInstances = result.formInstances as FlowFormInstance[];

                    this.linkedForms.forEach((linkedForm: FlowLinkedFormInstance) => {

                        const formInstance = formInstances.find(x => x.formId === linkedForm.form.id);

                        if (formInstance && formInstance.content) {
                            linkedForm.formInstanceContent = JSON.parse(formInstance.content);
                        } else {
                            // define um valor vazio.
                            linkedForm.formInstanceContent = { data: {} };
                        }
                    });

                    this.loadingInitialData = false;
                });
        }
    }

    public onRequest() {

        if (this.kformiolist.isValid()) {

            this.loading = true;
            this.getCommandToSaveDraft().subscribe((saveDraftCmd: CreateOrUpdateIssue) => {
                this.api.createOrUpdateIssue(saveDraftCmd).subscribe(x => {

                    const cmd = {
                        issueId: saveDraftCmd.issueId,
                        flowIdentifier: this.availableService.flowIdentifier
                    };

                    this.availableService.currentIssuePendingToRequestId = saveDraftCmd.issueId;
                    this.loading = false;
                    this.reloadComponent();

                    this.bottomSheet.open(ConfirmRequestComponent, { data: cmd }).afterDismissed().subscribe((code: number) => {
                        if (code) {
                            this.dialogRequestSubmitted.open(RequestSubmittedComponent,
                                {
                                    width: '650px', data: code
                                });
                            this.dialog.close();
                        }
                    });

                }, (err) => {
                    this.bottomSheet.dismiss();
                    this.notifier.error('INBOX.COMMON.SAVE_ERROR');
                });
            });
        }
    }

    public onCancelDraft() {
        const cmd = new DeleteIssueAndDraftFormInstances();
        cmd.flowIdentifier = this.availableService.flowIdentifier;
        cmd.issueId = this.availableService.currentIssuePendingToRequestId;

        this.api.deleteIssue(cmd).subscribe(() => {
            this.notifier.success('COMMON.DISCARTED_SUCCESSFULLY');
            this.dialog.close();
        });
    }

    public onSaveDraft() {
        this.loading = true;
        this.getCommandToSaveDraft().subscribe((cmd: CreateOrUpdateIssue) => {
            this.api.createOrUpdateIssue(cmd).subscribe(x => {
                this.notifier.success('INBOX.COMMON.SAVED_SUCCESSFULLY');
                this.availableService.currentIssuePendingToRequestId = cmd.issueId;
                this.loading = false;
                this.reloadComponent();
            }, (err) => {
                console.error(err);
                this.notifier.error('INBOX.COMMON.SAVE_ERROR');
            });
        });
    }

    private getCommandToSaveDraft(): Observable<CreateOrUpdateIssue> {
        return this.kformiolist.getContentForm().pipe(map((submission: KFormioSubmission[]) => {
            const content = submission.map(x => new FormInstancePayloadContent(x.formId, JSON.stringify(x.content)));

            const cmd = new CreateOrUpdateIssue();
            cmd.flowIdentifier = this.availableService.flowIdentifier;
            cmd.issueId = this.availableService.currentIssuePendingToRequestId ?
                this.availableService.currentIssuePendingToRequestId : UUID.UUID();
            cmd.title = null;
            cmd.description = null;
            cmd.draftFormInstanceContents = content;

            return cmd;
        }));

    }

    private reloadComponent = ():void => {
        this.params.isStart = false;
        this.ngOnInit();
    }
}
