import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { KAutocompletePagedComponent } from './k-autocomplete-paged.component';
import { OptionsScrollDirective } from 'app/shared/directives/options-scroll.directive';
import { MaterialModule } from 'app/shared/core/material.module';
import { KAvatarModule } from '../k-avatar/k-avatar.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        KAvatarModule
    ],
    exports: [
        KAutocompletePagedComponent
    ],
    declarations: [
        KAutocompletePagedComponent,
        OptionsScrollDirective,
    ],
    providers: []
})
export class KAutocompletePagedModule {}
