import { Component, ViewChild, Input, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit } from "@angular/core";
import { AppointmentTemplateComponent } from "./template";
import { AppointemtMapping } from "./mapping";

@Component({
    selector: 'mydrake-card-appointment-template',
    template: '<div class="card-appointment-template-type" style="height: 100%"><div #cardcontainer></div></div>'
})
export class AppointmentCardTemplateFactoryComponent implements OnInit, OnDestroy {

    @ViewChild('cardcontainer', { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    @Input()
    public type: string;

    @Input()
    public context: any;

    private componentRef: ComponentRef<{}>;

    constructor(private factoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        if (this.type) {
            let componentType = AppointemtMapping.getCard(this.type);
            let factory = this.factoryResolver.resolveComponentFactory(componentType);
            this.componentRef = this.container.createComponent(factory);
            let instance = <AppointmentTemplateComponent>this.componentRef.instance;
            instance.context = this.context;
        }
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
