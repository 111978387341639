import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Gender, TelephoneType, WorkerCategoryType, WorkerStatus, WorkerType } from "@mydrake/domain/enums/core";
import { AddressView, ProfileView, TelephoneView } from "@mydrake/domain/read/views/core";
import { TenantView } from "@mydrake/domain/read/views/core/tenant.view";
import { environment } from "@mydrake/environments/environment";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { TenantResolver } from "./app.tenant";

@Injectable()
export class UserResolver {
    private _currentUser: ProfileView;

    constructor(private http: HttpClient, private tenantResolver: TenantResolver){}

    private resolve = (): Observable<ProfileView> => {
        return this.tenantResolver.currentTenantId().pipe(
            switchMap(tenantId => {
                if (this._currentUser?.tenants.find(x => x.current).id !== tenantId) {
                    return this.http.get(`${environment.apiUrl}My/Profile?tenantId=${tenantId}`)
                        .pipe(
                            map(this.build),
                            tap(currentUser => this._currentUser = currentUser))
                }

                return of(this._currentUser);
            }));
    }

    public currentUser = (): Observable<ProfileView> =>
        this._currentUser ? of(this._currentUser) : this.resolve();


    private build = (json: any) : ProfileView  => {
        let worker = new ProfileView();

        worker.avatar = json.avatar
        worker.name = json.name;
        worker.job = json.job;
        worker.registration = json.registration;
        worker.gender = <Gender>json.gender;
        worker.bloodType = json.bloodType;
        worker.category = <WorkerCategoryType>json.category;
        worker.sispat = json.sispat;
        worker.cpf = json.cpf;
        worker.status = <WorkerStatus>json.status;
        worker.type = <WorkerType>json.type;
        worker.isManager = json.isManager;
        worker.admissionDate = json.admissionDate;
        worker.organizationalUnit = json.organizationalUnit;
        worker.company = json.company;
        worker.language = json.language;
        worker.emails = json.emails.map(e => e.email);

        worker.addresses = json.addresses.map(a => {
            let address = new AddressView();

            address.streetAddress = a.streetAddress;
            address.number = a.number;
            address.addressComplement = a.addressComplement;
            address.district = a.district;
            address.city = a.city;
            address.state = a.state;
            address.country = a.country;
            address.zipCode = a.zipCode;

            return address;
        });

        worker.telephones = json.telephones.map(t => {
            let telephone = new TelephoneView();

            telephone.country = t.countryCode;
            telephone.code = t.areaCode;
            telephone.number = t.number;
            telephone.type = <TelephoneType>t.type;

            return telephone;
        });

        worker.tenants = json.tenants.map(t => {
            let tenant = new TenantView();

            tenant.id = t.id;
            tenant.name = t.name;
            tenant.current = t.current;
            tenant.hasDrakeAccess = t.hasDrakeAccess;
            tenant.hasMyDrakeAccess = t.hasMyDrakeAccess;

            return tenant;
        });

        return worker;
    }
}
