import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KPasswordStrengthComponent } from './k-password-strength.component';
import { MaterialModule } from 'app/shared/core/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        TranslateModule
    ],
    exports: [
        KPasswordStrengthComponent
    ],
    declarations: [
        KPasswordStrengthComponent
    ],
})
export class KPasswordStrengthModule { }
