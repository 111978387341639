import { Parameter } from "@shared/models";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "@mydrake/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class ReportService {
    constructor(private http: HttpClient) {}

    public getReportParameters(): Observable<Parameter[]> {
        return this.http
            .get(environment.apiUrl + "My/Reports/GetReportParamaters")
            .pipe(map(res => res as Parameter[]));
    }
}
