
import { map } from 'rxjs/operators';

//Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

//RxJS
import { Observable } from 'rxjs/Observable';

//Services and Providers
import { environment } from 'environments/environment';

//Domain
import { InboxFilterTag, InboxOrderProperty } from '@mydrake/domain/read/models/core';
import { InboxDetailModel } from '@mydrake/domain/read/models/core/inbox-detail.model';
import { InboxTransitionPropertyModel } from '@mydrake/domain/read/models/core/inbox-transition-property.model';

@Injectable()
export class InboxApi {

    constructor(private _http: HttpClient) { }

    /**
     * Retorna as mensagens de inbox existentes para o usuário;
     *
     * @param page : página de resultados;
     * @param limit : número de registros por página;
     * @param textSearch : filtro de pesquisa;
     */
    public list(page: number, limit: number, textSearch: string, descending: boolean,
        inboxFilterTag: InboxFilterTag, inboxOrderProperty: InboxOrderProperty): Observable<any> {

        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());
        if (textSearch) { params = params.append('textSearch', textSearch); }
        params = params.append('descending', descending.toString());
        if (inboxOrderProperty) { params = params.append('inboxOrderProperty', inboxOrderProperty.toString()); }
        if (inboxFilterTag) { params = params.append('inboxFilterTag', inboxFilterTag.toString()); }

        return this._http.get(environment.apiUrl + 'Integration/Inbox/List', { params: params });
    }

    /**
     * Retorna o conteúdo de uma mensagem de INBOX
     *
     * @param id : identificador da entidade no fluxo;
     */
    public getDetails(id: string): Observable<InboxDetailModel> {

        let params = new HttpParams();

        params = params.append('workflowEntityId', id);

        return this._http.get(environment.apiUrl + "Integration/Inbox/GetDetails", { params: params }).pipe(
            map(data => {
                let retval = data as InboxDetailModel;
                return retval;
            }));
    }

    /**
     * Realiza a transição do fluxo informada
     *
     * @param transition : Chave da transição
     * @param flowType   : tipo de fluxo
     * @param domainId   : identificador do inbox
     * @param fields     : argumentos adicionais
     */
    public fireTransition(transition: string, flowType: string, domainId: string, fields: Array<InboxTransitionPropertyModel>, geolocateThisAction: boolean = false, onSuccess: (data) => void = null, onError: (err) => void = null, onComplete: () => void = null, linkedFormInstanceContents: any = null): void {

        const command = {
            transition: transition,
            flowType: flowType,
            domainId: domainId,
            linkedFormInstanceContents: linkedFormInstanceContents,
            fields: fields.map(function (x) {
                return { key: x.name, value: x.value };
            }),
            arguments: {}
        };

        if (geolocateThisAction === true) {

            if (navigator.geolocation) {

                navigator.geolocation.getCurrentPosition(
                    (position: any) => {
                        command.arguments = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                        this.executeFireTransition(command, onSuccess, onError, onComplete);
                    },
                    (error: any) => {
                        console.error(error);
                        this.executeFireTransition(command, onSuccess, onError, onComplete);
                    }
                );

            } else {
                console.error('Geolocation API not supported.');
                this.executeFireTransition(command, onSuccess, onError, onComplete);
            }

        } else {
            this.executeFireTransition(command, onSuccess, onError, onComplete);
        }
    }

    private executeFireTransition(command: any, onSuccess: (data) => void, onError: (err) => void, onComplete: () => void): void {
        this._http.post(environment.apiUrl + 'Integration/Inbox/FireTransition', command).subscribe(onSuccess, onError, onComplete);
    }
}
