import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import {
    IWorkerDocument,
    DocumentOrderProperty,
    GroupType,
    DocumentsAndFoldersResult,
    DocumentFilterTag
} from '@mydrake/domain/read/models/documents';
import { environment } from '@mydrake/environments/environment';

@Injectable()
export class DocumentsService {
    constructor(private http: HttpClient) {}

    public getWorkerDocuments(page: number, limit: number, descending: boolean,
        documentOrderProperty: DocumentOrderProperty, documentFilterTag?: DocumentFilterTag, textSearch?: string,
        folder?: string): Observable<DocumentsAndFoldersResult> {
        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());
        params = params.append('descending', descending.toString());
        params = params.append('documentOrderProperty', documentOrderProperty.toString());
        if (documentFilterTag) {
            params = params.append('documentFilterTag', documentFilterTag);
        }
        if (textSearch) {
            params = params.append('textSearch', textSearch);
        }
        if (folder) {
            params = params.append('folder', folder);
        }

        return this.http.get(environment.apiUrl + 'My/Documents/', { params: params })
            .pipe(
                map((res: any) => ({
                    workerDocuments: res.documentsPagedResult.items,
                    folders: res.folders,
                    groupTypes: res.groupTypes
                })));
            ;
    }

    public getWorkerDocumentsInfoLegacy(ids: string[], groupType?: string): Observable<any[]> {
        return this.http.get(environment.apiUrl + 'My/Documents/Document', { params: { ids: ids, groupType: groupType.toString() } }).pipe(
            map((res: any[]) => res));
    }

    public getOlderDocumentsByQualification(qualificationId: string): Observable<any[]> {
        return this.http.get(environment.apiUrl + 'My/Documents/OlderDocumentsByQualification', { params: { qualificationId: qualificationId } })
            .pipe(map((res: any[]) => res));
    }

    public getOlderDocumentsByDocumentTypeWellKnownId(documentTypeWellKnownId: string): Observable<any[]> {
        return this.http.get(environment.apiUrl + 'My/Documents/OlderDocumentsByDocumentTypeWellKnownId', { params: { documentTypeWellKnownId: documentTypeWellKnownId } })
            .pipe(map((res: any[]) => res));
    }

    public prepareDownloadFile(folder: string): Observable<string> {
        const cmd = {
            folder: folder
        };

        return this.http.post<string>(environment.apiUrl + 'My/Documents/PrepareDownloadFile', cmd);
    }
}
