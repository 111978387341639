import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@mydrake/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AvailableService } from '@mydrake/domain/read/models/request-center';
import { FormInstancesAndDefinitionsModel } from '@mydrake/domain/read/models/request-center/form-instances-and-definitions.model';
import { FlowLinkedFormModel } from '@mydrake/domain/read/models/request-center/flow-linked-form.model';
import { CreateOrUpdateIssue } from '@mydrake/domain/write/commands/request-center/create-or-update-issue.command';
import { RequestService } from '@mydrake/domain/write/commands/request-center/request-service.command';
import {
    DeleteIssueAndDraftFormInstances
} from '@mydrake/domain/write/commands/request-center/delete-issue-and-draft-form-instances.command';

@Injectable()
export class RequestCenterService {

    constructor(private http: HttpClient) { }


    public getAvailableServices(textSearch?: string): Observable<AvailableService[]> {
        let params = new HttpParams();

        if (textSearch) {
            params = params.append('textSearch', textSearch);
        }

        return this.http.get(environment.apiUrl + 'My/RequestCenter/AvailableServices', { params })
            .pipe(map(res => res as AvailableService[]));
    }

    public getDraftFormInstancesAndDefinitions(issueId: string): Observable<FormInstancesAndDefinitionsModel> {
        return this.http.get<FormInstancesAndDefinitionsModel>(environment.apiUrl +
            'My/RequestCenter/DraftFormInstancesAndDefinitions/' + issueId);
    }

    public getInitialFormDefinitions(flowIdentifier: string): Observable<FlowLinkedFormModel[]> {
        return this.http.get<FlowLinkedFormModel[]>(environment.apiUrl +
            'My/RequestCenter/InitialFormDefinitions/' + flowIdentifier);
    }

    public request(cmd: RequestService) {
        return this.http.post(environment.apiUrl + 'My/RequestCenter/Request', cmd);
    }

    public createOrUpdateIssue(cmd: CreateOrUpdateIssue) {
        return this.http.post(environment.apiUrl + 'My/RequestCenter/CreateOrUpdateIssue', cmd)
    }

    public deleteIssue(cmd: DeleteIssueAndDraftFormInstances) {
        return this.http.post(environment.apiUrl + 'My/RequestCenter/DeleteIssue', cmd)
    }

}
