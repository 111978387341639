<k-dialog title="{{ 'INBOX.COMMON.TRANSITION_DIALOG_TITLE' | translate }}">

    <k-dialog-content fxLayout="column">
        <div *ngFor="let field of fields">
            <k-field-render [prop]="field"></k-field-render>
        </div>

        <div *ngFor="let linkedForm of linkedFormInstances | orderBy: 'index'" layout="column">
            <span class="linked-form-name">{{linkedForm.form.name}}</span>
            <div class="bootstrap">
                <k-formio [formDefinition]="linkedForm.form.definition" [readOnly]="linkedForm.readOnly" (formInstanceContentChange)="changeFormInstance($event)"></k-formio>
            </div>
        </div>
    </k-dialog-content>

    <k-dialog-footer>
        <button fxFlex mat-button [mat-dialog-close]="false" class="cancel">{{ 'COMMON.CANCEL' | translate }}</button>
        <button fxFlex mat-button [mat-dialog-close]="true"
            [disabled]="!isValid()">{{ 'COMMON.CONFIRM' | translate }}</button>
    </k-dialog-footer>

</k-dialog>
