<div class="card">

    <div class="approved-or-reproved" *ngIf="approved || reproved">

        <div class="thumbnail" [ngClass]="{ 'reprove': reproved }">
            <i *ngIf="approved" class="icon-mydrake-basic-elaboration-todolist-check"></i>
            <i *ngIf="reproved" class="icon-mydrake-basic-elaboration-todolist-noaccess"></i>
        </div>

        <div class="message" *ngIf="approved">
            <span>{{ 'INBOX.ONLINE_TRAINING.MESSAGE_APPROVE' | translate }}</span>

            <div class="certificate-file-message received" *ngIf="certificateFileStatus === 'Received'">
                <span><mat-icon>school</mat-icon></span>
                <span><a href="{{ certificateLink }}" target="_blank">{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_RECEIVED_DOWNLOAD_IT' | translate }}</a></span>
            </div>

            <div class="certificate-file-message waiting-generation" *ngIf="certificateFileStatus === 'WaitingGeneration'">
                <span><mat-icon>hourglass_full</mat-icon></span>
                <span>{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_WAITING_GENERATION' | translate }}</span>
            </div>

            <div class="certificate-file-message error" *ngIf="certificateFileStatus === 'GenerationError'">
                <span><mat-icon>error</mat-icon></span>
                <span>{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_GENERATION_ERROR' | translate }}</span>
            </div>

        </div>

        <div class="message" *ngIf="reproved">
            <span>{{ 'INBOX.ONLINE_TRAINING.MESSAGE_REPROVE' | translate }}</span>
        </div>

        <div class="manual">
            <span *ngIf="manual">{{ 'INBOX.ONLINE_TRAINING.IS_MANUAL' | translate }}</span>
        </div>

    </div>

    <div class="done" *ngIf="done">

        <div class="thumbnail">
            <i class="icon-mydrake-basic-elaboration-todolist-check"></i>
        </div>

        <div class="message">
            <span>{{ 'INBOX.ONLINE_TRAINING.CONGRATULATIONS_YOU_VE_COMPLETED_THE_TRAINING' | translate }}</span>

            <div class="certificate-file-message received" *ngIf="certificateFileStatus === 'Received'">
                <span><mat-icon>school</mat-icon></span>
                <span><a href="{{ certificateLink }}" target="_blank">{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_RECEIVED_DOWNLOAD_IT' | translate }}</a></span>
            </div>

            <div class="certificate-file-message waiting-generation" *ngIf="certificateFileStatus === 'WaitingGeneration'">
                <span><mat-icon>hourglass_full</mat-icon></span>
                <span>{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_WAITING_GENERATION' | translate }}</span>
            </div>

            <div class="certificate-file-message error" *ngIf="certificateFileStatus === 'GenerationError'">
                <span><mat-icon>error</mat-icon></span>
                <span>{{ 'INBOX.ONLINE_TRAINING.CERTIFICATE_GENERATION_ERROR' | translate }}</span>
            </div>

        </div>

    </div>

</div>