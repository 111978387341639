<div class="mydrake-unknown-error-dialog">

    <i class="material-icons">cancel</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.LABELS.ERROR' | translate }}</h4>

        <p class="description">{{ message }}</p>

    </div>

</div>
