import {HttpClient} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";

import { MaterialModule } from "./shared/core//material.module";

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { MyDrakeRegistrationModule } from "./registration/registration.module";
import { MyDrakeCoreModule } from "./core/core.module";
import { MyDrakeSharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MyDrakeErrorHandler } from "./shared/core/handlers/error.handler";
import { VideoJSComponent } from './home/video.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';

import './formio-components/editForm/DomainSelect.edit.data';
import './formio-components/editForm/DomainSelect.edit.validation';
import './formio-components/DomainSelectForm';
import './formio-components/DomainSelect';
import {LocalTranslateHttpLoader} from './shared/providers/translate.service';
import { HomeApi } from './home/api/home.api';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        VideoJSComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MaterialModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        SignaturePadModule,
        CommonModule,
        InfiniteScrollModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: http => new LocalTranslateHttpLoader(http),
                deps: [HttpClient]
            },
            isolate: false
        }),
        MyDrakeSharedModule.forRoot(),
        MyDrakeCoreModule,
        MyDrakeRegistrationModule
    ],
    providers: [
        HomeApi,
        { provide: ErrorHandler, useClass: MyDrakeErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
