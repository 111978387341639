<div class="container" fxLayout="column" fxFlex>

    <div *ngFor="let linked of linkedFormInstances | orderBy:'index'">
        <p class="title" *ngIf="title">{{linked.form.name}}</p>
        <div class="bs-box">
            <div class="bootstrap">
                <k-formio 
                    [formDefinition]="linked.form.definition" 
                    [(formInstanceContent)]="linked.formInstanceContent" 
                    [readOnly]="linked.readOnly" 
                    #kformio>
                </k-formio>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" *ngIf="showButtons && mustShow" (click)="onFormSave()">{{'ACTIONS.SAVE_FORM'| translate}}</button>
    </div>
    <mat-card class="loading" *ngIf="loading">
        <mat-spinner class="spinner"></mat-spinner>
        <div class="message">{{ loadMessage }}</div>
    </mat-card>
</div>
