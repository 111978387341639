import {Component, Input} from '@angular/core';

@Component({
    selector: 'k-key-value',
    templateUrl: './k-key-value.component.html',
    styleUrls: ['./k-key-value.component.scss']
})
export class KKeyValueComponent {
    @Input()
    public key: string;

    @Input()
    public value: string;
}
