
<div class="signature-container">
    <mat-label>{{prop.title}}<span *ngIf="prop.required"> *</span></mat-label>
    
    <signature-pad [options]="signaturePadOptions" (onEndEvent)="onChange()"></signature-pad>
    <button mat-stroked-button type="button" (click)="clear()">{{ 'CLEAR_SIGNATURE' | translate }}</button>
</div>



