import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCenterComponent } from './request-center.component';
import { MyDrakeSharedModule } from '../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RequestCenterService } from './request-center.service';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { KIconModule } from '../../shared/components/k-icon/k-icon.module';
import { ConfirmRequestComponent } from './confirm-request/confirm-request.component';
import { RequestSubmittedComponent } from './request-submitted/request-submitted.component';
import {NgScrollbarModule} from 'ngx-scrollbar';

@NgModule({
    declarations: [
        RequestCenterComponent,
        RequestDetailsComponent,
        ConfirmRequestComponent,
        RequestSubmittedComponent
    ],
    imports: [
        CommonModule,
        MyDrakeSharedModule,
        FlexLayoutModule,
        InfiniteScrollModule,
        KIconModule,
        NgScrollbarModule
    ],
    exports: [
        RequestCenterComponent
    ],
    entryComponents: [
        RequestDetailsComponent,
        ConfirmRequestComponent,
        RequestSubmittedComponent
    ],
    providers: [RequestCenterService],
})
export class MyDrakeRequestCenterModule { }
