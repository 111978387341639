import { MyDrakeException } from '../exceptions/my-drake.exception';
import { ErrorHandler, Injector, Injectable } from "@angular/core";
import { KSnackBar } from "../services/k-snackbar.service";


@Injectable()
export class MyDrakeErrorHandler extends ErrorHandler {

    private _snackBar: KSnackBar;

    constructor(private _injector: Injector) {
        // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
        // when an error happens. If we do not rethrow, bootstrap will always succeed.
        super();
    }

    handleError(error) {

        // delegate to the default handler
        super.handleError(error);

        this._snackBar = this._injector.get(KSnackBar);

        if(error.error){
            this._snackBar.exception(new MyDrakeException(error.error.Key, error.error.Message));

        }else {
            this._snackBar.exception(error);
        }
    }
}
