import { map } from "rxjs/operators";

// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

// RxJS
import { Observable } from "rxjs";

// Services and Providers
import { environment } from "environments/environment";
import { AdditionalEventConfig } from "@mydrake/domain/read/models/additional-events/additional-event-config.model";
import { AdditionalEvent } from "@mydrake/domain/read/models/additional-events/additional-event.model";
import { Combo } from "@shared/models";
import {
    CreateAdditionalEventCommand,
    ChangeAdditionalEventCommand
} from "@mydrake/domain/write/commands/additional-event/additional-event.command";

@Injectable()
export class AdditionalEventsApi {
    constructor(private http: HttpClient) {}

    public getConfig(): Observable<AdditionalEventConfig> {
        return this.http
            .get(environment.apiUrl + "My/AdditionalEvents/GetConfig")
            .pipe(map(data => data as AdditionalEventConfig));
    }

    public getOccurrences(textSearch: string): Observable<Array<Combo>> {
        let params = new HttpParams();

        if (textSearch) {
            params = params.append("textSearch", textSearch);
        }

        return this.http.get<Array<Combo>>(
            environment.apiUrl + "My/AdditionalEvents/GetOccurrences",
            { params: params }
        );
    }

    public getAdditionalEvent(id: string) {
        let params = new HttpParams();
        params = params.append("id", id);

        return this.http.get(environment.apiUrl + "My/AdditionalEvents/Get", {
            params: params
        });
    }

    public getAdditionalEvents(
        page: number,
        limit: number,
        textSearch: string
    ): Observable<Array<AdditionalEvent>> {
        let params = new HttpParams();

        params = params.append("page", page.toString());
        params = params.append("limit", limit.toString());

        if (textSearch) {
            params = params.append("textSearch", textSearch);
        }

        return this.http
            .get(environment.apiUrl + "My/AdditionalEvents/List", {
                params: params
            })
            .pipe(
                map((data: any) =>
                    data.items.map(event => event as AdditionalEvent)
                )
            );
    }

    public remove(ids: Array<string>): Observable<any> {
        let params = new HttpParams();
        ids.forEach(id => (params = params.append("ids", id)));

        return this.http.delete(environment.apiUrl + "My/AdditionalEvents", {
            params: params
        });
    }

    public save(
        cmd: CreateAdditionalEventCommand | ChangeAdditionalEventCommand
    ): Observable<Object> {
        if (cmd instanceof CreateAdditionalEventCommand) {
            return this.http.post(
                environment.apiUrl + "My/AdditionalEvents",
                cmd
            );
        }

        if (cmd instanceof ChangeAdditionalEventCommand) {
            return this.http.put(
                environment.apiUrl + "My/AdditionalEvents",
                cmd
            );
        }
    }
}
