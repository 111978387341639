<div style="padding: 10px 35px;">

    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="{{ 'MENU.VACATIONS' | translate }}">

            <ng-scrollbar [autoHeightDisabled]="false">
                <div id="vacations" autoresize [fix]="150" fxLayout="column">
                    <div class="item" fxLayout="row" fxLayoutAlign="start center" *ngFor="let vacation of vacations">
                        <div class="vacation-days">{{ vacation.getDays() }} {{'COMMON.DAYS' | translate }}</div>
                        <div class="range">
                            <k-date-viewer [date]="vacation.start"></k-date-viewer>
                            <div class="to">{{ 'COMMON.TO' | translate }}</div>
                            <k-date-viewer [date]="vacation.end"></k-date-viewer>
                        </div>
                        <div fxLayout="column" fxFlex *ngIf="!vacation.vacationRequestIdIsEmpty()">
                            <div class="label" *ngIf="vacation.aquisitivePeriodId">{{ 'VACATION.AQUISITIVE_PERIOD' | translate }}</div>
                            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="vacation.aquisitivePeriodId">
                                <div class="value">{{vacation.acquisitivePeriodStart | i18nDate: 'date'}}</div>
                                <div class="to">{{ 'COMMON.TO' | translate }}</div>
                                <div class="value">{{vacation.acquisitivePeriodEnd | i18nDate: 'date'}}</div>
                            </div>
                        </div>
                        <div class="status" fxLayout="row" fxLayoutAlign="end center">
                            <div class="confirmed" *ngIf="vacation.isConfirmed">{{ 'VACATION.CONFIRMED' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
        </mat-tab>

        <mat-tab label="{{ 'MENU.VACATION_REQUEST' | translate }}">
            <div *ngIf="selectedTabIndex == 1">
                <ng-scrollbar [autoHeightDisabled]="false">
                    <div style="padding: 25px 5px 0px 5px; height: 100%;" autoresize [fix]="150">
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let request of requests">
                                <mat-expansion-panel-header style="height: 56px !important; padding: 30px 15px;">
                                    <mat-panel-title>
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

                                            <!-- AQUISITIVE PERIOD -->
                                            <div style="padding-right: 30px">
                                                <div class="label">{{ 'VACATION.AQUISITIVE_PERIOD' | translate }}</div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <span>{{request.aquisitiveStart | i18nDate: 'date'}}</span>
                                                    <span class="to">{{ 'COMMON.TO' | translate }}</span>
                                                    <span>{{request.aquisitiveEnd | i18nDate: 'date'}}</span>
                                                </div>
                                            </div>
                                            <!-- AQUISITIVE PERIOD -->

                                            <!-- CONCESSION PERIOD -->
                                            <div>
                                                <div class="label">{{ 'VACATION.CONCESSION_PERIOD' | translate }}</div>
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <span>{{request.concessionStart | i18nDate: 'date'}}</span>
                                                    <span class="to">{{ 'COMMON.TO' | translate }}</span>
                                                    <span>{{request.concessionEnd | i18nDate: 'date'}}</span>
                                                </div>
                                            </div>
                                            <!-- CONCESSION PERIOD -->

                                            <div fxFlex></div>

                                            <!-- STATUS -->
                                            <div>
                                                <div class="status">{{ request.flowCurrentState }}</div>
                                            </div>
                                            <!-- STATUS -->

                                        </div>
                                    </mat-panel-title>
                                    <mat-panel-description>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <div class="not-found" *ngIf="request.status == 'Draft'">
                                    <div class="thumbnail">
                                        <i class="icon-mydrake-vacation"></i>
                                    </div>
                                    <div class="message">{{ 'VACATION.NO_REQUESTED_VACATION_YET' | translate }}</div>
                                </div>

                                <div class="box" *ngIf="request.status != 'Draft'">

                                    <!-- FÉRIAS VENDIDAS -->
                                    <div class="separeted" *ngIf="request.howManyDaysToSell > 0">
                                        <span
                                            class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.SELL' | translate }}</span>
                                        <span class="answer">{{ request.howManyDaysToSell > 0 | yesOrNo }}</span>
                                        <span class="answer" style="margin-left: 0;"
                                            *ngIf="request.howManyDaysToSell > 0">, {{ request.howManyDaysToSell }}
                                            {{ 'COMMON.DAYS' | translate }}</span>
                                    </div>
                                    <!-- FÉRIAS VENDIDAS -->

                                    <!-- 13º SALARIO -->
                                    <div class="separeted">
                                        <span
                                            class="question">{{ 'INBOX.VACATION_REQUEST.COMMON.RECEIVE_EARLY_BONUS' | translate }}</span>
                                        <span class="answer">{{ request.receiveBonusEarly | yesOrNo }}</span>
                                    </div>
                                    <!-- 13º SALARIO -->

                                    <div fxLayout="row" *ngIf="request.workerCategory == 'OffShore'">
                                        <div fxFlex="50" style="border-right: 1px solid #ddd">
                                            <span
                                                class="question">{{ 'VACATION.VACATION_PRIORIZATION' | translate }}:</span>
                                            <ul style="list-style: none; padding-left: 25px">
                                                <li
                                                    *ngFor="let suggestion of request.fruitionSuggestions | orderBy:'priority'">
                                                    <div fxLayout="row" fxLayoutAlign="start center" class="question">
                                                        <div class="bullet">{{ suggestion.priority }}</div>
                                                        <span class="">{{suggestion.start | i18nDate:'date'}}</span>
                                                        <span class="to">{{ 'COMMON.TO' | translate }}</span>
                                                        <span class="">{{suggestion.end | i18nDate:'date'}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div fxFlex="50" style="padding-left: 20px">
                                            <div class="question">{{ 'VACATION.SCHEDULED_VACATION' | translate }}:</div>
                                            <ul>
                                                <li *ngFor="let vacation of request.vacations">
                                                    <div class="question">
                                                        <span class="">{{vacation.start | i18nDate:'date'}}</span>
                                                        <span class="to">{{ 'COMMON.TO' | translate }}</span>
                                                        <span class="">{{vacation.end | i18nDate:'date'}}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="answer" *ngIf="request.vacations.length == 0">
                                                {{ 'VACATION.NO_VACATION_SCHEDULED_SO_FAR' | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </ng-scrollbar>>
            </div>

        </mat-tab>
    </mat-tab-group>


</div>
