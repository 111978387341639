<div class="card">
    <mat-tab-group *ngIf="showResourceFiles && hasAnyResourceStepWithDocuments">
        <mat-tab label="{{ 'INBOX.ONLINE_TRAINING.RESULT' | translate }}">
            <mydrake-online-training-final-result [domainEntity]="domainEntity"></mydrake-online-training-final-result>
        </mat-tab>
        <mat-tab *ngFor="let content of contents" label="{{'INBOX.ONLINE_TRAINING.CONTENT_DOCUMENTS'| translate: { culture: content.culture } }}">
            <mydrake-online-resource-step-documents [steps]="content.steps"></mydrake-online-resource-step-documents>
        </mat-tab>
    </mat-tab-group>
    <mydrake-online-training-final-result [domainEntity]="domainEntity" *ngIf="!showResourceFiles || !hasAnyResourceStepWithDocuments"></mydrake-online-training-final-result>
</div>
