<mat-form-field *ngIf="!isMultipleSelect">
    <input matInput
           placeholder="{{prop.title}}"
           [required]="prop.required"
           [formControl]="domainControl"
           [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onAutoCompleteSelect($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option[option.customDomainTextField] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field *ngIf="isMultipleSelect">
    <mat-label>{{ prop.title }}</mat-label>
    <mat-select multiple (selectionChange)="onMultipleSelect($event)">
      <mat-option *ngFor="let option of options" [value]="option">
          {{ option[option.customDomainTextField] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
