import { Injectable } from '@angular/core';
import { environment } from '@mydrake/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { QualificationNeedsContextDetails } from '@mydrake/domain/read/models/qualification-needs/worker-qualification-needs';
import { Observable } from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {
    WorkerQualificationNeeds,
    QualificationNeedsFilterTag,
    QualificationNeedsOrderProperty
} from '@mydrake/domain/read/models/qualification-needs';

@Injectable()
export class QualificationNeedsService {
    constructor(private http: HttpClient) {
    }

    public getQualificationNeed(page: number, limit: number, textSearch?: string, descending?: boolean,
        qualificationNeedsOrderProperty?: QualificationNeedsOrderProperty,
        qualificationNeedsFilterTag?: QualificationNeedsFilterTag): Observable<WorkerQualificationNeeds[]> {
        let params = new HttpParams();

        params = params.append('page', page.toString());
        params = params.append('limit', limit.toString());
        if (textSearch) {
            params = params.append('textSearch', textSearch);
        }
        if (descending) {
            params = params.append('descending', descending.toString());
        }
        if (qualificationNeedsOrderProperty) {
            params = params.append('qualificationNeedsOrderProperty', qualificationNeedsOrderProperty);
        }
        if (qualificationNeedsFilterTag) {
            params = params.append('qualificationNeedsFilterTag', qualificationNeedsFilterTag);
        }

        return this.http.get(environment.apiUrl + 'My/QualificationNeeds/', { params: params })
        .pipe(map((res: any) => res.items.map((q: WorkerQualificationNeeds[]) => q as WorkerQualificationNeeds[])));
    }

    public getQualificationNeedDetails(id: string): Observable<QualificationNeedsContextDetails> {
        return this.http.get(environment.apiUrl + 'My/QualificationNeeds/Details/' + id).pipe(
            map(res => res as QualificationNeedsContextDetails));
    }
}
