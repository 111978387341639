import { Component, OnInit, ViewChild, OnDestroy, EventEmitter } from '@angular/core';
import { AppointmentService } from "./appointment.service";
import { MatSidenav } from "@angular/material/sidenav";
import { MyDrakeEventEmitter } from '@shared/services';
import { AppointmentView } from '@mydrake/domain/read/views/core';
import { Constants } from '../../../shared/core/constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mydrake-appointments',
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.scss']
})
export class AppointmentListComponent implements OnDestroy {

    public loaded: boolean = false;
    public appointments: Array<AppointmentView> = [];
    public future : boolean = true;
    public selected : AppointmentView = new AppointmentView();

    private page : number = 1;
    private limit : number = 10;
    private subscription : Subscription;
    private textSearch : string;
    private appointmentType : any;


    @ViewChild('appointmentDetailSideNav', { static: true }) detail: MatSidenav;
    @ViewChild('searchFilterSideNav', { static: true }) filter: MatSidenav;

    constructor(private _api : AppointmentService, private _emitter : MyDrakeEventEmitter) {
        this.appointments = [];
        this.onNextAppointments();
        this.subscription = this._emitter.get(Constants.EventKeys.SearchTextChange).subscribe(text => this.onSearchListener(text));
    }

    public onSearchListener(textSearch : string){

        this.textSearch = textSearch;

        if(this.future){
            this.onNextAppointments();
        } else {
            this.onLastAppointments();
        }
    }

    /**
     * Exibe os compromissos antigos do trabalhador em questão;
     */
    public onLastAppointments() : void {
        this.future = false;
        this.page = 1;
        this._api.getLastAppointments(this.page, this.limit, this.textSearch, this.appointmentType).subscribe(data => {
            this.loaded = true;
            this.appointments = data;
        });
    }

    /**
     * Exibe os compromissos futuros do trabalhador em questão;
     */
    public onNextAppointments() : void {
        this.future = true;
        this.page = 1;
        this._api.getNextAppointments(this.page, this.limit, this.textSearch, this.appointmentType).subscribe(data => {
            this.loaded = true;
            this.appointments = data;
        });
    }

    /**
     * Seleciona um compromisso da lista para ser exibido o detalhamento;
     *
     * @param appointment: compromisso a ser detalhado;
     */
    public onSelectAppointment(appointment : any) : void {
        this.selected = appointment;
        this.filter.close();
        this.detail.close();
        this.detail.open();
    }

    /**
     * Abre o painel lateral de filtros;
     */
    public onOpenFilters() : void{
        this.detail.close();
        this.filter.open();
    }

    public onCloseDetail() {
        this.detail.close();
    }

    public onScrollDown () {

        this.loaded = false;

        if(this.appointments.length < this.page * this.limit){
            return;
        }

	    if(this.future){
            this.page++;
            this._api.getNextAppointments(this.page, this.limit, this.textSearch, this.appointmentType).subscribe(data => {
                for (var index = 0; index < data.length; index++) {
                    this.appointments.push(data[index]);
                }
                this.loaded = true;
            });
        }
        else {
            this.page++;
            this._api.getLastAppointments(this.page, this.limit, this.textSearch, this.appointmentType).subscribe(data => {
                for (var index = 0; index < data.length; index++) {
                    this.appointments.push(data[index]);
                }
                this.loaded = true;
            });
        }
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
