import {Component, OnInit} from '@angular/core';
import {FieldRenderTemplate} from '../field-render-template';

// Vendor
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'k-boolean-field',
    templateUrl: './k-boolean-field.component.html',
    styleUrls: ['./k-boolean-field.component.scss']
})

export class KBooleanFieldComponent extends FieldRenderTemplate implements OnInit {

    constructor(private translateService: TranslateService) {
        super();
    }

    ngOnInit () {
        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.prop.value = false;
            this.prop.isValid = true;
        });

        // Isso aqui é para manter a compatibilidade com os fluxos que já existem hoje... =/
        if (this.prop.title === 'CONFIRM_DOWNLOAD_ATTACHMENTS')
        {
            this.translateService.get('INBOX.ONLINE_TRAINING.' + this.prop.title).subscribe((response: string) => {
                this.prop.title = response;
            });
        }
    }
}
