<mat-sidenav-container>

    <div style="padding: 0px 15px;">

        <!-- BREADCRUMB -->
        <k-breadcrumb label="{{ 'MENU.INBOX' | translate }}"></k-breadcrumb>
        <!--/ BREADCRUMB -->

        <section autoresize [fix]="165" *ngIf="items.length > 0">
            <ng-scrollbar [autoHeightDisabled]="false">
                <div scrollViewport infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="10" [infiniteScrollDisabled]="anyInboxOpened" [scrollWindow]="false" (scrolled)="onScrollDown()">
                    <div style="padding: 0px 25px; height: 100%" autoresize [fix]="125">
                        <mat-accordion>
                            <div *ngFor="let group of groups" style="margin-bottom: 10px">

                                <div style="font-size: 12px; text-transform: uppercase; font-weight: 500; color: #757575; padding: 10px 15px;">{{ group.key | translate }}</div>

                                <mat-expansion-panel hideToggle="false" *ngFor="let message of group.value" [expanded]="message.opened" >

                                    <mat-expansion-panel-header style="height: 56px !important; padding: 30px 15px;" (click)="openMessage(message)" [ngStyle]="{'border-left': (message.content.unreadItem) ? '#0747a6 solid 6px' : '#f2f2f2 solid 6px'}">
                                        <mat-panel-title>
                                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex >
                                                <inbox-icon-type [type]="message.content.flowType" *ngIf="!message.content.imageIconId && message.content.flowType !== 'Issue'" style="margin: 10px 15px 0 0px; font-size: 36px">
                                                </inbox-icon-type>
                                                <k-icon *ngIf="message.content.imageIconId || message.content.flowType === 'Issue'" [iconId]="message.content.imageIconId" [name]="message.content.title" type="circle" size="40px" style="margin-right: 10px; padding: 2px;"></k-icon>
                                                <div fxFlex>
                                                    <div style="text-transform: uppercase; font-size: 12px; margin: 0px 0px 4px 0px;"
                                                         [ngStyle]=" {'font-weight': (message.content.unreadItem)  ? '900' : '600'}">
                                                        {{message.content.title}}</div>
                                                    <div style="font-style: italic; font-size: 12px; color: #757575;"  [ngStyle]="(message.content.unreadItem) && {'font-weight': '900'}">
                                                        {{message.content.description}}</div>
                                                </div>

                                                <button fxLayout="row" fxLayoutAlign="end center" mat-icon-button (click)="onMarkAsUnread($event, message)" matTooltip="{{ 'MARK_AS_NOT_READ' | translate }}"
                                                        style="padding-right: 8px"
                                                        *ngIf="message.opened && !message.content.unreadItem"><i class="material-icons" style="color: #757575;">mark_as_unread</i></button>

                                                <div class="status">
                                                    <k-badge [prop]="message.content.currentStateProperties">
                                                        {{ message.content.currentStateName }}</k-badge>
                                                </div>
                                                <div style="width: 30px; padding-left: 20px;">
                                                    <i *ngIf="message.content.processed" class="material-icons processed">done</i>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                        <mat-panel-description></mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="box" *ngIf="!message.content.completed">
                                        <mat-spinner></mat-spinner>
                                    </div>

                                    <div fxLayout="column" *ngIf="message.content.completed">
                                        <inbox-template [type]="message.content.flowType" [inboxId]="message.id" [context]="message.content.context" [domainId]="message.content.domainEntityId" style="width:100%"></inbox-template>
                                    </div>

                                </mat-expansion-panel>

                            </div>
                        </mat-accordion>
                    </div>
                </div>
            </ng-scrollbar>
        </section>

        <section *ngIf="loaded && items.length == 0">

            <div style="padding: 0px 15px; height: calc(100vh - 125px);" fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -60px">
                    <i class="icon-mydrake-basic-message-multiple" style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
                    <div style="padding: 20px 0 5px 0;
                font-weight: 400;
                font-size: 23px;
                color: #757575;">Nenhuma mensagem encontrada!</div>
                    <div style="font-style: italic;
                font-size: 14px; color: #757575;">Utilize os filtros de pesquisa para melhorar sua consulta.</div>
                </div>
            </div>

        </section>

    </div>

</mat-sidenav-container>
