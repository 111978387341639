<div class="card-step">

    <!-- HEADER -->
    <div class="card-step-header">
        <div class="type">{{ step.type.toUpperCase() | translate }}</div>
        <div class="separator"></div>
        <div class="title">{{ step.title }}</div>
    </div>
    <!--/ HEADER -->

    <!-- CONTENT -->
    <div class="card-step-content">
        <div class="description">{{ step.description }}</div>
    </div>
    <!--/ CONTENT -->

    <!-- FOOTER -->
    <div class="card-step-footer">
        <div class="scout">
            <mat-icon>attach_file</mat-icon>
            <label>{{ step.documents.length }} {{'ATTACHMENTS' | translate}}</label>
        </div>
    </div>
    <!--/ FOOTER -->

    <div *ngIf="step.documents && step.documents.length > 0">
        <k-document-view [documents]="step.documents" mode="grid"></k-document-view>
    </div>

    <div class="step-toolbar-transition">
        <mydrake-inbox-transition [transitions]="context.availableTransitions" [flowType]="context.flowType" [domainId]="domainId" [inboxId]="inboxId"></mydrake-inbox-transition>
    </div>

</div>
