export interface IScrollOptions {
    position?: string;
    barBackground?: string;
    barOpacity?: string;
    barWidth?: string;
    barBorderRadius?: string;
    barMargin?: string;
    gridBackground?: string;
    gridOpacity?: string;
    gridWidth?: string;
    gridBorderRadius?: string;
    gridMargin?: string;
    alwaysVisible?: boolean;
    visibleTimeout?: number;
  }

  export class ScrollOptions implements IScrollOptions {
    position?: string;
    barBackground?: string;
    barOpacity?: string;
    barWidth?: string;
    barBorderRadius?: string;
    barMargin?: string;
    gridBackground?: string;
    gridOpacity?: string;
    gridWidth?: string;
    gridBorderRadius?: string;
    gridMargin?: string;
    alwaysVisible?: boolean;
    visibleTimeout?: number;

    constructor(obj?: IScrollOptions) {
      this.position = obj && obj.position ? obj.position : 'right';
      this.barBackground = obj && obj.barBackground ? obj.barBackground : '#C9C9C9';
      this.barOpacity = obj && obj.barOpacity ? obj.barOpacity : '1';
      this.barWidth = obj && obj.barWidth ? obj.barWidth : '8';
      this.barBorderRadius = obj && obj.barBorderRadius ? obj.barBorderRadius : '10';
      this.barMargin = obj && obj.barMargin ? obj.barMargin : '6px 8px';
      this.gridBackground = obj && obj.gridBackground ? obj.gridBackground : 'rgba(217, 217, 217, 0.45)';
      this.gridOpacity = obj && obj.gridOpacity ? obj.gridOpacity : '1';
      this.gridWidth = obj && obj.gridWidth ? obj.gridWidth : '4';
      this.gridBorderRadius = obj && obj.gridBorderRadius ? obj.gridBorderRadius : '10';
      this.gridMargin = obj && obj.gridMargin ? obj.gridMargin : '1px 2px';
      this.alwaysVisible = obj && typeof obj.alwaysVisible !== 'undefined' ? obj.alwaysVisible : true;
      this.visibleTimeout = obj && obj.visibleTimeout ? obj.visibleTimeout : 1000;
    }
  }
