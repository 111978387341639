import { Component, OnInit} from "@angular/core";
import { TemplateComponent } from "../template";

@Component({
    selector: 'vacation-request',
    templateUrl: 'vacation-request.component.html',
    styleUrls: ['./vacation-request.component.scss']
})
export class VacationRequestComponent extends TemplateComponent implements OnInit {

    public formLoaded : boolean = false;

    constructor() {
        super();
    }

    ngOnInit() {

        if (this.context.domainEntitySnapshot.status == 'Requested'){
            this.formLoaded = true;
        }
    }

    public onGetForm(){
        this.formLoaded = true;
    }  
}

