import { Component, OnInit, OnDestroy } from '@angular/core';
import { QualificationNeedsService } from './qualification-needs.service';
import {
    WorkerQualificationNeeds,
    QualificationNeedsFilterTag,
    QualificationNeedsOrderProperty
} from '@mydrake/domain/read/models/qualification-needs';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MyDrakeEventEmitter, DMS } from '@shared/services';
import { Constants } from 'app/shared/core/constants';
import { TipoContextoTrabalhador } from '@mydrake/domain/read/models/qualification-needs/worker-qualification-needs';
import { DocumentInfo } from '@shared/models';
import { KDocumentViewer } from 'app/shared/components/k-document-viewer/k-document-viewer.service';

@Component({
    selector: 'qualification-needs',
    templateUrl: 'qualification-needs.component.html',
    styleUrls: ['qualification-needs.component.scss']
})
export class QualificationNeedsComponent implements OnInit, OnDestroy {
    public qualificationNeeds = new Array<WorkerQualificationNeeds>();
    private _qualificationNeedsOrderProperty = QualificationNeedsOrderProperty.Expiration;
    public qualificationNeedsOrderPropertyList = [
        QualificationNeedsOrderProperty.Expiration,
        QualificationNeedsOrderProperty.Qualification];
    public descending = false;
    private _qualificationNeedsFilterTag = QualificationNeedsFilterTag.All;
    public qualificationNeedsFilterTagList = [
        QualificationNeedsFilterTag.All,
        QualificationNeedsFilterTag.Expired,
        QualificationNeedsFilterTag.ThirtyDays,
        QualificationNeedsFilterTag.SixtyDays,
        QualificationNeedsFilterTag.OneHundredAndEightyDays];
    public contextDataSource = new MatTableDataSource();
    public attendancesDataSource = new MatTableDataSource();
    private searchTextChangeSubscription: Subscription;
    private filterTagChangeSubscription: Subscription;
    private orderByChangeSubscription: Subscription;
    private orderArrowChangeSubscription: Subscription;
    private textSearch: string;
    private page = 1;
    private limit = 15;

    constructor(private api: QualificationNeedsService, private emitter: MyDrakeEventEmitter,
        private viewer: KDocumentViewer) {
        this.searchTextChangeSubscription = this.emitter.get(Constants.EventKeys.SearchTextChange)
            .subscribe((text: string) => this.onSearchListener(text));

        this.emitter.get(Constants.EventKeys.OrderBy).emit(
            {
                value: this.qualificationNeedsOrderPropertyList,
                defaultValue: QualificationNeedsOrderProperty.Expiration,
                translatePath: 'QUALIFICATION_NEEDS.ORDER_BY.'
            });
        this.emitter.get(Constants.EventKeys.FilterTag).emit(
            {
                value: this.qualificationNeedsFilterTagList,
                defaultValue: QualificationNeedsFilterTag.All,
                translatePath: 'QUALIFICATION_NEEDS.FILTER_TAG.'
            });
        this.emitter.get(Constants.EventKeys.ShowOrderArrow).emit({ value: true, defaultValue: false });

        this.filterTagChangeSubscription = this.emitter.get(Constants.EventKeys.FilterTagChange)
            .subscribe((text) => this.qualificationNeedsFilterTag = text);
        this.orderByChangeSubscription = this.emitter.get(Constants.EventKeys.OrderByChange)
            .subscribe((text) => this.qualificationNeedsOrderProperty = text);
        this.orderArrowChangeSubscription = this.emitter.get(Constants.EventKeys.OrderArrowChange)
            .subscribe((value) => { this.descending = value; this.refresh(); });
    }

    get qualificationNeedsFilterTag(): QualificationNeedsFilterTag {
        return this._qualificationNeedsFilterTag;
    }

    set qualificationNeedsFilterTag(res) {
        this._qualificationNeedsFilterTag = res;
        this.refresh();
    }

    get qualificationNeedsOrderProperty(): QualificationNeedsOrderProperty {
        return this._qualificationNeedsOrderProperty;
    }

    set qualificationNeedsOrderProperty(res) {
        this._qualificationNeedsOrderProperty = res;
        this.refresh();
    }

    private refresh() {
        this.page = 1;
        this.api.getQualificationNeed(this.page, this.limit, this.textSearch, this.descending,
            this.qualificationNeedsOrderProperty, this.qualificationNeedsFilterTag
        ).subscribe(qualificationNeeds => {
            this.qualificationNeeds = qualificationNeeds;
        });
    }

    ngOnInit(): void {
        this.api.getQualificationNeed(this.page, this.limit, this.textSearch, this.descending,
            this.qualificationNeedsOrderProperty, this.qualificationNeedsFilterTag
        ).subscribe(qualificationNeeds => {
            this.qualificationNeeds = qualificationNeeds;
        });
    }

    public isPositive(validDays: number): boolean {
        return validDays >= 0;
    }

    public getModule(validDays: number): number {
        return Math.sqrt(Math.pow(validDays, 2));
    }

    public getContextType(type: TipoContextoTrabalhador): string {
        return 'QUALIFICATION_NEEDS.CONTEXT_TYPES.' + type.toString().toUpperCase();
    }

    public getDetails(val: WorkerQualificationNeeds) {
        this.api.getQualificationNeedDetails(val.qualificationId).subscribe(details => {
            val.details = details;
            this.contextDataSource.data = details.contexts;
            this.attendancesDataSource.data = details.attendances;
        });
    }

    public onSearchListener(textSearch: string) {
        this.textSearch = textSearch;
        this.page = 1;

        this.api.getQualificationNeed(this.page, this.limit, this.textSearch, this.descending,
            this.qualificationNeedsOrderProperty, this.qualificationNeedsFilterTag
        ).subscribe(qualificationNeeds => {
            this.qualificationNeeds = qualificationNeeds;
        });
    }

    public onScrollDown() {
        if (this.qualificationNeeds.length < this.page * this.limit) {
            return;
        }

        this.page++;
        this.api.getQualificationNeed(this.page, this.limit, this.textSearch, this.descending,
            this.qualificationNeedsOrderProperty, this.qualificationNeedsFilterTag
        ).subscribe(result => {
            result.forEach(qualificationNeed => this.qualificationNeeds.push(qualificationNeed));
        });
    }

    public onDownload(documentInfo: DocumentInfo) {
        this.viewer.open(documentInfo);
    }

    ngOnDestroy(): void {
        this.searchTextChangeSubscription.unsubscribe();
        this.filterTagChangeSubscription.unsubscribe();
        this.orderByChangeSubscription.unsubscribe();
        this.orderArrowChangeSubscription.unsubscribe();
    }
}
