import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboxTransitionModel } from '@mydrake/domain/read/models/core';
import { InboxApi } from '../inbox.api';
import { MyDrakeEventEmitter } from '@shared/services';
import { InboxTransitionFormDialogComponent} from '../inbox-transition-form-dialog/inbox-transition-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';

@Component({
    selector: 'mydrake-inbox-transition',
    templateUrl: './inbox-transition.component.html',
    styleUrls: ['./inbox-transition.component.scss']
})
export class InboxTransitionComponent {

    @Input()
    public flowType: string;

    @Input()
    public domainId: string;

    @Input()
    public transitions: Array<InboxTransitionModel> = [];

    @Input()
    public inboxId: string;

    @Input()
    public onValid: () => boolean;

    @Output()
    public submit = new EventEmitter<FormInstanceSaveOrUpdateSubmission[]>();

    public disableButtons = false;

    constructor(private api: InboxApi, private emitter: MyDrakeEventEmitter, private dialog: MatDialog) {
    }

    public onFireTransition(transition) {

        this.disableButtons = true;

        if (this.onValid) {
            try {
                this.onValid();
            }
            catch(error) {
                // Reabilita o botao pois ocorre um erro durante a validacao.
                this.disableButtons = false;

                throw error;
            }
        }

        if (transition.inputProperties.length > 0) {
            let dialogRef = this.dialog.open(InboxTransitionFormDialogComponent, { width: '550px', data: transition });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.fireTransition(transition);
                } else {
                    // reabilita os botoes pois o usuario cancelou
                    this.disableButtons = false;
                }
            });
        }
        else {
            this.fireTransition(transition);
        }

    }

    private fireTransition(transition){
        this.api.fireTransition(transition.key, this.flowType, this.domainId, transition.inputProperties, transition.geolocateThisAction, () => {
            this.emitter.get('INBOX_LIST_REFRESH').emit(this.inboxId);
        }, (error) => {
            // Se ocorrer algum erro durante a transição, reabilita o botao.
            this.disableButtons = false;
            throw error;
        });
    }
}

export class FormInstanceSaveOrUpdateSubmission {

    constructor(formId: string = null, content: string = null) {
        this.formId = formId;
        this.content = content;
    }

    public formId: string;
    public content: string;
}
