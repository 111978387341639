import { Injectable } from '@angular/core';
import { KBooleanFieldComponent } from './k-boolean-field/k-boolean-field.component';
import { KCustomDomainFieldComponent } from './k-customdomain-field/k-customdomain-field.component';
import { KDateFieldComponent } from './k-date-field/k-date-field.component';
import { KMultilineTextFieldComponent } from './k-multiline-text-field/k-multiline-text-field.component';
import { KSimpleTextFieldComponent } from './k-simple-text-field/k-simple-text-field.component';
import { KTimeFieldComponent } from './k-time-field/k-time-field.component';
import { KSignatureFieldComponent } from './k-signature-field/k-signature-field.component';

@Injectable()
export class FieldRenderMapping {

    private static FieldMappings = {
        'Bool': KBooleanFieldComponent,
        'MultiLineText': KMultilineTextFieldComponent,
        'Date': KDateFieldComponent,
        'Time': KTimeFieldComponent,
        'Signature': KSignatureFieldComponent,
        'CustomDomain': KCustomDomainFieldComponent        
    };

    public static getField(typeName: string) {
        let type = this.FieldMappings[typeName];
        return type || KSimpleTextFieldComponent;
    }
}
