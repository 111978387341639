<mydrake-inbox-taskbar [transitions]="context.availableTransitions" [flowType]="context.flowType" [domainId]="domainId" [inboxId]="inboxId" [flowIdentifier]="context.flowIdentifier"></mydrake-inbox-taskbar>

<div fxLayout="row">
    <div fxLayout="column" fxFlex>
            <div fxLayout="row" fxFlex>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.TITLE' | translate }}</label>
                    <div>{{context.domainEntitySnapshot.title }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.DESCRIPTION' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.description }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.START' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.startDate | i18nDate }}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.END' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.endDate | i18nDate}}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.OPERATIONAL_UNIT' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.workerOrganizationalUnitDescription}}</div>
                </div>

            </div>

            <div fxLayout="row" fxFlex>

                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.OCCURRENCE' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.occurrenceDescription}}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.TYPE' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.typeName}}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.ORIGIN' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.originName}}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.DESTINATION' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.destinationName}}</div>
                </div>
                <div fxFlex class="content">
                    <label>{{ 'TYPES.APPOINTMENT.COST_CENTER' | translate}}</label>
                    <div>{{context.domainEntitySnapshot.costCenterDescription}}</div>
                </div>

            </div>

            <br>

            <k-formio-list [linkedFormInstances]="context.currentStateLinkedFormInstances" (submit)="onSubmitted($event)"></k-formio-list>

            <k-document-view class="documents" *ngIf="context.workersCanViewAttachments" [documents]="context.attachments" mode="grid"></k-document-view>
    </div>
</div>

