import { Component } from '@angular/core';
import { TemplateComponent } from '../template';

@Component({
    selector: 'inbox-card-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss']
})
export class InboxCardAppointmentComponent extends TemplateComponent {

    constructor() {
        super();
    }

}
