<header style="background: #F44336; padding: 20px; color: white;">
    <div fxLayout="row" fxLayoutAlign="start center">
        <k-date-viewer [date]="context.start"></k-date-viewer>
        <div style="margin-left: 15px">
            <div class="uop" style="color: white;">{{ context.organizationalUnit }}</div>
            <div class="job" style="color: white;">{{ context.job }}</div>
        </div>
    </div>
</header>
<mydrake-logistic-timeline [logistics]="context.logisticNeeds"></mydrake-logistic-timeline>

