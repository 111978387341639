import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ElementRef, Renderer2, Inject, ViewChild } from '@angular/core';
import { DocumentInfo } from '@shared/models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { DMS } from '@shared/services';
import { plainToClass } from 'class-transformer';

@Component({
    selector: 'k-document-viewer',
    templateUrl: './k-document-viewer.component.html',
    styleUrls: ['./k-document-viewer.component.scss']
})

export class KDocumentViewerDialog implements OnInit {

    public current: DocumentInfo;
    public documents: Array<DocumentInfo> = [];
    public index: number = 0;
    public progress: boolean = false;

    @ViewChild("image", { static: false })
    public container: ElementRef;

    constructor(private renderer: Renderer2,
                private viewer: ElementRef,
                private DMS: DMS,
                private sanitizer: DomSanitizer,
                @Inject(MAT_DIALOG_DATA) public params: any) {

        this.documents = plainToClass(DocumentInfo, this.params.otherDocuments as Array<DocumentInfo>);

        if(this.params.otherDocuments.length == 0){
            this.documents.push(plainToClass(DocumentInfo, this.params.document as DocumentInfo));
        }

        if(this.params.document){
            this.index = _.findIndex(this.documents, document => document.id === this.params.document.id);
        }

        this.current = new DocumentInfo();
    }

    ngOnInit() {
        this.applyStyle();

        this.loading();

        //Resolvendo problema de ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.setCurrentDocument(plainToClass(DocumentInfo, this.params.document as DocumentInfo));
            this.stop();
        });
    }

    /**
     * Exibir próximo documento
     */
    public onNext(): void {

        this.loading();

        if (this.index < this.documents.length) {
            this.index++;
            this.setCurrentDocument(this.documents[this.index]);
        }

        this.stop();
    }

    /**
     * Exibir documento anterior
     */
    public onPrevious(): void{

        this.loading();

        if (this.index > 0) {
            this.index--;
            this.setCurrentDocument(this.documents[this.index]);
        }

        this.stop();
    }

    /**
     *
     * @param document
     */
    private setCurrentDocument(document: DocumentInfo) {
        this.current = document;
        this.current.content = this.sanitizer.bypassSecurityTrustResourceUrl(this.DMS.getStream(document));
        if (this.params.otherDocuments[this.index]) {
         this.params.otherDocuments[this.index].alreadyDownloaded = true;
        }
    }

    /**
     *
     */
    public onZoomIn(): void {
        this.renderer.setStyle(this.container.nativeElement, 'width', (this.container.nativeElement.width * 1.1) + 'px')
    }

    /**
     *
     */
    public onZoomOut(): void {
        this.renderer.setStyle(this.container.nativeElement, 'width', (this.container.nativeElement.width * 0.9) + 'px')
    }

    /**
     * Aplica a estilização para que o modal fique em fullscreen;
     */
    private applyStyle(): void {

        let container = this.viewer.nativeElement.parentElement;
        let overlay = this.viewer.nativeElement.parentElement.parentElement;
        let backdrop = container.parentElement.parentElement.parentElement.querySelector('.cdk-overlay-backdrop');

        this.renderer.setStyle(container, 'background', 'transparent');
        this.renderer.setStyle(container, 'box-shadow', 'none');
        this.renderer.setStyle(overlay, 'max-width', '100%');
        this.renderer.setStyle(overlay, 'width', '100%');
        this.renderer.setStyle(overlay, 'height', '100%');

        this.renderer.setStyle(backdrop, 'background', 'rgba(0,0,0,0.85)');
    }

    private loading(): void{
        this.progress = true;
    }

    private stop(): void{
        this.progress = false;
    }

    public onDownload(documentInfo : DocumentInfo){
        let current = this;
        window.location.href = this.DMS.download(documentInfo);
        window.onload = function(e){
            current.DMS.confirmDownload(documentInfo.id);
        };
    }

    public confirmDownload(id: string) {
        this.DMS.confirmDownload(id);
    }
}
