import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FlowLinkedFormInstance } from '@mydrake/domain/read/models/core/inbox-transition.model';
import { KFormioComponent } from '../k-formio/k-formio.component';
import * as _ from 'lodash';
import {JSONPath} from 'jsonpath-plus';
import {DMS} from '@shared/services';
import {UploadUriInfo} from '../../models/dms.model';
import {FileUploader} from 'ng2-file-upload';
import {from, Observable, of} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'k-formio-list',
    templateUrl: './k-formio-list.component.html',
    styleUrls: ['./k-formio-list.component.scss']
})
export class KFormioListComponent {

    @Input()
    public linkedFormInstances: Array<FlowLinkedFormInstance> = [];

    @Input()
    public showButtons = true;

    @Input()
    public showTitle = true;

    @ViewChild('kformio', { static: false, read: KFormioComponent })
    kformio: KFormioComponent;

    @Output()
    public submit = new EventEmitter<KFormioSubmission[]>();

    public loading: boolean = false;
    public loadMessage: string = '';


    constructor(private dms: DMS, private translateService: TranslateService) {}

    public get mustShow(): boolean {
        return _.some(this.linkedFormInstances, (item: FlowLinkedFormInstance) => item.readOnly === false) === true;
    }

    public isValid(): any {
        return this.kformio.isValid();
    }

    public onFormSave(): void {

        this.getContentForm().subscribe(result => {
            this.submit.emit(result);
        });


    }

    public getContentForm(): Observable<KFormioSubmission[]> {

        const promises = new Array<Promise<any>>();

        this.linkedFormInstances.forEach((linkedForm: FlowLinkedFormInstance) => {

            if (linkedForm.readOnly === false) {

                const fileFieldNames = JSONPath({ path: '$..components[?(@.type==\'file\')].key', json: linkedForm.form.definition });

                fileFieldNames.forEach((fileFieldName: string) => {

                    const jsonPathToFindFormioFiles = `$.data.${fileFieldName}[?(@.storage==\'base64\')]`;

                    const result = JSONPath({ path: jsonPathToFindFormioFiles, json: linkedForm.formInstanceContent });

                    if (result.length > 0) {

                        const updateFileComponentPromise = new Promise<any>((resolve, reject) => {

                            JSONPath({
                                path: jsonPathToFindFormioFiles, json: linkedForm.formInstanceContent,
                                callback: async (formioFile: KFormioFile) => {

                                    // Precisamos fazer upload desse arquivo.
                                    await this.dms.getUploadUri().subscribe((uploadUriInfo: UploadUriInfo) => {

                                        const uploader = new FileUploader(this.dms.getFileUploaderOptionsToAzureStorage(uploadUriInfo.url));
                                        uploader.onAfterAddingFile = (item) => {
                                            item.withCredentials = false;
                                        };

                                        uploader.onSuccessItem = () => {
                                            console.log('Upload do arquivo ' + formioFile.name + ' concluído.');
                                            this.startLoader(false);
                                            resolve(true);
                                        }

                                        this.convertBase64ToFile(formioFile.url, formioFile.name).subscribe(result => {
                                            console.log('Upload do arquivo ' + formioFile.name + ' iniciado.');
                                            const uploadMessage = this.translateService.instant('UPLOADING_FILE') + ' ' + formioFile.name;
                                            this.startLoader(true, uploadMessage);

                                            uploader.addToQueue([result]);

                                            formioFile.dmsDocumentId = uploadUriInfo.documentId;
                                            formioFile.storage = 'dmsTemp';
                                            formioFile.url = null;
                                        });


                                    });

                                }

                            });

                        });

                        promises.push(updateFileComponentPromise);
                    }

                });
            }
        });

        return from(Promise.all(promises).then(() => {

            const content = new Array<KFormioSubmission>();

            this.linkedFormInstances.forEach((linkedForm: FlowLinkedFormInstance) => {
                content.push(new KFormioSubmission(linkedForm.form.id, linkedForm.formInstanceContent));
            });

            return content;

        }));
    }

    private convertBase64ToFile(dataURI: string, fileName: string): Observable<File> {
        const byteString = dataURI.split(',')[0].indexOf('base64') >= 0
            ? atob(dataURI.split(',')[1])
            : unescape(dataURI.split(',')[1]);

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const imageBlob = new Blob([ia], { type: mimeString });

        return of(new File([imageBlob], fileName, { type: mimeString }));
    }

    private startLoader = (shouldStart:boolean, message?:string):void => {
        this.loading = shouldStart;
        this.loadMessage = message ? message : '';
    }
}

export class KFormioSubmission {
    constructor(formId: string = null, content: string = null) {
        this.formId = formId;
        this.content = content;
    }

    public formId: string;
    public content: string;
}


export class KFormioFile {
    public name: string;
    public originalName: string;
    public size: number;
    public storage: string;
    public type: string;
    public url: string;
    public dmsDocumentId: string;
}
