import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LogisticNeedView } from '@mydrake/domain/read/views/core';
import { LogisticNeedType } from '@mydrake/domain/enums/logistic';

@Component({
    selector: 'mydrake-logistic-info',
    templateUrl: './logistic-info.component.html',
    styleUrls: ['./logistic-info.component.scss']
})
export class LogisticInfoComponent {

    @Input()
    public logistic : LogisticNeedView = new LogisticNeedView();

    @Output()
    public back = new EventEmitter();

    constructor() {}

    public onBack(){
        this.back.emit();
    }

    public isHosting(){
        return this.logistic.type == LogisticNeedType.Hosting;
    }
}
