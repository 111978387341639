import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MyDrakeSharedModule } from '../../shared/shared.module';
import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
import {NgScrollbarModule} from 'ngx-scrollbar';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MyDrakeSharedModule,
        NgScrollbarModule
    ],
    exports: [
        ProfileComponent
    ],
    declarations: [
        ProfileComponent
    ],
    providers: [
        ProfileService
    ],
})
export class MyDrakeProfileModule { }
