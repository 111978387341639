import {
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {FieldRenderTemplate} from './field-render-template';
import {FieldRenderMapping} from './field-render-mapping';
import { KDomainFieldComponent } from './k-domain-field/k-domain-field.component';

@Component({
    selector: 'k-field-render',
    template: '<div #fieldrendercontainer></div>'
})

export class KFieldRenderComponent implements OnInit, OnDestroy {

    @Input() prop: any;

    @ViewChild('fieldrendercontainer', { read: ViewContainerRef, static: true })
    public container: ViewContainerRef;

    private componentRef: ComponentRef<{}>;

    constructor(private factoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        let factory: ComponentFactory<unknown>;
        const type = this.prop.type;

        if (type) {

            if (type.isDomain === true) {
                factory = this.factoryResolver.resolveComponentFactory(KDomainFieldComponent);
            } else {
                let componentType = FieldRenderMapping.getField(type.key);
                factory = this.factoryResolver.resolveComponentFactory(componentType);
            }

            this.componentRef = this.container.createComponent(factory);
            let instance = <FieldRenderTemplate>this.componentRef.instance;
            instance.prop = this.prop;
        }

    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = null;
        }
    }
}
