import { AppointmentType } from "@mydrake/domain/enums/core";
import { AppointmentView } from "@mydrake/domain/read/views/core/appointment.view";
import { LogisticNeedView } from "@mydrake/domain/read/views/core/logistic-need.view";

export class DisembarkView extends AppointmentView {

    public organizationalUnit : string;
    public job : string;
    public logisticNeeds : Array<LogisticNeedView>;

    constructor() {
        super();
        this.type = AppointmentType.Disembark;
        this.logisticNeeds = [];
    }

}
