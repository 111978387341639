import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KIconComponent } from './k-icon.component';

@NgModule({
    declarations: [KIconComponent],
    imports: [CommonModule],
    exports: [KIconComponent],
    providers: [],
})
export class KIconModule { }
