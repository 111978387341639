import { Injectable } from '@angular/core';
import { VacationRequestComponent } from './vacation-request/vacation-request.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';
import { InboxCardAppointmentComponent } from './appointment/appointment.component';
import { InboxCardParticipantLogisticScheduleComponent } from './participant-logistic-schedule/participant-logistic-schedule.component';
import { InboxCardDefaultComponent } from './default/default.component';
import { InboxCardDocumentComponent } from './document/document.component';

@Injectable()
export class InboxMapping {

    /**
     * Mapeamento dos tipos de INBOX para seus respectivos templates;
     */
    private static templateMappings = {
        'VacationRequest': VacationRequestComponent,
        'OnlineTraining': OnlineTrainingComponent,
        'Appointment': InboxCardAppointmentComponent,
        'ParticipantLogisticSchedule': InboxCardParticipantLogisticScheduleComponent,
        'Document': InboxCardDocumentComponent
    };

    /**
     * Mapeamento dos tipos de INBOX para seus respectivos ícones;
     */
    private static iconMappings = {
        'VacationRequest': 'icon-mydrake-vacation',
        'OnlineTraining': 'icon-mydrake-diploma',
        'Appointment': 'icon-mydrake-basic-elaboration-calendar-empty',
        'Document': 'icon-mydrake-folder-search',
        'ParticipantLogisticSchedule': 'icon-mydrake-arrows-expand-horizontal1',
    };

    /**
     * Retorna o template a partir do tipo do INBOX;
     *
     * @param typeName : tipo do inbox;
     */
    public static getTemplate(typeName: string) {
        return this.templateMappings[typeName] || InboxCardDefaultComponent;
    }

    /**
     * Retorna o template a partir do tipo do INBOX;
     *
     * @param typeName : tipo do inbox;
     */
    public static getIcon(typeName: string) {
        return this.iconMappings[typeName] || 'icon-mydrake-tasks';
    }
}
