<div class="inactive-mydrake-dialog">

    <i class="material-icons">cancel</i>

    <div class="information">

        <h4>{{ 'REGISTRATION.EXCEPTIONS.MYDRAKE_INACTIVE.TITLE' | translate }}</h4>

        <p class="description">{{ 'REGISTRATION.EXCEPTIONS.MYDRAKE_INACTIVE.DESCRIPTION' | translate }}</p>

    </div>

</div>
