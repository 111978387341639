<section class="rule-selection">

    <ng-container *ngIf="rulesLoaded && !ruleSelected">

        <div class="thumbnail">
            <i class="icon-mydrake-vacation"></i>
        </div>

        <span>Escolha a regra para continuar</span>

        <div class="rules">
            <button *ngFor="let rule of rules" class="k-action" mat-raised-button (click)="chooseRule(rule.id)">{{ rule.acronym }}</button>
        </div>

    </ng-container>

    <ng-container *ngIf="rulesLoaded && ruleSelected">
        <mydrake-vacation-request-wizard [context]="context" [inboxId]="inboxId" [domainId]="domainId" [rule]="rule"></mydrake-vacation-request-wizard>
    </ng-container>

</section>