export const environment = {
    production: true,
    clientId: "sia.mydrake.ui",
    appUrl: window.location.origin,
    apiUrl:  '/api/v2/',
    issuerUrl: window.location.origin,
    authorityUrl: '/logon',
    redirectUrl: '/inbox',
    basePath: '/My'
};
