import { Component, OnInit, Input } from '@angular/core';
import { DocumentInfo } from '@shared/models';
import { KDocumentViewer } from '../k-document-viewer/k-document-viewer.service';

@Component({
    selector: 'k-document-view',
    templateUrl: 'k-document-view.component.html',
    styleUrls: [ 'k-document-view.component.scss']
})

export class KDocumentViewComponent implements OnInit {

    @Input()
    public mode: string;

    public isGrid: boolean;

    @Input()
    public documents: Array<DocumentInfo>;

    @Input()
    public useBorder: Boolean = true;

    constructor(public viewer: KDocumentViewer) {
        this.documents = [];
    }

    ngOnInit() {
        this.isGrid = this.mode === 'grid';
    }

    public onOpen(document: DocumentInfo){
        this.viewer.open(document, this.documents);
    }
}
