import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MyDrakeUserPreference {

    constructor(private http: HttpClient) {
    }

    public save(key: string, value: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}Preference`, { key: key, value: value });
    }

    public getByKey(key: string): Observable<any> {

        let params = new HttpParams();

        params = params.append('key', key);

        return this.http.get(`${environment.apiUrl}Preference`, { params: params });
    }
}
