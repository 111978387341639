import { Component, Input, OnInit } from '@angular/core';
import { AlertMode, AlertType, alertIcons } from './k-alert.model';

@Component({
  selector: 'k-alert',
  templateUrl: './k-alert.component.html',
  styleUrls: ['./k-alert.component.scss']
})
export class KAlertComponent {

  @Input() public mode: AlertMode = 'default';
  @Input() public type: AlertType = 'primary';

  @Input() public message: string;
  @Input() public hideIcon: boolean = false;
  @Input() public icon: string;

  public readonly alertIcons = alertIcons;

  constructor() { }

}
