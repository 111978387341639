import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'request-submitted',
    templateUrl: './request-submitted.component.html',
    styleUrls: ['./request-submitted.component.scss']
})
export class RequestSubmittedComponent implements OnInit {
    public code: number;

    constructor(
        private _dialogRef: MatDialogRef<RequestSubmittedComponent>,
        @Inject(MAT_DIALOG_DATA) data: number,
        private router: Router) {
        this.code = data;
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this._dialogRef.close();
    }

    onGoToInbox(): void {
        this._dialogRef.close();
        this.router.navigate(['/inbox']);
    }
}
