import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ContactService } from "./contact.service";
import { MatSidenav } from "@angular/material/sidenav";
import { Subscription } from "rxjs";
import { MyDrakeEventEmitter } from '@shared/services';
import { ContactView } from '@mydrake/domain/read/views/core';
import { Constants } from '../../../shared/core/constants';

@Component({
    selector: 'mydrake-contacts',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

    public loaded: boolean = false;
    public opened: boolean = false;

    /**
     * Modo de exibição dos contatos: GRID ou LIST
     */
    public gridMode : boolean = true;

    /**
     * Lista de contatos a ser exibida;
     */
    public contacts : Array<ContactView> = [];

    /**
     * Lista de contatos a ser exibida;
     */
    public store : Array<ContactView> = [];

    /**
     * Contato selecionado para ver o detalhamento do mesmo;
     */
    public selected : ContactView = new ContactView();

    /**
     * Referente ao evento de pesquisa da barra global;
     */
    private subscription : Subscription;

    /**
     * Referente ao retriever;
     */
    private contractsSubscription: Subscription;

    /**
     * Referência para a barra lateral que exibe o detalhamento do contato;
     */
    @ViewChild('infoSideNav', { static: true })
    public content: MatSidenav;

    constructor(private _service : ContactService, private emitter : MyDrakeEventEmitter) {
        this.subscription = this.emitter.get(Constants.EventKeys.SearchTextChange).subscribe(text => this.onSearchListener(text));
    }

    ngOnInit(){
        this.getContacts();
    }

    /**
     * Habilita o modo GRID de exibição dos contatos;
     */
    public onGridMode(){
        this.gridMode = true;
    }

    /**
     * Habilita o modo LIST de exibição dos contatos;
     */
    public onListMode(){
        this.content.close();
        this.gridMode = false;
    }

    /**
     * Seleciona um contato para ser exibido na barra lateral;
     *
     * @param contact
     */
    public selectContact(contact : ContactView){
        this.selected = contact;
        this.opened = true;
        this.content.open();
    }

    /**
     * Esconde o painel lateral que exibe o detalhamento do contato;
     */
    public onClose(){
        this.opened = false;
        this.content.close();
    }

    /**
     * Retorna os contatos compartilhados com o trabalhador em questão;
     */
    private getContacts(){
        this.contractsSubscription = this._service.getContacts().subscribe(data => {
            this.loaded = true;
            this.contacts = data;
            this.store = data;
        });
    }

    /**
     *
     * @param textSearch : filtro texto de pesquisa referente ao nome do contato;
     */
    public onSearchListener(textSearch: string){
        this.contacts = this.store.filter(c => c.name.toUpperCase().indexOf(textSearch.toUpperCase()) != -1);
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
        this.contractsSubscription.unsubscribe();
    }

}
