import { InboxDefaultTransitionBehavior } from '@mydrake/domain/enums/core';
import * as _ from 'lodash';
import { Injectable } from "@angular/core";

@Injectable()
export class InboxUtils {

    public static getDefaultTransition(transitions : Array<any>, behavior: InboxDefaultTransitionBehavior) {
        let result = _.filter(transitions, transition => transition.defaultTransitionBehavior && transition.defaultTransitionBehavior === behavior);
        return (result.length > 0) ? result[0] : null;
    }
}
