<div class="card">

    <div class="thumbnail">
        <i class="icon-mydrake-basic-elaboration-calendar-remove"></i>
    </div>

    <div class="message">
        <span>{{ 'INBOX.ONLINE_TRAINING.MESSAGE_EXPIRED' | translate }}</span>
    </div>

</div>
