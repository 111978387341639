<!-- BREADCRUMB -->
<k-breadcrumb label="{{ 'MENU.QUALIFICATION_NEEDS' | translate }}"></k-breadcrumb>
<!--/ BREADCRUMB -->


<div autoresize [fix]="150" *ngIf="qualificationNeeds.length > 0">
    <ng-scrollbar [autoHeightDisabled]="false">
        <div scrollViewport infinite-scroll  [infiniteScrollDistance]="2" [infiniteScrollThrottle]="15" [scrollWindow]="false" (scrolled)="onScrollDown()">
            <div style="padding: 5px 25px; height: 100%;">
                <mat-accordion id="qualificationNeed">
                    <mat-expansion-panel class="item" (opened)="getDetails(qualificationNeed)" *ngFor="let qualificationNeed of qualificationNeeds"
                        #mep="matExpansionPanel">
                        <mat-expansion-panel-header style="height: 56px !important; padding: 30px 15px;">
                            <mat-panel-title>
                                <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

                                    <div fxLayout="column" fxFlex>
                                        <div class="label">{{ 'QUALIFICATION_NEEDS.QUALIFICATION' | translate }}</div>
                                        <div>{{ qualificationNeed.qualification }}</div>
                                    </div>

                                    <div fxLayout="column" fxFlex>
                                        <div class="label">{{ 'QUALIFICATION_NEEDS.INDICATED_COURSE' | translate }}</div>
                                        <div>{{ qualificationNeed.indicatedCourse }}</div>
                                    </div>

                                    <div fxLayout="column" style="min-width: 100px;">
                                        <div class="label">{{ 'QUALIFICATION_NEEDS.EXPIRATION' | translate }}</div>
                                        <div *ngIf="qualificationNeed.expiration">{{ qualificationNeed.expiration | i18nDate: 'date'}}</div>
                                        <div *ngIf="!qualificationNeed.expiration"> {{ 'QUALIFICATION_NEEDS.NO_EXPIRATION_DATE' | translate }} </div>
                                    </div>

                                    <div style="padding: 20px"></div>

                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <div class="valid-days-positive"
                                            *ngIf="isPositive(qualificationNeed.validDays) && qualificationNeed.validDays > 90">
                                            {{ getModule(qualificationNeed.validDays) }}
                                            {{ 'QUALIFICATION_NEEDS.DAYS_TO_EXPIRE' | translate }}
                                        </div>

                                        <div class="valid-days-warning"
                                            *ngIf="isPositive(qualificationNeed.validDays) && 90 >= qualificationNeed.validDays">
                                            {{ getModule(qualificationNeed.validDays) }}
                                            {{ 'QUALIFICATION_NEEDS.DAYS_TO_EXPIRE' | translate }}
                                        </div>

                                        <div class="valid-days-negative" *ngIf="!isPositive(qualificationNeed.validDays)">
                                            {{ 'QUALIFICATION_NEEDS.EXPIRED_TO' | translate }}
                                            {{ getModule(qualificationNeed.validDays) }}
                                            {{ 'QUALIFICATION_NEEDS.DAYS' | translate }}
                                        </div>
                                    </div>

                                </div>
                            </mat-panel-title>
                            <mat-panel-description>

                            </mat-panel-description>
                        </mat-expansion-panel-header>


                        <div class="box" *ngIf="qualificationNeed.status != 'Draft' && qualificationNeed.details">
                            <div class="course" fxLayout="row" fxLayoutAlign="start center" fxFlex
                                *ngIf="qualificationNeed.course || (qualificationNeed.startCourse || qualificationNeed.endCourse) || qualificationNeed.trainingState">
                                <div fxLayout="column" fxFlex>
                                    <div class="label">{{ 'QUALIFICATION_NEEDS.COURSE' | translate }}</div>
                                    <div>{{ qualificationNeed.course }}</div>
                                </div>
                                <div fxLayout="column" fxFlex>
                                    <div class="label">{{ 'QUALIFICATION_NEEDS.COURSE_PERIOD' | translate }}</div>
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <span>{{qualificationNeed.startCourse | i18nDate: 'date'}}</span>
                                        <span class="to">{{ 'COMMON.TO' | translate }}</span>
                                        <span>{{qualificationNeed.startCourse | i18nDate: 'date'}}</span>
                                    </div>
                                </div>
                                <div style="padding: 20px"></div>
                                <div class="state">
                                    <div>{{ qualificationNeed.trainingState }}</div>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="50">
                                    <h3 class="title">{{ 'QUALIFICATION_NEEDS.REASONS' | translate }}</h3>
                                    <div class="content">
                                        <div class="table-container mat-elevation-z2">
                                            <mat-table #table [dataSource]="contextDataSource">
                                                <ng-container matColumnDef="date">
                                                    <mat-header-cell *matHeaderCellDef> {{ 'DATE' | translate }}
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let context"> {{context.date | i18nDate: 'date'}}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="job">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        {{ 'QUALIFICATION_NEEDS.JOB' | translate }} </mat-header-cell>
                                                    <mat-cell *matCellDef="let context"> {{context.job}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="context">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        {{ 'QUALIFICATION_NEEDS.ORIGIN' | translate }} </mat-header-cell>
                                                    <mat-cell *matCellDef="let context">
                                                        {{ getContextType(context.workerContextType) | translate }}
                                                    </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="['date', 'job', 'context']">
                                                </mat-header-row>
                                                <mat-row *matRowDef="let row; columns: ['date', 'job', 'context'];">
                                                </mat-row>
                                            </mat-table>
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="50">
                                    <h3 class="title">{{ 'QUALIFICATION_NEEDS.EVIDENCES' | translate }}</h3>
                                    <div class="content" style="padding-left: 20px">
                                        <div class="table-container mat-elevation-z2">
                                            <mat-table #table [dataSource]="attendancesDataSource">
                                                <ng-container matColumnDef="qualificationName">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        {{ 'QUALIFICATION_NEEDS.QUALIFICATION_NAME' | translate}}
                                                    </mat-header-cell>
                                                    <mat-cell *matCellDef="let attendance">
                                                        {{ attendance.qualificacaoNome }}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="type">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        {{ 'TYPE' | translate}} </mat-header-cell>
                                                    <mat-cell *matCellDef="let attendance"> {{ attendance.origemAtendimento }}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="cert">
                                                    <mat-header-cell *matHeaderCellDef>
                                                        {{ 'QUALIFICATION_NEEDS.ISSUER' | translate}} </mat-header-cell>
                                                    <mat-cell *matCellDef="let attendance"> {{ attendance.certificadora }}
                                                    </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="download">
                                                    <mat-header-cell fxFlex="5%" *matHeaderCellDef> Doc. </mat-header-cell>
                                                    <mat-cell fxFlex="5%" *matCellDef="let attendance">
                                                        <button mat-icon-button style="text-align: center" *ngIf="attendance.documents[0]"
                                                            (click)="onDownload(attendance.documents[0])">
                                                            <i class="icon-mydrake-expand-window"></i>
                                                        </button>
                                                    </mat-cell>
                                                </ng-container>

                                                <mat-header-row *matHeaderRowDef="['qualificationName', 'type', 'cert', 'download']">
                                                </mat-header-row>
                                                <mat-row *matRowDef="let row; columns: ['qualificationName', 'type', 'cert', 'download'];">
                                                </mat-row>
                                            </mat-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>

<div class="not-found" *ngIf="qualificationNeeds.length === 0">
    <div class="thumbnail">
        <i class="icon-mydrake-todo"></i>
    </div>
    <div class="message">{{ 'QUALIFICATION_NEEDS.NO_REQUESTED_QUALIFICATION_NEEDS' | translate }}
    </div>
</div>
