import { Component, OnInit, OnDestroy } from '@angular/core';
import { VacationRequestService } from "./vacation.service";
import { Constants } from '../../shared/core/constants';
import { VacationRequestModel } from '@mydrake/domain/read/models/vacation-planner';
import { VacationView } from '@mydrake/domain/read/views/vacation-planner';
import { MyDrakeEventEmitter } from '@shared/services';

@Component({
    selector: 'mydrake-vacations',
    templateUrl: 'vacation.component.html',
    styleUrls: ['vacation.component.scss']
})
export class VacationComponent implements OnInit, OnDestroy {

    public requests: Array<VacationRequestModel> = [];
    public vacations: Array<VacationView> = [];
    public selectedTabIndex : number = 0;

    constructor(private api : VacationRequestService, private _emitter: MyDrakeEventEmitter) {
        this._emitter.get(Constants.EventKeys.HideGlobalSearch).emit(true);
    }

    ngOnInit(): void {

        this.api.getVacationRequests().subscribe(requests => {
            this.requests = requests;
        });

        this.api.getVacations().subscribe(vacations => {
            this.vacations = vacations;
        });
    }

    ngOnDestroy(): void {
        this._emitter.get(Constants.EventKeys.HideGlobalSearch).emit(false);
    }
}
