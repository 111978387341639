import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MyDrakeSharedModule } from '../shared/shared.module';
import { MyDrakeInboxModule } from './inbox/inbox.module';
import { MyDrakeProfileModule } from './profile/profile.module';
import { MyDrakeAppointmentModule } from './appointments/appointment.module';
import { CoreComponent } from './core.component';
import { CoreRoutingModule } from './core.routing';
import { MyDrakeContactModule } from './contacts/contact.module';
import { MyDrakeVacationModule } from './vacation/vacation.module';
import { MyDrakeTrainingModule } from './training/training.module';
import { MyDrakeAdditionalEventsModule } from './additional-events/additional-events.module';
import { MyDrakeReportModule } from './reports/report.module';
import { MyDrakeQualificationNeedsModule } from './qualification-needs/qualification-needs.module';
import { MyDrakeDocumentsModule } from './documents/documents.module';
import { FormsModule } from '@angular/forms';
import { MyDrakeRequestCenterModule } from './request-center/request-center.module';
import { TenantResolver } from 'app/shared/core/providers/app.tenant';
import { UserResolver } from 'app/shared/core/providers/app.user';

@NgModule({
    declarations: [
        CoreComponent
    ],
    imports: [
        FormsModule,
        CoreRoutingModule,
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        TranslateModule,
        MyDrakeSharedModule,
        MyDrakeInboxModule,
        MyDrakeProfileModule,
        MyDrakeAppointmentModule,
        MyDrakeContactModule,
        MyDrakeVacationModule,
        MyDrakeTrainingModule,
        MyDrakeAdditionalEventsModule,
        MyDrakeReportModule,
        MyDrakeQualificationNeedsModule,
        MyDrakeDocumentsModule,
        MyDrakeReportModule,
        MyDrakeRequestCenterModule
    ],
    providers: [
        TenantResolver,
        UserResolver
    ]
})
export class MyDrakeCoreModule { }
