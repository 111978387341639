import { Component, Input, OnInit, EventEmitter, OnDestroy } from "@angular/core";
import { DragulaService } from "ng2-dragula";
import { VacationRequestWizardTabs } from "./wizard-tab";
import { VacationApi } from "../vacation.api";
import { UUID } from 'angular2-uuid';
import { Tab } from "../../../../../shared/models/tab.model";
import { VacationRequestValidator } from "../vacation-request.validator";
import { MyDrakeEventEmitter, KSnackBar } from '@shared/services';
import { VacationRequestModel, VacationPlannerRule, FruitionSuggestionModel, VacationPlannerRuleMethods } from "@mydrake/domain/read/models/vacation-planner";
import { VacationRequestStatus } from "@mydrake/domain/enums/vacation-planner";
import { VacationRequestCommand } from "@mydrake/domain/write/commands/vacation-planner";
import { VacationOption, VacationPart } from "@mydrake/domain/write/value-objects/vacation-planner";

import { InboxUtils } from "../../utils";
import { InboxApi } from "../../../inbox.api";
import { Subscription } from "rxjs";
import { VacationPlannerRuleMethodStrategyApplier } from "@mydrake/domain/read/models/vacation-planner/rule-method-strategy-applier/rulemethod-strategy.applier";

import * as _ from 'lodash';
import * as moment from 'moment';
import { InboxDefaultTransitionBehavior } from '@mydrake/domain/enums/core';
import { ParametersService } from "app/shared/providers";

@Component({
    selector: 'mydrake-vacation-request-wizard',
    templateUrl: 'wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class VacationRequestWizardComponent implements OnInit, OnDestroy {

    @Input()
    public context: any;

    @Input()
    public inboxId: any;

    @Input()
    public domainId: any;

    @Input()
    public rule: VacationPlannerRule;

    public daysToSell: Array<number> = [];
    public limitOfSplits: Array<number> = [];

    public today: Date = new Date();
    public transition: any = {};
    public index: number = 1;
    public steps: number = 0;
    public tabs: VacationRequestWizardTabs = new VacationRequestWizardTabs();

    public request: VacationRequestModel;
    public suggestion: FruitionSuggestionModel;

    private ruleMethodStrategyApplier: VacationPlannerRuleMethodStrategyApplier;
    private dragula$: Subscription = new Subscription();

    constructor(
        private api: VacationApi,
        private inboxApi: InboxApi,
        private notifier: KSnackBar,
        private validator: VacationRequestValidator,
        private emitter: MyDrakeEventEmitter,
        private dragulaService: DragulaService,
        private parametersService: ParametersService
    ) {

        this.dragula$.add(this.dragulaService.dropModel("periodsPriorization").subscribe((model) => {
            this.ruleMethodStrategyApplier.updatePeriods(model.targetModel);
        }));
    }

    ngOnInit() {
        this.request = this.context.domainEntitySnapshot as VacationRequestModel;
        this.suggestion = this.getSuggestion(this.request, this.rule);
        this.ruleMethodStrategyApplier = new VacationPlannerRuleMethodStrategyApplier(this.rule, this.suggestion)
        this.configForm();
    }

    ngOnDestroy() {
        this.dragula$.unsubscribe();
    }

    public onPrevious(): void {
        if (this.index == 0) return;
        this.index--;
    }

    public onNext(): void {
        if (this.index == this.steps || !this.validator.isValid(this.request, this.rule)) {
            return;
        }
        this.index++;
    }

    public enableSplit(): void {
        this.request.wantToPartitionVacation = true;
    }

    public createPeriods(options: any): void {
        this.ruleMethodStrategyApplier.createPeriods(options);
    }

    public clearPeriods(): void {
        this.ruleMethodStrategyApplier.createPeriods(1);
        this.request.wantToPartitionVacation = false;
        this.request.howManyPiecesToPartition = 1;
    }

    public clearWantToSellVacationDays() {
        this.request.howManyDaysToSell = 0;
    }

    public defaultWantToSellVacationDays() {
        this.request.howManyDaysToSell = this.rule.maxDaysToSell;
    }

    /**
     * Método que defini o período de férias e envia a solicitação;
     */
    public onSend(): void {

        let iso8601 = 'YYYY-MM-DDTHH:mm:ss';
        let command = new VacationRequestCommand();

        command.requestId = this.request.id;
        command.receiveBonusEarly = this.rule.allowsReceiveBonusEarly ? this.request.receiveBonusEarly : false;
        command.transition = this.transition.key;
        command.howManyDaysToSell = this.request.howManyDaysToSell;
        command.howManyPiecesToPartition = this.request.howManyPiecesToPartition;

        let option = new VacationOption(this.rule.id);
        let periods = this.ruleMethodStrategyApplier.getPeriods();

        for (let i = 0; i < periods.length; i++) {

            let period = periods[i];
            let start = moment(period.start).format(iso8601);
            let end = moment(period.end).format(iso8601);

            option.id = UUID.UUID();
            option.parts.push(new VacationPart(period.id, start, end, period.priority, period.type));
        }

        command.options.push(option);

        this.api.requestVacation(command).subscribe(() => {
            this.notifier.success("INBOX.VACATION_REQUEST.COMMON.SUCCESSFULLY");
            this.request.status = VacationRequestStatus.Requested;
            this.emitter.get('INBOX_LIST_REFRESH').emit(this.inboxId);
        }, (error) => {
            this.notifier.error(error?.error);
        });
    }

    public onFireTransition(): void {
        const defaultOkTransition = InboxUtils.getDefaultTransition(this.context.availableTransitions, InboxDefaultTransitionBehavior.Ok);
        this.inboxApi.fireTransition(defaultOkTransition.key, this.context.flowType, this.domainId, [], defaultOkTransition.geolocateThisAction, () => {
            this.emitter.get('INBOX_LIST_REFRESH').emit(this.inboxId);
        });
    }

    private configForm(): void {

        if (this.request.status === VacationRequestStatus.Draft) {

            //SELL
            this.request.wantToSellVacationDays = (!this.request.wantToSellVacationDays) ? false : this.request.wantToSellVacationDays;

            //SPLIT
            this.request.wantToPartitionVacation = (!this.request.wantToPartitionVacation) ? false : this.request.wantToPartitionVacation;

            //BONUS
            this.parametersService.defaultValueReceiveBonusEarly().then(defaultValue => {
                this.request.receiveBonusEarly = (!this.request.receiveBonusEarly) ? defaultValue : this.request.receiveBonusEarly;
            });

            this.ruleMethodStrategyApplier.initialize();
        }

        this.transition = InboxUtils.getDefaultTransition(this.context.availableTransitions, InboxDefaultTransitionBehavior.Ok);
        this.calculateTabs();
    }

    private getSuggestion(request: VacationRequestModel, rule: VacationPlannerRule): FruitionSuggestionModel {
        let suggestion = <FruitionSuggestionModel>_.find(request.fruitionSuggestions, (s: FruitionSuggestionModel) => s.ruleConfiguration.id === rule.id);
        return new FruitionSuggestionModel(rule, suggestion);
    }

    /**
     * Calcula a ordem de exibição das abas
     */
    private calculateTabs() {

        let position: number = 0;

        //BONUS
        if (this.rule.allowsReceiveBonusEarly) {
            position++;
            this.steps++;
            this.tabs.bonus = new Tab(true, position);

        } else {
            this.tabs.bonus = new Tab(false, -1);
        }

        //SELL
        if (this.rule.maxDaysToSell && this.rule.maxDaysToSell > 0) {

            position++;
            this.steps++;
            this.tabs.sell = new Tab(true, position);

            for (let i = 1; i <= this.rule.maxDaysToSell; i++) {
                this.daysToSell.push(i);
            }

        } else {
            this.tabs.sell = new Tab(false, -1);
        }

        //SPLIT
        if (this.rule.maxVacationDivision && this.rule.maxVacationDivision > 1) {
            position++;
            this.steps++;
            this.tabs.split = new Tab(true, position);

            for (let i = 2; i <= this.rule.maxVacationDivision; i++) {
                this.limitOfSplits.push(i);
            }

        } else {
            this.tabs.split = new Tab(false, -1);
        }

        // METHOD
        switch (this.rule.method) {

            case VacationPlannerRuleMethods.Suggested:
                position++;
                this.steps++;
                this.tabs.periodPriorization = new Tab(true, position);
                break;

            case VacationPlannerRuleMethods.Informed:
                position++;
                this.steps++;
                this.tabs.periodSelection = new Tab(true, position);
                break;

            case VacationPlannerRuleMethods.Both:
                position++;
                this.steps++;
                this.tabs.periodPriorization = new Tab(true, position);
                position++;
                this.steps++;
                this.tabs.periodSelection = new Tab(true, position);
                break;
        }

        position++;
        this.steps++
        this.tabs.confirmation = new Tab(true, position);
    }
}
