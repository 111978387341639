import { DocumentInfo } from '../../models/dms.model';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KDocumentViewerDialog } from './k-document-viewer.component';

@Injectable()
export class KDocumentViewer {

    constructor(public dialog: MatDialog) { }

    open(document: DocumentInfo, otherDocuments: Array<DocumentInfo> = []) {
        this.dialog.open(KDocumentViewerDialog, { width: '900px', data: { document: document, otherDocuments: otherDocuments } });
    }
}
