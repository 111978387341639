import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InboxTransitionModel } from '@mydrake/domain/read/models/core';
import { InboxApi } from '../inbox.api';
import { MyDrakeEventEmitter } from '@shared/services';
import { InboxTransitionFormDialogComponent} from '../inbox-transition-form-dialog/inbox-transition-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';
import { InboxUtils } from '../types/utils';
import { InboxDefaultTransitionBehavior } from '@mydrake/domain/enums/core';
import { KFlowTransitionLogComponent } from 'app/shared/components/k-flow-transition-log/k-flow-transition-log.component';

@Component({
    selector: 'mydrake-inbox-taskbar',
    templateUrl: './inbox-task-bar.component.html',
    styleUrls: ['./inbox-task-bar.component.scss']
})
export class InboxTaskbarComponent {

    @Input()
    public flowType: string;

    @Input()
    public domainId: string;

    @Input()
    public flowIdentifier: string;

    @Input()
    public transitions: Array<InboxTransitionModel> = [];

    @Input()
    public inboxId: string;

    public disableButtons = false;

    get defaultOkTransition(): any {
        return InboxUtils.getDefaultTransition(this.transitions, InboxDefaultTransitionBehavior.Ok);
    }

    get defaultCancelTransition(): any {
        return InboxUtils.getDefaultTransition(this.transitions, InboxDefaultTransitionBehavior.Cancel);
    }

    constructor(private api: InboxApi, private emitter: MyDrakeEventEmitter, private dialog: MatDialog) {
    }

    public onShowTransitionLog() {
        this.dialog.open(KFlowTransitionLogComponent, {
            width: '600px',
            data: { domainId: this.domainId }
        });
    }

    public onFireTransition(transition) {

        this.disableButtons = true;
        const domainId = this.domainId;
        const flowType = this.flowType;
        const flowIdentifier = this.flowIdentifier;

        if (transition.inputProperties.length > 0 || transition.linkedFormInstances.length > 0) {
            let dialogRef = this.dialog.open(InboxTransitionFormDialogComponent, { width: '550px', data: transition });
            dialogRef.afterClosed().subscribe(linkedFormInstances => {
                if (linkedFormInstances != null) {
                    let formInstancesToUpdate = _.filter(linkedFormInstances, function(item) {
                        return !item.readOnly;
                    });

                    let linkedFormInstanceContents = [];
                    linkedFormInstanceContents = _.map(formInstancesToUpdate, function(item) {
                        return {
                            DomainEntityId: domainId,
                            FlowIdentifier: flowIdentifier,
                            FlowType: flowType,
                            FormId: item.form.id,
                            NewContent: JSON.stringify(item.formInstanceContent)
                        };
                    });

                    this.fireTransition(transition, linkedFormInstanceContents);
                } else {
                    // reabilita os botoes pois o usuario cancelou
                    this.disableButtons = false;
                }
            });
        }
        else {
            this.fireTransition(transition);
        }
    }

    private fireTransition(transition, linkedFormInstanceContents = null){
        this.api.fireTransition(transition.key, this.flowType, this.domainId, transition.inputProperties, transition.geolocateThisAction, () => {
            this.emitter.get('INBOX_LIST_REFRESH').emit(this.inboxId);
        }, (error) => {
            // Se ocorrer algum erro durante a transição, reabilita o botao.
            this.disableButtons = false;
            throw error;
        }, null, linkedFormInstanceContents);
    }
}

export class FormInstanceSaveOrUpdateSubmission {

    constructor(formId: string = null, content: string = null) {
        this.formId = formId;
        this.content = content;
    }

    public formId: string;
    public content: string;
}
