import { Component, Input, ViewChild } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { LogisticNeedView } from '@mydrake/domain/read/views/core';

@Component({
    selector: 'mydrake-logistic-timeline',
    templateUrl: './logistic-timeline.component.html',
    styleUrls: ['./logistic-timeline.component.scss']
})
export class LogisticTimelineComponent {

    @Input() logistics : Array<LogisticNeedView>;
    @ViewChild('infoSideNav', { static: true }) content: MatSidenav;

    constructor() {}

    public selected : LogisticNeedView = new LogisticNeedView();

    public onDetail(logistic) : void{
        this.selected = logistic;
        this.content.open();
    }

    public onBack(){
        this.content.close();
    }
}
