import { Component, ViewChild, Output, EventEmitter } from "@angular/core";
import { AppointmentTemplateComponent } from "../../shared/template";
import { MatSidenav } from "@angular/material/sidenav";

@Component({
    selector: 'mydrake-appointment-detail-logistic-type',
    templateUrl: "./detail.component.html",
    styleUrls: ['./detail.component.scss']
})
export class LogisticDetailAppointmentComponent extends AppointmentTemplateComponent {


}