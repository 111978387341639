import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@mydrake/environments/environment";
import { PublicInfo } from "app/shared/models/public-info.model";
import { Observable } from "rxjs";

@Injectable()
export class HomeApi {

    constructor(private http: HttpClient){}

    public getPublicInfo = (): Observable<PublicInfo> =>
        this.http.get<PublicInfo>(`${environment.apiUrl}Public/Info`)
}
