//Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//RxJS
import { Observable } from 'rxjs';

//Services and Providers
import { environment } from 'environments/environment';
import { DefinedEvent } from '@mydrake/domain/read/models/time-sheet';
import { GenerateTimeSheet } from '@mydrake/domain/write/commands/time-sheet/generate-time-sheet.command';
import { map } from 'rxjs/operators';

@Injectable()
export class TimeSheetService {
    constructor(private http: HttpClient) {
    }

    public getDefinedEvents(): Observable<Array<DefinedEvent>> {
        return this.http.get(environment.apiUrl + 'My/Reports/DefinedEvents')
            .pipe(map((res: any) => res as Array<DefinedEvent>));
    }

    public print(cmd: GenerateTimeSheet): Observable<any> {
        return this.http.post(environment.apiUrl + 'My/Reports/PrintTimeSheet', cmd);
    }

    public canActivate(): Observable<boolean> {
        return this.http.get(environment.apiUrl + 'My/Reports/CanActivate')
            .pipe(map(res => res as boolean));
    }
}
