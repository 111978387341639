import { TelephoneType } from "@mydrake/domain/enums/core";

export class TelephoneView {

    public country : string;
    public code : string;
    public number : string;
    public type : TelephoneType;

    public toString() : string {
        let country = this.country ? `+${this.country}` : '';
        let code = this.code ? ` ${this.code} ` : '';
        return `${country}${code}${this.number}`;
    }
}
