import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatRadioChange} from '@angular/material/radio';
import {FormModel, QuestionModel} from '@mydrake/domain/read/models/online-training';
import * as _ from 'lodash';
import {QuizApi} from './domain/quiz.service';
import {MyDrakeException} from '@shared/exceptions';
import {EssayQuestionInterval} from './domain/quiz.model';
import {QuestionType} from '@mydrake/domain/read/models/online-training/question.model';
import {InboxTransitionComponent} from '../../../inbox-transition/inbox-transition.component';

const ESSAY_ANSWER_REQUEST_TIMEOUT = 10000; // 10 segundos

@Component({
    selector: "mydrake-online-training-quiz",
    templateUrl: "./quiz.component.html",
    styleUrls: ["./quiz.component.scss"]
})
export class OnlineTraininfQuizComponent implements OnInit, AfterViewInit {
    @Input()
    public stepId: string;

    @Input()
    public domainId: string;

    @Input()
    public context: any;

    @Input()
    public form: FormModel;

    @Input()
    public inboxId: string;

    @Input()
    public expired: boolean;

    @ViewChild('inboxTransition', { static: true })
    public inboxTransition: InboxTransitionComponent;

    private onFireTransition: any;
    private dirtyEssayQuestions: Array<EssayQuestionInterval> = [];

    constructor(private quizApi: QuizApi) {}

    ngOnInit() {
        this.form.questions = _.forEach(
            this.form.questions,
            question =>
                (question.options = _.sortBy(
                    question.options,
                    option => option.index
                ))
        );
        this.form.questions = _.sortBy(
            this.form.questions,
            question => question.index
        );
    }

    ngAfterViewInit(): void {
        this.bindCustomTransition();
    }

    public validate() {
        if (!this.expired) {
            const multipleChoiceQuestions = this.form.questions
                .filter(question => question.type === QuestionType.MultipleChoice && question.isMandatory);

            const essayQuestions = this.form.questions
                .filter(question => question.type === QuestionType.Essay && question.isMandatory);

            const multipleChoiceWithoutAnswer = _.some(
                multipleChoiceQuestions,
                question => question.answer == null
            );

            const essayWithoutAnswer = _.some(
                essayQuestions,
                question => !question.essayAnswer || !question.essayAnswer.trim()
            );

            if (multipleChoiceWithoutAnswer || essayWithoutAnswer) {
                throw new MyDrakeException(
                    "INBOX.ONLINE_TRAINING.THERE_ARE_UNANSWERED_QUESTIONS"
                );
            }
        }
    }

    public markAnswer(event: MatRadioChange, question) {
        this.quizApi
            .markAnswer(this.form.id, question.id, event.value)
            .subscribe(response => {
                question.answer = event.value;
            });
    }

    public setEssayAnswer(event: Event, question: QuestionModel) {

        const dirtyQuestion = this.dirtyEssayQuestions.find(q => q.questionId === question.id);

        if (dirtyQuestion) {
            this.removeDirtyQuestion(dirtyQuestion.questionId);
        }

        const element = event.target as HTMLInputElement;
        question.essayAnswer = element.value;

        const intervalId = setTimeout(() => {
            this.quizApi
                .setEssayAnswer(this.form.id, question)
                .subscribe(() => {
                    this.removeDirtyQuestion(question.id);
                });
        }, ESSAY_ANSWER_REQUEST_TIMEOUT);

        this.dirtyEssayQuestions.push(new EssayQuestionInterval(question.id, intervalId));
    }

    private onCustomFireTransition = (transition: any) => {
        if (transition.key === 'CONCLUIR_2') {
            this.saveEssayAnswers(transition);
        } else {
            this.onFireTransition(transition);
        }
    }
    private bindCustomTransition = () => {
        this.onFireTransition = this.inboxTransition.onFireTransition.bind(this.inboxTransition);
        this.inboxTransition.onFireTransition = this.onCustomFireTransition;
    }
    private saveEssayAnswers = (transition: any) => {
        if (this.dirtyEssayQuestions.length > 0) {

            const questionAnswersToUpdate = this.dirtyEssayQuestions.map(questionToUpdate => {
                clearInterval(questionToUpdate.intervalId);
                return this.form.questions.find(question => question.id === questionToUpdate.questionId);
            });

            this.quizApi.setEssayAnswers(this.form.id, questionAnswersToUpdate)
                .subscribe(() => {
                    this.onFireTransition(transition);
                });
        } else {
            this.onFireTransition(transition);
        }
    }
    private removeDirtyQuestion = (questionId: string) => {
        this.dirtyEssayQuestions = this.dirtyEssayQuestions.filter(q => {
            if (q.questionId === questionId) {
                clearInterval(q.intervalId);
                return false;
            } else {
                return true;
            }
        });
    }
}
