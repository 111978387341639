export class EssayQuestionInterval {
    public questionId: string;
    public intervalId: any;

    constructor(questionId: string, interval: any) {
        this.questionId = questionId;
        this.intervalId = interval;
    }
}

export class QuestionAnswerValue {
    questionId: string;
    essayAnswer: string;

    constructor(questionId: string, essayAnswer: string) {
        this.questionId = questionId;
        this.essayAnswer = essayAnswer;
    }
}
