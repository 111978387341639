import { InactiveMyDrakeDialog } from './inactive-mydrake/inactive-mydrake.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RegisterService } from "./register.service";
import { MatDialog } from "@angular/material/dialog";
import { EmailNotFoundDialog } from "./email-not-found/email-not-found.component";
import { UserAlreadyExistsDialog } from "./user-already-exists/user-already-exists.component";
import { ThereIsMoreThanOneWorkerByEmailDialog } from "./there-is-more-than-one-worker-by-email/there-is-more-than-one-worker-by-email.component";
import { UnknownErrorDialog } from "./unknown-error/unknown-error.component";
import { RegisterSuccessfullyDialog } from './successfully/successfully.component';
import { CultureProvider } from '@shared/providers';

@Component({
    selector: 'mydrake-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public form: FormGroup;
    public progress: boolean = false;

    constructor(
        private _builder: FormBuilder,
        private _service: RegisterService,
        private _dialog: MatDialog,
        private cultureProvider: CultureProvider) { }

    ngOnInit() {

        this.cultureProvider.configure();

        this.configForm();

    }

    public onRegister(): void {
        this.progress = true;
        this._service
            .register(this.form.value.email)
            .subscribe(data => {
                this.showSuccessfulyRegistration();
                this.progress = false;
            },
                error => {
                    this.handleError(error);
                    this.progress = false;
                });
    }


    private configForm(): void {
        this.form = this._builder.group({
            email: [
                "",
                [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(150)
                ]
            ]
        });
    }

    private handleError(error: any) {

        console.log(error);

        switch (error.error.Key) {
            case "EmailNotFound": this.showEmailNotFoundMessage(this.form.value.email);
                break;

            case "UserAlreadyExists": this.showUserAlreadyExistsMessage();
                break;

            case "ThereIsMoreThanOneWorkerByEmail": this.showThereIsMoreThanOneWorkerByEmailMessage();
                break;

            case "MyDrakeIsInactiveForThisCompany": this.showMyDrakeIsInactiveForThisCompanyMessage();
                break;

            default: this.showUnknownErrorMessage(error.error.Message);
        }
    }

    private showSuccessfulyRegistration() {
        this._dialog.open(RegisterSuccessfullyDialog, { width: '410px', disableClose: true, data: { message: 'REGISTRATION.LABELS.REGISTER_START_SUCCESSFULLY' } });
    }

    private showEmailNotFoundMessage(email) {
        this._dialog.open(EmailNotFoundDialog, { width: '460px', data: { email: email } });
    }

    private showUserAlreadyExistsMessage() {
        this._dialog.open(UserAlreadyExistsDialog, { width: '460px' });
    }

    private showThereIsMoreThanOneWorkerByEmailMessage() {
        this._dialog.open(ThereIsMoreThanOneWorkerByEmailDialog, { width: '460px' });
    }

    private showMyDrakeIsInactiveForThisCompanyMessage() {
        this._dialog.open(InactiveMyDrakeDialog, { width: '460px' });
    }

    private showUnknownErrorMessage(message) {
        this._dialog.open(UnknownErrorDialog, { width: '460px', data: { message: message } });
    }
}
