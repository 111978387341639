import { SharedContactView } from "@mydrake/domain/read/views/core";

export class ContactView {

    public id : string;
    public avatarId : string;
    public name : string;
    public job : string;
    public contacts: Array<SharedContactView> = []
}
