import { Component, Input, OnInit } from "@angular/core";
import { VacationApi } from "../vacation.api";
import { VacationPlannerRule, VacationRequestModel } from "@mydrake/domain/read/models/vacation-planner";

import * as _ from 'lodash';

@Component({
    selector: 'mydrake-vacation-request-rule-selection',
    templateUrl: 'rule-selection.component.html',
    styleUrls: ['./rule-selection.component.scss']
})
export class VacationRequestRuleSelectionComponent implements OnInit {

    public rulesLoaded: boolean;
    public ruleSelected: boolean;
    public rules: Array<VacationPlannerRule>;
    public rule: VacationPlannerRule;

    @Input()
    public context: any;

    @Input()
    public inboxId: any;

    @Input()
    public domainId: any;

    @Input()
    public workerCategoryId: any;

    private request: VacationRequestModel;

    constructor(private api: VacationApi) { 

    }

    ngOnInit() {

        this.request = this.context.domainEntitySnapshot as VacationRequestModel;
        
        this.api.getRulesByCategory(this.workerCategoryId).subscribe(rules => {

            this.rules = rules as Array<VacationPlannerRule>;

            if (this.request.choosedRuleConfiguration) {

                this.rule = _.find(this.rules, r => r.id === this.request.choosedRuleConfiguration.id);
                this.ruleSelected = true;

            } else {

                if (this.rules.length === 1) {
                    this.rule = this.rules[0];
                    this.ruleSelected = true;
                    this.updateRequest();
                } 
            }

            this.rulesLoaded = true;
        });
    }

    public chooseRule(ruleId: string): void {
        this.rule = _.find(this.rules, r => r.id === ruleId);
        if (this.rule) {
            this.ruleSelected = true;
            this.updateRequest();
        }
    }

    private updateRequest(): void {
        this.request.choosedRuleConfiguration = { id: this.rule.id };
        this.api.changeVacationRequestRule(this.request.id, this.rule.id);
    }
}