<!-- BREADCRUMB -->
<k-breadcrumb label="{{ 'MENU.DOCUMENTS' | translate }}{{ breadcrumbLabelSuffix }}">
    <button mat-icon-button (click)="onDownloadAllFiles()" style="display: flex; justify-content: center; align-items: center;">
        <mat-icon class="material-icons" style="font-size: 23px; color: #757575;">save_alt
        </mat-icon>
    </button>
    <button mat-icon-button (click)="onBack()" *ngIf="folder" style="display: flex; justify-content: center; align-items: center;">
        <mat-icon class="material-icons" style="font-size: 23px; color: #757575;">reply</mat-icon>
    </button>
</k-breadcrumb>
<!--/ BREADCRUMB -->


<div autoresize [fix]="150" *ngIf="!loading && (workerDocuments.length > 0 || folders.length > 0)">
    <ng-scrollbar [autoHeightDisabled]="false" >
        <div scrollViewport infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="15" [scrollWindow]="false" (scrolled)="onScrollDown()">

            <div style="padding: 5px 25px">

                <mat-accordion id="documents">

                    <div class="item" (click)="onOpenFolder(f)" [class.folder-card]="true"
                        style="max-height: 65px; height: 56px !important; padding: 30px 23px;" fxLayoutAlign="start center"
                        *ngFor="let f of folders">

                        <div class="icon-section" >
                            <div class="icon-container" >
                                <i class="icon-document-folder-open icon" [style.color]="'#0747a6'"></i>
                            </div>
                        </div>

                        <div fxLayout="column" fxFlex>
                            <div class="label">{{ 'DOCUMENTS.FOLDER' | translate }}</div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div class="value" [style.color]="'#000000de'"> {{ f }}</div>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button
                                mat-icon-button
                                style="text-align: center"
                                matTooltip="{{ 'DOCUMENTS.MORE_OPTIONS.DOWNLOAD_FOLDER' | translate }}"
                                (click)="downloadFilesFromFolder(f, $event)"
                            >
                                <i style="color: #757575" class="material-icons">cloud_download</i>
                            </button>
                        </div>
                    </div>

                    <ng-container>

                        <mat-expansion-panel class="document-card" *ngFor="let workerDocument of workerDocuments"
                            (click)="workerDocument.opened = true" hideToggle>

                            <mat-expansion-panel-header collapsedHeight="106px" expandedHeight="106px">

                                <mat-panel-title>
                                    <div class="card-top">

                                        <div class="icon-section">
                                            <div class="icon-container" >
                                                <i class="icon-document-{{workerDocument.icon}} icon" [style.color]="workerDocument.color"></i>
                                            </div>
                                        </div>

                                        <div class="info-section">

                                            <div class="row">
                                                <div *ngIf="workerDocument.groupId" class="info qualification">
                                                    <div class="label">{{ 'QUALIFICATION' | translate }}</div>
                                                    <div class="value">
                                                        <span>
                                                            {{ workerDocument.groupName }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div *ngIf="!workerDocument.groupId" class="info qualification">
                                                    <div class="label">{{ 'TITLE' | translate }}</div>
                                                    <div class="value">
                                                        <span>
                                                            {{ workerDocument.title }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <validity-badge [validDays]="workerDocument.validDays"></validity-badge>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="info issued-date">
                                                    <div class="label">{{ 'ISSUE_DATE' | translate }}</div>
                                                    <div class="value">{{ workerDocument.issuedDate | i18nDate: 'date' | whenEmpty: '-' }}</div>
                                                </div>

                                                <div class="info issuer">
                                                    <div class="label">{{ 'ISSUER' | translate }}</div>
                                                    <div class="value">{{ workerDocument.issuer | whenEmpty:'-' }}</div>
                                                </div>

                                                <div class="info number">
                                                    <div class="label">{{ 'NUMBER' | translate }}</div>
                                                    <div class="value">{{ workerDocument.number | whenEmpty:'-' }}</div>
                                                </div>

                                                <div class="info validity">
                                                    <div class="label">{{ 'DOCUMENTS.EXPIRATION' | translate }}</div>
                                                    <div class="value">{{ workerDocument.validity | i18nDate: 'date' | whenEmpty:'-' }}</div>
                                                </div>

                                                <div class="info attachments-count">
                                                    <mat-icon
                                                        [class.no-files]="countDocuments(workerDocument) === 0"
                                                        matBadge="{{ countDocuments(workerDocument) }}"
                                                        matBadgeSize="small"
                                                        [matBadgeHidden]="countDocuments(workerDocument) === 0"
                                                    >
                                                        attach_file
                                                    </mat-icon>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </mat-panel-title>

                            </mat-expansion-panel-header>

                            <div class="detail-wrapper" *ngIf="workerDocument.opened">
                                <document-detail [workerDocument]="workerDocument">
                                </document-detail>

                            </div>

                        </mat-expansion-panel>

                    </ng-container>

                </mat-accordion>

            </div>

    </div>
    </ng-scrollbar>
</div>

<div class="loading" *ngIf="loading">
    <mat-spinner></mat-spinner>
    <div class="message">{{ 'LOADING_DATA' | translate }}</div>
</div>

<div class="not-found" *ngIf="workerDocuments.length === 0 && !loading">
    <div class="thumbnail">
        <i class="icon-mydrake-folder-search"></i>
    </div>
    <div class="message">{{ 'DOCUMENTS.NO_DOCUMENTS_YET' | translate }}</div>
</div>
