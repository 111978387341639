import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContactView } from '@mydrake/domain/read/views/core';

@Component({
    selector: 'mydrake-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {

    @Input()
    public contact : ContactView;

    @Output()
    public back : EventEmitter<any> = new EventEmitter<any>();

    constructor() {  }

    public onBack(){
        this.back.emit();
    }
}
