import {QuestionModel} from '@mydrake/domain/read/models/online-training';
import {QuestionAnswerValue} from './quiz.model';

export class SaveEssayAnswersQuestion {
    public formId: string;
    public questionAnswers: QuestionAnswerValue[] = [];

    constructor(formId: string, questions: QuestionModel[]) {
        this.formId = formId;
        this.questionAnswers = questions.map(question => new QuestionAnswerValue(question.id, question.essayAnswer));
    }
}
