<!-- BREADCRUMB -->
<div class="cover">
    <k-breadcrumb label="Profile"></k-breadcrumb>
</div>
<!--/ BREADCRUMB -->
<div class="profile">

    <!-- WORKER AVATAR-->
    <aside>
        <k-avatar [avatarId]="worker.avatar" [name]="worker.name" type="circle" size="185px" fontsize="100px"></k-avatar>
        <section>
            <div *ngIf="worker.cpf">
                <label>{{ 'PROFILE.LABELS.CPF' | translate }}</label>
                <div class="value">{{ worker.cpf }}</div>
            </div>
            <div *ngIf="worker.sispat">
                <label>{{ 'PROFILE.LABELS.SISPAT' | translate }}</label>
                <div class="value">{{ worker.sispat }}</div>
            </div>
            <div *ngIf="worker.rne">
                <label>{{ 'PROFILE.LABELS.RNE' | translate }}</label>
                <div class="value">{{ worker.rne }}</div>
            </div>
        </section>
    </aside>
    <!--/ WORKER AVATAR -->

    <article fxFlex>
        <header>

            <!-- NAME, JOB & REGISTRATION-->
            <div fxFlex class="worker-information">
                <div class="name">{{ worker.name }}</div>
                <div class="job">{{ worker.job }}</div>
                <div class="registration">{{ worker.registration }}</div>
            </div>
            <!--/ NAME, JOB & REGISTRATION-->

            <!-- SUMMARY-->
            <div class="worker-summary">

                <div class="item -separete" *ngIf="worker.isManager">
                    <div class="item-icon">
                        <i class="icon-mydrake-captain-cap"></i>
                    </div>
                    <label>{{ 'PROFILE.LABELS.MANAGER' | translate }}</label>
                </div>

                <div class="item -separete">
                    <div class="item-icon">
                        <i [class]="categoryIcon"></i>
                    </div>
                    <label>{{ worker.category }}</label>
                </div>

                <div class="item" [class.-separete]="worker.bloodType">
                    <div class="item-icon">
                        <i [class]="genderIcon"></i>
                    </div>
                    <label>{{ 'PROFILE.LABELS.GENDER' | translate }}</label>
                </div>

                <div class="item" *ngIf="worker.bloodType">
                    <div class="item-icon">
                        <div class="blood">{{ worker.bloodType }}</div>
                    </div>
                    <label>{{ 'PROFILE.LABELS.BLOOD_TYPE' | translate }}</label>
                </div>
            </div>
            <!--/ SUMMARY-->

        </header>
        <mat-card style="margin-top: 20px">

            <!-- PROFESSIONAL INFORMATIONS -->
            <mat-card-header>
                <div class="field">
                    <label>{{ 'PROFILE.LABELS.STATUS' | translate }}</label>
                    <div class="value">{{ 'TYPES.WORKER_STATUS.' + worker.status | translate }}</div>
                </div>
                <div class="field">
                    <label>{{ 'PROFILE.LABELS.TYPE' | translate }}</label>
                    <div class="value">{{ 'TYPES.WORKER_TYPE.' + worker.type | translate }}</div>
                </div>
                <div class="field">
                    <label>{{ 'PROFILE.LABELS.COMPANY' | translate }}</label>
                    <div class="value">{{ worker.company }}</div>
                </div>
                <div class="field" *ngIf="worker.organizationalUnit">
                    <label>{{ 'PROFILE.LABELS.ORGANIZATIONAL_UNIT' | translate }}</label>
                    <div class="value">{{ worker.organizationalUnit }}</div>
                </div>
                <div class="field" *ngIf="worker.admissionDate">
                    <label>{{ 'PROFILE.LABELS.ADMISSION_DATE' | translate }}</label>
                    <div class="value">{{ worker.admissionDate | i18nDate:'date'}}</div>
                </div>
                <div class="field" *ngIf="worker.dismissalDate">
                    <label>{{ 'PROFILE.LABELS.DISMISSAL_DATE' | translate }}</label>
                    <div class="value">{{ worker.dismissalDate | i18nDate:'date' }}</div>
                </div>
            </mat-card-header>
            <!--/ PROFESSIONAL INFORMATIONS -->

            <mat-card-content>
                <ng-scrollbar [autoHeightDisabled]="false">
                    <div class="extras" autoresize [fix]="405">

                        <!-- EMAILS -->
                        <div fxFlex="33">
                            <div class="title">
                                <i class="icon-mydrake-basic-mail-open-text"></i>
                                <div>{{ 'PROFILE.LABELS.EMAILS' | translate }}</div>
                            </div>

                            <ul>
                                <li *ngFor="let email of worker.emails">
                                    <div class="value">{{email}}</div>
                                </li>
                            </ul>
                        </div>
                        <!--/ EMAILS -->

                        <!-- TELEPHONES -->
                        <div fxFlex="33" style="border-left: 3px solid rgba(221, 221, 221, 0.3); padding: 0 25px; margin: 0 25px; border-right: 3px solid rgba(221, 221, 221, 0.3);">
                            <div class="title">
                                <i class="icon-mydrake-telephone"></i>
                                <div>{{ 'PROFILE.LABELS.TELEPHONES' | translate }}</div>
                            </div>

                            <ul>
                                <li *ngFor="let telephone of worker.telephones">
                                    <label>{{ 'TYPES.TELEPHONE_TYPE.' + telephone.type | translate }}</label>
                                    <div class="value">{{telephone.toString()}}</div>
                                </li>
                            </ul>
                        </div>
                        <!--/ TELEPHONES -->

                        <!-- ADDRESSES -->
                        <div fxFlex="33">
                            <div class="title">
                                <i class="icon-mydrake-map-location"></i>
                                <div>{{ 'PROFILE.LABELS.ADDRESSES' | translate }}</div>
                            </div>

                            <ul>
                                <li *ngFor="let address of worker.addresses">
                                    <div class="value">{{address.toString()}}</div>
                                </li>
                            </ul>
                        </div>
                        <!-- ADDRESSES -->

                    </div>
                </ng-scrollbar>
            </mat-card-content>
        </mat-card>
    </article>


</div>
