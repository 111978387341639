<k-breadcrumb label="{{ 'MENU.REQUEST_CENTER' | translate }}"> </k-breadcrumb>

<div autoresize  [fix]="150" *ngIf="availableServices.length > 0">
    <ng-scrollbar [autoHeightDisabled]="false" >
        <div scrollViewport id="issue-list">
            <div id="issues" fxLayout="row" fxLayoutWrap >
                <div *ngFor="let availableService of availableServices" [fxFlex]="20" fxFlex.xs="100" [fxFlex.sm]="50" [fxFlex.md]="25"
                    style="padding: 10px">
                    <mat-card class="issue-card">
                        <mat-card-content fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                            <k-icon class="k-icon" [iconId]="availableService.imageIconId" [name]="availableService.name" type="circle" size="80px">
                            </k-icon>
                            <div class="issue-name">{{availableService.name}}</div>
                        </mat-card-content>
                        <mat-card-actions class="issue-task-bar">
                            <div style="flex:1"></div>
                            <button mat-button color="primary" *ngIf="!availableService.currentIssuePendingToRequestId" (click)="onStart(availableService)">{{ 'REQUEST_CENTER.START' | translate }}</button>
                            <button mat-button color="primary" *ngIf="availableService.currentIssuePendingToRequestId" (click)="onContinue(availableService)">{{ 'REQUEST_CENTER.CONTINUE' | translate }}</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>

<div style="padding: 0px 15px; height: calc(100vh - 125px);" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="availableServices.length === 0">
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -60px">
        <i class="icon-mydrake-basic-sheet-multiple"
            style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
        <div style="padding: 20px 0 5px 0;
                    font-weight: 400;
                    font-size: 23px;
                    color: #757575;">{{ 'REQUEST_CENTER.NOT_ANY_ISSUE_FOUND' | translate }}</div>
        <div style="font-style: italic;
                    font-size: 14px; color: #757575;">{{ 'REQUEST_CENTER.USE_SEARCH_FILTERS' | translate }}
        </div>
    </div>
</div>
