import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { KDialogContentComponent, KDialogFooterComponent, KDialogComponent } from './k-dialog.component';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../core/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { KScrollModule } from '../k-scrollbar/scrollbar.module';
import { KIconModule } from '../k-icon/k-icon.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        TranslateModule,
        KScrollModule,
        KIconModule
    ],
    exports: [
        KDialogComponent,
        KDialogContentComponent,
        KDialogFooterComponent
    ],
    declarations: [
        KDialogComponent,
        KDialogContentComponent,
        KDialogFooterComponent
    ],
    providers: [],
})
export class KDialogModule { }
