<k-breadcrumb label="{{ 'MENU.REPORTS' | translate }}"> </k-breadcrumb>

<div id="report-list" autoresize [fix]="150" *ngIf="reports.length > 0">

    <ng-scrollbar [autoHeightDisabled]="false">

        <div scrollViewport>

            <div id="reports" fxLayout="column">

                <div fxLayout="row" fxLayoutWrap>
                    <div *ngFor="let report of reports" [fxFlex]="20" fxFlex.xs="100"
                        [fxFlex.sm]="50" [fxFlex.md]="25" style="padding: 10px">
                        <mat-card style="height: 100%;" (click)="onOpen(report.component)" class="contact-card">
                            <mat-card-content fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                                <i [class]="report.icon" matTooltip="{{ report.title | translate }}"
                                    matTooltipPosition="right"></i>
                                <div class="report-name">
                                    {{report.name}}</div>
                                <div class="report-description">
                                    {{report.description}}</div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </ng-scrollbar>
    <div style="padding: 0px 15px; height: calc(100vh - 125px);" fxLayout="column" fxLayoutAlign="center center" *ngIf="loaded && reports.length === 0">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: -60px">
            <i class="icon-mydrake-infographics" style="font-size: 80px; color: #888883; border: 3px solid #888883; border-radius: 50%; width: 150px; height: 150px; display: flex; flex-direction: row; align-items: center; justify-content: center;"></i>
            <div style="padding: 20px 0 5px 0;
                font-weight: 400;
                font-size: 23px;
                color: #757575;">{{ 'REPORTS.NOT_ANY_REPORT_FOUND' | translate }}</div>
            <div style="font-style: italic;
                font-size: 14px; color: #757575;">{{ 'REPORTS.USE_SEARCH_FILTERS' | translate }}</div>
        </div>
    </div>

</div>
