import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mydrake-online-training-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.scss']
})
export class OnlineTrainingCanceledComponent implements OnInit {

    @Input()
    public domainEntity: any;

    constructor() { }

    ngOnInit() {
    }

    get cancelationReason() {
        return this.domainEntity.cancelationReason;
    }

    get cancelationComments() {
        return this.domainEntity.cancelationComments;
    }
}
