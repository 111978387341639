import { Moment } from "moment";
import * as moment from "moment";

export class GenerateTimeSheet {
    public startDate: Moment;
    public endDate: Moment;
    public note: string;
    public definedEventsIds: Array<string>;

    constructor(startDate: Date, endDate: Date, note: string, definedEventsIds: Array<string>) {
        this.startDate = startDate ? moment(startDate) : null;
        this.endDate = endDate ? moment(endDate) : null;
        this.note = note;
        this.definedEventsIds = definedEventsIds;
    }
}
