import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'mydrake-unknown-error-dialog',
    templateUrl: 'unknown-error.component.html',
    styleUrls: ['./unknown-error.component.scss']
})
export class UnknownErrorDialog {

    public message: string;

    constructor(
        public dialogRef: MatDialogRef<UnknownErrorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.message = data.message;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
